import React, { useMemo, useState } from "react";

interface TrendingCoursesContextParams {
  trendingCoursesData: any;
  firstEightCourses: any;
  totalPages: number;
  setTrendingCoursesData: (state: any) => null;
  setFirstEightCourses: (state: any) => null;
  setTotalPages: (state: number) => null;
}

const TrendingCoursesContext = React.createContext<
  TrendingCoursesContextParams | undefined
>(undefined);

TrendingCoursesContext.displayName = "TrendingCoursesContext";

function TrendingCoursesDataProvider(props: any) {
  const [trendingCoursesData, setTrendingCoursesData] = useState([]);
  const [firstEightCourses, setFirstEightCourses] = useState([]);

  const [totalPages, setTotalPages] = useState<number>();

  const value = useMemo(() => {
    return {
      trendingCoursesData,
      setTrendingCoursesData,
      firstEightCourses,
      setFirstEightCourses,
      totalPages,
      setTotalPages,
    };
  }, [trendingCoursesData, firstEightCourses, totalPages]);

  return <TrendingCoursesContext.Provider value={value} {...props} />;
}

function useTrendingCoursesContext() {
  const context = React.useContext(TrendingCoursesContext);

  if (context === undefined) {
    throw new Error(
      `useTrendingCoursesContext must be used within a TrendingCoursesDataProvider`
    );
  }

  return context;
}

export { TrendingCoursesDataProvider, useTrendingCoursesContext };
