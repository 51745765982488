import { useState, useEffect } from "react";

// Component imports
import {
	NewsPostIcon,
	AddMembersIcon,
	LiveSessionIcon,
	CreateAccountIcon,
	ExplorePortfolioIcon,
	CreateAddLearningIcon,
} from "components/svg";

// Context imports
import { useOnboardingContext, usePortfolioDataContext } from "context";

// Image imports
import StepOneImg from "assets/images/getStarted/create-account.svg";
import StepTwoImg from "assets/images/getStarted/add-learners.svg";
import StepThreeImg from "assets/images/getStarted/add-members.svg";
import StepFourImg from "assets/images/getStarted/create-session.svg";
import StepFiveImg from "assets/images/getStarted/create-post.svg";
import StepSixImg from "assets/images/getStarted/explore-portfolio.svg";

import { OnboardingAssetProps } from "ts/interfaces/assets.interfaces";

interface OnboardingStepsDataProperties {
	step_one: {
		image: string;
		title: string;
		text: string;
		active: boolean;
	};
	step_two: {
		image: string;
		title: string;
		text: string;
		active: boolean;
	};
	step_three: {
		image: string;
		title: string;
		text: string;
		active: boolean;
	};
	step_four: {
		image: string;
		title: string;
		text: string;
		active: boolean;
	};
	step_five: {
		image: string;
		title: string;
		text: string;
		active: boolean;
	};
}

interface OnboardingAssetsProps {
	onboardingAssets: OnboardingAssetProps[];
}

function useCreateOnboardingStepsData({
	onboardingAssets,
}: OnboardingAssetsProps) {
	const [onboardingStepsData, setOnboardingStepsData] = useState<
		OnboardingStepsDataProperties | {}
	>({});

	const { onboardingSteps } = useOnboardingContext();

	const { portfolioUserData } = usePortfolioDataContext();

	useEffect(() => {
		if (onboardingSteps && onboardingAssets.length !== 0) {
			let onboardingStepsData: any = {
				step_one: {
					image: StepOneImg,
					icon: CreateAccountIcon,
					title: onboardingAssets[0].title,
					text: onboardingAssets[0].description,
					active: onboardingAssets[0].show,
					videoSrc:
						onboardingAssets[0].type === "video"
							? onboardingAssets[0].link
							: null,
					imgSrc:
						onboardingAssets[0].type === "image"
							? onboardingAssets[0].link
							: null,
				},
				step_two: {
					image: StepTwoImg,
					icon: CreateAddLearningIcon,
					title: onboardingAssets[1].title,
					text: onboardingAssets[1].description,
					active: onboardingAssets[1].show,
					videoSrc:
						onboardingAssets[1].type === "video"
							? onboardingAssets[1].link
							: null,
					imgSrc:
						onboardingAssets[1].type === "image"
							? onboardingAssets[1].link
							: null,
				},
				step_three: {
					image: StepThreeImg,
					icon: AddMembersIcon,
					title: onboardingAssets[2].title,
					text: onboardingAssets[2].description,
					active: onboardingAssets[2].show,
					videoSrc:
						onboardingAssets[2].type === "video"
							? onboardingAssets[2].link
							: null,
					imgSrc:
						onboardingAssets[2].type === "image"
							? onboardingAssets[2].link
							: null,
				},
				step_four: {
					image: StepFourImg,
					icon: LiveSessionIcon,
					title: onboardingAssets[3].title,
					text: onboardingAssets[3].description,
					active: onboardingAssets[3].show,
					videoSrc:
						onboardingAssets[3].type === "video"
							? onboardingAssets[3].link
							: null,
					imgSrc:
						onboardingAssets[3].type === "image"
							? onboardingAssets[3].link
							: null,
				},
				step_five: {
					image: StepFiveImg,
					icon: NewsPostIcon,
					title: onboardingAssets[4].title,
					text: onboardingAssets[4].description,
					active: onboardingAssets[4].show,
					videoSrc:
						onboardingAssets[4].type === "video"
							? onboardingAssets[4].link
							: null,
					imgSrc:
						onboardingAssets[4].type === "image"
							? onboardingAssets[4].link
							: null,
				},
			};

			if (portfolioUserData) {
				const stepSix = {
					image: StepSixImg,
					icon: ExplorePortfolioIcon,
					title: onboardingAssets[5].title,
					text: onboardingAssets[5].description,
					active: onboardingAssets[5].show,
					videoSrc:
						onboardingAssets[5].type === "video"
							? onboardingAssets[5].link
							: null,
					imgSrc:
						onboardingAssets[5].type === "image"
							? onboardingAssets[5].link
							: null,
				};

				onboardingStepsData = { ...onboardingStepsData, stepSix };
			}

			setOnboardingStepsData(onboardingStepsData);
		}
	}, [
		onboardingSteps,
		onboardingAssets,
		portfolioUserData,
		setOnboardingStepsData,
	]);

	return { onboardingStepsData, setOnboardingStepsData };
}

export { useCreateOnboardingStepsData };
