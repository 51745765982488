import React from "react";

const HomeIcon = () => {
  return (
    <svg
      style={{ marginRight: "10px" }}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="white"
        d="M7.313 1.261c.385-.364.989-.364 1.374 0l4.844 4.58c.3.283.469.677.469 1.09V12.5c0 .828-.672 1.5-1.5 1.5h-2c-.828 0-1.5-.672-1.5-1.5V10c0-.276-.224-.5-.5-.5h-1c-.276 0-.5.224-.5.5v2.5c0 .828-.672 1.5-1.5 1.5h-2c-.828 0-1.5-.672-1.5-1.5V6.93c0-.412.17-.806.47-1.09l4.843-4.579zM8 1.988l-4.844 4.58c-.1.094-.156.225-.156.363V12.5c0 .276.224.5.5.5h2c.276 0 .5-.224.5-.5V10c0-.828.672-1.5 1.5-1.5h1c.828 0 1.5.672 1.5 1.5v2.5c0 .276.224.5.5.5h2c.276 0 .5-.224.5-.5V6.93c0-.137-.057-.268-.156-.363L8 1.988z"
      />
    </svg>
  );
};

export default HomeIcon;
