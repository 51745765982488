import React, { useMemo } from "react";

import { localStorageKeys, useLocalStorageState } from "hooks";

interface VleTokenContextParams {
  vleToken: string;
  setVleToken: (state: boolean) => null;
  removeVleTokenFromLocalStorage: () => null;
}

const VleTokenContext = React.createContext<VleTokenContextParams | undefined>(
  undefined
);

VleTokenContext.displayName = "VleTokenContext";

function VleTokenProvider(props: any) {
  let [vleToken, setVleToken, removeVleTokenFromLocalStorage] =
    useLocalStorageState({
      key: localStorageKeys.vleToken,
    });

  const value = useMemo(() => {
    return {
      vleToken,
      setVleToken,
      removeVleTokenFromLocalStorage,
    };
  }, [vleToken, setVleToken, removeVleTokenFromLocalStorage]);

  return <VleTokenContext.Provider value={value} {...props} />;
}

function useVleTokenContext() {
  const context = React.useContext(VleTokenContext);

  if (context === undefined) {
    throw new Error(
      `useVleTokenContext must be used within a VleTokenProvider`
    );
  }

  return context;
}

export { VleTokenProvider, useVleTokenContext };
