import { useState, useEffect } from "react";

import { client } from "utils/api-client";

import { useInfiniteQuery } from "react-query";

import { Notification } from "utils/notification";

import {
  useVleTokenContext,
  useRecentCoursesContext,
  useMarketPlaceFilterContext,
  useCoursesFilterContext,
} from "context";
import { useGetOrgData } from "hooks/useGetOrgData";

function useGetRecentCoursesMarketplace() {
  const { setRecentCoursesData, setFirstFiveCourses } =
    useRecentCoursesContext();
  const { enablePayment } = useGetOrgData();

  const [coursesCategories, setCoursesCategories] = useState([]);
  const [pageLimit, setPageLimit] = useState(8);

  const { filterValue, filterBy, courseProviderValue } =
    useMarketPlaceFilterContext();

  const { vleToken } = useVleTokenContext();
  const { isFree } = useCoursesFilterContext();
  const getRecentCoursesMarkeplace = ({ pageParam = 1 }: any) => {
    // if (courseProviderValue) {
    return client({
      method: "get",
      // endpoint: "api/akademy/getFreeCourses",
      endpoint: "api/course-provider/courses",
      optional: {
        token: vleToken,
        params: {
          show_b2b_enabled_only: 1,
          search_by: filterBy ? filterBy : "title",
          app: "ms_teams",
          page: pageParam,
          page_limit: pageLimit,
          status: 1,
          search_keyword: filterValue,
          account_id: courseProviderValue?.split(" ")[1],
          is_free: enablePayment ? isFree : "",
        },
      },
    });
    // } else {
    // 	return null;
    // }
  };

  const { data, isLoading, isSuccess, isError, hasNextPage, fetchNextPage } =
    useInfiniteQuery(
      [
        "recentCoursesMarketplace",
        { filterValue, filterBy, courseProviderValue, isFree },
      ],
      getRecentCoursesMarkeplace,
      {
        getNextPageParam: (_lastPage, pages) => {
          let coursesCount = pages[0]?.data.data.courses_count;

          const totalPages = Math.ceil(coursesCount / pageLimit);

          if (pages.length < totalPages) {
            return pages.length + 1;
          } else {
            return undefined;
          }
        },
        refetchOnWindowFocus: false,
      }
    );

  useEffect(() => {
    if (isSuccess) {
      if (data) {
        let allRecentCourses: any = [];
        let allCategories = data?.pages[0]?.data.data.categories;

        data?.pages.map((group) => {
          return group?.data.data.courses.map((course: any) => {
            return allRecentCourses.push(course);
          });
        });

        const firstFiveCourses = allRecentCourses.slice(0, 5);

        setFirstFiveCourses(firstFiveCourses);

        setRecentCoursesData(allRecentCourses);

        setCoursesCategories(allCategories);
      }
    }
  }, [isSuccess, data, pageLimit, setRecentCoursesData, setFirstFiveCourses]);

  useEffect(() => {
    if (isError) {
      Notification({
        message: "Unable to get recent courses, please try again later",
        type: "warning",
        title: "Warning",
      });
    }
  }, [isError]);

  return {
    isLoading,
    hasNextPage,
    fetchNextPage,
    coursesCategories,
    setCoursesCategories,
  };
}

export { useGetRecentCoursesMarketplace };
