import { useState, useEffect, useCallback } from "react";

import {
	useLegacyContext,
	useMykademyDataContext,
	usePortfolioDataContext,
} from "context";

function useProfileData() {
	const [profileWithSignout, setProfileWithSignout] = useState<any>([]);
	const [profileWithoutSignout, setProfileWithoutSignout] = useState<any>([]);

	const { isMykademyLegacy } = useLegacyContext();
	const { isMykademy } = useMykademyDataContext();
	const { isPortfolio } = usePortfolioDataContext();

	const checkForProfileTitle = useCallback(() => {
		if (isMykademyLegacy) {
			return "Mykademy Profile";
		} else if (isPortfolio) {
			return "Myportfolio Profile";
		} else if (isMykademy && !isMykademyLegacy) {
			return "VLE Profile";
		}
	}, [isMykademy, isMykademyLegacy, isPortfolio]);

	useEffect(() => {
		let withSignout = [
			{
				title: checkForProfileTitle(),
				icon: "ProfileIcon",
			},
			{
				title: "Sign out",
				icon: "KeyIcon",
			},
		];

		let withoutSignout = [
			{
				title: checkForProfileTitle(),
				icon: "ProfileIcon",
			},
		];

		setProfileWithSignout(withSignout);
		setProfileWithoutSignout(withoutSignout);
	}, [checkForProfileTitle]);

	return { profileWithSignout, profileWithoutSignout };
}

export { useProfileData };
