/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React from "react";

// Style imports
import { Button, BoxShadow } from "styles/components.styles";

import {
	Text,
	Title,
	Wrapper,
	Progress,
	CourseImage,
	ProgressBar,
	ButtonWrapper,
	ProgressWrapper,
	TopCourseWrapper,
	ProgressCompleted,
} from "components/coursesCommonWidget/coursesCommonWidget.styles";

const TopCourse = ({ title, topCourse, viewAllCoursesDeeplink }: any) => {
	let image;
	let header;
	let percentage;
	let dataId = "";

	if (title === "Courses") {
		const { cb_image, cb_title, cs_percentage, course_id } = topCourse;

		image = cb_image;
		header = cb_title;
		percentage = cs_percentage;
		dataId = course_id;
	} else if (title === "Portfolio") {
		const { occupation_logo, occupation_name, progress, id } = topCourse;

		image = occupation_logo;
		header = occupation_name;
		percentage = progress;
		dataId = id;
	}

	return (
		<BoxShadow css={{ marginTop: "15px", display: "flex", padding: "0" }}>
			<Wrapper>
				<CourseImage src={image} alt="" />

				<TopCourseWrapper>
					<Text>Jump back in</Text>
					<Title>{header}</Title>
					<ButtonWrapper>
						<Button
							variant="purple"
							css={{ marginRight: "15px" }}
							onClick={() => viewAllCoursesDeeplink(dataId)}
						>
							{parseInt(percentage) ? "Resume" : "Start"}
						</Button>
						<ProgressWrapper>
							<ProgressCompleted>{`Progress: ${
								percentage ? percentage : "0"
							} %`}</ProgressCompleted>
							<Progress>
								<ProgressBar
									role="progressbar"
									background={percentage === "100" ? "#13a10e" : "#6264a7"}
									css={{
										width: percentage + "%",
										ariaValuenow: "25",
										ariaValuemin: "0",
										ariaValuemax: "100",
									}}
								/>
							</Progress>
						</ProgressWrapper>
					</ButtonWrapper>
				</TopCourseWrapper>
			</Wrapper>
		</BoxShadow>
	);
};

export default TopCourse;
