/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import { InputFile } from "styles/form.styles";

import {
  UploadImg,
  UploadImgWrap,
  UploadLogoContent,
  AssociatedQuestion,
  AssociatedQuestionText,
  AssociatedQuestionImage,
} from "components/imgCropper/imgCropper.styles";

import ImgQuestion from "assets/images/common/question.svg";
import ImgUploadIcon from "assets/images/register/upload-icon.png";

interface UploadImageInitialProps {
  image: any;
  register: any;
  onChangeHandler: Function;
}

function UploadImageInitial({
  image,
  register,
  onChangeHandler,
}: UploadImageInitialProps) {
  const { ref } = register("avatar");

  return (
    <UploadLogoContent image={image}>
      <UploadImgWrap>
        <UploadImg src={ImgUploadIcon} alt="upload img logo" />
        Upload image
        <InputFile
          type="file"
          accept="image/png, image/jpg, image/jpeg"
          ref={ref}
          name="avatar"
          onChange={(e) => onChangeHandler(e)}
          onClick={(event: any) => {
            event.target.value = null;
          }}
        />
      </UploadImgWrap>

      <AssociatedQuestion>
        <AssociatedQuestionImage src={ImgQuestion} alt="associated question" />
        <AssociatedQuestionText>
          Using a square version of your logo will look best.
        </AssociatedQuestionText>
      </AssociatedQuestion>
    </UploadLogoContent>
  );
}

export default UploadImageInitial;
