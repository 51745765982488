import { useState, useEffect } from "react";

// Third party imports
import { useInfiniteQuery } from "react-query";

// Util imports
import { client } from "utils/api-client";
import { Notification } from "utils/notification";

// Context imports
import { useVleTokenContext, useCoursesFilterContext } from "context";

function useGetCourseList() {
	const [courseList, setCourseList] = useState<any>(null);
	const [coursesNearCompletionData, setCoursesNearCompletionData] = useState(
		[]
	);

	const [pageLimit, setPageLimit] = useState(8);

	const { vleToken } = useVleTokenContext();

	const { filterValue } = useCoursesFilterContext();

	const getCourseList = ({ pageParam = 1 }: any) => {
		return client({
			method: "get",
			endpoint: "api/akademy/course-list",
			optional: {
				token: vleToken,
				params: {
					page_limit: pageLimit,
					page: pageParam,
					search: filterValue,
					course_status: 1,
				},
			},
		});
	};

	const {
		data,
		error,
		isLoading,
		isSuccess,
		isError,
		hasNextPage,
		fetchNextPage,
	} = useInfiniteQuery(["courseList", { filterValue }], getCourseList, {
		getNextPageParam: (_lastPage, pages) => {
			let coursesCount = pages[0].data.data.total;

			const totalPages = Math.ceil(coursesCount / pageLimit);

			if (pages.length < totalPages) {
				return pages.length + 1;
			} else {
				return undefined;
			}
		},
		refetchOnWindowFocus: false,
	});

	useEffect(() => {
		if (isSuccess) {
			let allCourseList: any = [];

			data?.pages.map((group) => {
				return group.data.data.data.map((course: any) => {
					return allCourseList.push(course);
				});
			});

			const coursesAccordingToCompletion = allCourseList.filter(
				(courses: any) =>
					courses.cs_percentage !== "100" && courses.cs_percentage !== "0"
			);

			setCoursesNearCompletionData(coursesAccordingToCompletion);

			setCourseList(allCourseList);
		}
	}, [data, isSuccess]);

	useEffect(() => {
		if (isError) {
			console.log({ error });

			Notification({
				message: "Unable to get course list, please try again later",
				type: "warning",
				title: "Warning",
			});
		}
	}, [error, isError]);

	return {
		isSuccess,
		isLoading,
		courseList,
		hasNextPage,
		fetchNextPage,
		coursesNearCompletionData,
	};
}

export { useGetCourseList };
