import { useState, useEffect } from "react";

import { client } from "utils/api-client";

import { useQuery } from "react-query";

import { Notification } from "utils/notification";

import { useVleTokenContext } from "context";

function useGetUserAnalyticsCoursesTab() {
  const [userAnalytics, setUserAnalytics] = useState<any | {}>({});
  const [totalCompletedPercentage, setTotalCompletedPercentage] = useState<
    number | null
  >(null);
  const [totalInProgressPercentage, setTotalInProgressPercentage] = useState<
    number | null
  >(null);
  const [totalNotStartedPercentage, setTotalNotStartedPercentage] = useState<
    number | null
  >(null);

  const { vleToken } = useVleTokenContext();

  const getUserAnalyticscourseTab = () => {
    return client({
      method: "get",
      endpoint: "api/akademy/old-user-analytics",
      optional: {
        token: vleToken,
      },
    });
  };

  const { data, error, isSuccess, isLoading, isError } = useQuery(
    "userAnalyticsCoursesTab",
    getUserAnalyticscourseTab,
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (isSuccess) {
      const userAnalytics = data?.data?.data.data;

      const totalCompletedPercentage = Math.round(
        (userAnalytics.completed * 100) / data?.data.data.total
      );

      const totalInProgressPercentage = Math.round(
        (userAnalytics.in_progress * 100) / data?.data.data.total
      );
      const totalNotStartedPercentage = Math.round(
        (userAnalytics.not_started * 100) / data?.data.data.total
      );

      setUserAnalytics(userAnalytics);
      setTotalCompletedPercentage(totalCompletedPercentage);
      setTotalInProgressPercentage(totalInProgressPercentage);
      setTotalNotStartedPercentage(totalNotStartedPercentage);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (isError) {
      console.log({ error });

      Notification({
        message:
          "Unable to get the user analytics data, please try again later",
        type: "warning",
        title: "Warning",
      });
    }
  }, [error, isError]);

  return {
    userAnalytics,
    isLoadingUserAnalytics: isLoading,
    totalCompletedPercentage,
    totalInProgressPercentage,
    totalNotStartedPercentage,
  };
}

export { useGetUserAnalyticsCoursesTab };
