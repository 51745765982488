import { useEffect, useRef, useState } from "react";

export const useShowTooltip = () => {
	const titleRef = useRef(null);
	const [isEllipseEnabled, setIsEllipseEnabled] = useState(false);

	const compareSize = () => {
		const compare =
			//@ts-ignore
			titleRef?.current?.scrollWidth > titleRef?.current?.clientWidth;
		setIsEllipseEnabled(compare);
	};

	useEffect(() => {
		compareSize();
		window.addEventListener("resize", compareSize);
		return () => window.removeEventListener("resize", compareSize);
	}, []);

	return { isEllipseEnabled, titleRef };
};
