/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React, { Fragment } from "react";

import { Doughnut } from "react-chartjs-2";

import { Col } from "react-bootstrap";

import { Chart, Legend, Tooltip, ArcElement } from "chart.js";

import { BoxShadow, CustomHeightSpinner } from "styles/components.styles";

import { DoughnutWrapper } from "styles/charts.styles";

import {
	Count,
	Progress,
	Percentage,
	InfoWrapper,
} from "./halfDoughnut.styles";

interface DoughnutChartProps {
	halfDoughtNutData: (number | null)[];
	totalProgressHeader: any;
	isLoadingUserAnalytics: boolean;
}

Chart.register(ArcElement, Tooltip, Legend);

const HalfDoughnut = ({
	halfDoughtNutData,
	totalProgressHeader,
	isLoadingUserAnalytics,
}: DoughnutChartProps) => {
	const totalCompletedPercentage = halfDoughtNutData[0];

	const data = {
		datasets: [
			{
				data: halfDoughtNutData,
				backgroundColor: [
					totalCompletedPercentage === 100 ? "#13A10E" : "#13A10E",
					"#6264A7",
					"#D9DBDB",
				],
				hoverBackgroundColor: [
					totalCompletedPercentage === 100 ? "#13A10E" : "#13A10E",
					"#6264A7",
					"#D9DBDB",
				],
				label: "Expenditures",
			},
		],
		labels: ["Completed", "In Progress", "Not Started"],
	};

	const options = {
		responsive: true,
		rotation: -28.8 * Math.PI,
		circumference: 57.4 * Math.PI,
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				callbacks: {
					label: function (tooltipItem: any) {
						var index = tooltipItem.dataIndex;

						var caption = tooltipItem.label;

						var captionValue = tooltipItem.dataset.data[index];

						return `${caption}:${captionValue}%`;
					},
				},
				xAlign: "center",
				yAlign: "bottom",
			},
		},
		cutout: "50",

		animation: {
			animateScale: true,
			animateRotate: true,
		},
	};

	return (
		<Col xs={12} sm={6} md={6} lg={4} xl={3} css={{ marginBottom: "20px" }}>
			<BoxShadow css={{ height: "100%" }}>
				<Fragment>
					<h4 css={{ marginBottom: "20px" }}>
						{/* Total Progress */}
						{totalProgressHeader?.label}
					</h4>
					{isLoadingUserAnalytics ? (
						<CustomHeightSpinner height="245px" />
					) : (
						<Fragment>
							<DoughnutWrapper>
								{/* @ts-ignore */}
								<Doughnut data={data} options={options} />
								<Percentage>{totalCompletedPercentage}%</Percentage>
							</DoughnutWrapper>
							<InfoWrapper>
								<Count>0%</Count>
								<Progress>Complete</Progress>
								<Count>100%</Count>
							</InfoWrapper>
						</Fragment>
					)}
				</Fragment>
			</BoxShadow>
		</Col>
	);
};

export default HalfDoughnut;
