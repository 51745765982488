import React, { Fragment } from "react";
import { Col } from "react-bootstrap";
import BundleCard from "./bundleCard";
import { BundleStackWrapper } from "./bundleCardStack.styles";

const BundleCardStack = ({ bundle }: any) => {
  const { c_title: title, c_courses } = bundle;
  const courseList = JSON.parse(c_courses);
  let children;

  if (courseList?.length === 1) {
    children = [1, 2, 3].map((item: any) => (
      <BundleCard
        title={title}
        imageUrl={courseList[0]?.course_image}
        courseName={courseList[0]?.course_name}
        key={item}
        totalCourse={courseList.length}
        bundle={bundle}
      />
    ));
  }
  if (courseList?.length === 2) {
    children = (
      <Fragment>
        <BundleCard
          key={1}
          title={title}
          imageUrl={courseList[0]?.course_image}
          courseName={courseList[0]?.course_name}
          totalCourse={courseList.length}
          bundle={bundle}
        />
        <BundleCard
          key={2}
          title={title}
          imageUrl={courseList[1]?.course_image}
          courseName={courseList[1]?.course_name}
          totalCourse={courseList.length}
          bundle={bundle}
        />
        <BundleCard
          key={3}
          title={title}
          imageUrl={courseList[0]?.course_image}
          courseName={courseList[0]?.course_name}
          totalCourse={courseList.length}
          bundle={bundle}
        />
      </Fragment>
    );
  }
  if (courseList?.length === 3 || courseList?.length > 3) {
    children = courseList
      ?.slice(0, 3)
      .reverse()
      .map((item: any, index: any) => (
        <BundleCard
          courseName={item?.course_name}
          imageUrl={item?.course_image}
          title={title}
          key={index}
          totalCourse={courseList.length}
          bundle={bundle}
        />
      ));
  }

  return (
    <Col
      sm={12}
      md={6}
      lg={4}
      xl={4}
      xxl={3}
      style={{ padding: "30px", marginBottom: "20px" }}
    >
      <BundleStackWrapper>{children}</BundleStackWrapper>
    </Col>
  );
};

export default BundleCardStack;
