/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React, { Fragment } from "react";

import { BiDotsVerticalRounded } from "react-icons/bi";

import { Ul } from "screens/getStartedTab/getStarted.styles";

const EnablePopupModal = () => {
	return (
		<Fragment>
			<h2
				css={{
					textAlign: "center",
					margin: "36px 0",
					textTransform: "uppercase",
				}}
			>
				Please enable popups in your browser to enable sso login
			</h2>
			<h3>For Chrome:</h3>
			<Ul>
				<li>
					• &nbsp;At the top right, click&nbsp;
					<strong>
						More{" "}
						<BiDotsVerticalRounded
							css={{ verticalAlign: "text-top", marginLeft: "-4px" }}
						/>
					</strong>
					&nbsp;and then &nbsp;<strong>Settings</strong>.
				</li>
				<li>
					• &nbsp;Click &nbsp;<strong>Privacy and security</strong>&nbsp; and
					then&nbsp;
					<strong>Site Settings</strong>.
				</li>
				<li>
					• &nbsp;Click &nbsp;<strong>Pop-ups and redirects</strong>.
				</li>
				<li>
					• &nbsp;Click &nbsp;<strong>Add</strong>&nbsp; button beside&nbsp;
					<strong>Allowed to send pop-ups and use redirects</strong>&nbsp;
					section
				</li>
				<li>
					• &nbsp;Add "https://teams.microsoft.com" to allow popups in teams
					application
				</li>
				<li>• &nbsp;Reload teams VLE application</li>
			</Ul>
		</Fragment>
	);
};

export default EnablePopupModal;
