import styled from "@emotion/styled/macro";
import * as mq from "styles/media-queries";
import * as colors from "styles/colors";

interface MPWrapper {
	hideSidebar: boolean;
	isMyEnrollments?: boolean;
}

interface LCBProps {
	isSelected: boolean;
}

const MarketPlaceWrapper = styled.div<MPWrapper>`
	padding-left: ${({ hideSidebar }) => (hideSidebar ? "0" : "200px")};
	background: #f5f5f5;
	min-height: 101vh;

	${mq.smallPhone} {
		padding: ${({ isMyEnrollments }) =>
			isMyEnrollments ? "65px 0 30px 0%" : "210px 0 30px 0"};

		width: ${({ hideSidebar }) => (hideSidebar ? "100%" : "0")};
		display: ${({ hideSidebar }) => (hideSidebar ? "block" : "none")};
	}

	${mq.desktop} {
		padding: ${({ hideSidebar }) =>
			hideSidebar ? "60px 30px 30px 30px" : "60px 0 30px 200px"};

		width: ${({ hideSidebar }) => (hideSidebar ? "100%" : "100%")};
		display: block;
	}

	.react-flow {
		// height: 100vh !important;
		// width: 100vw !important;
		// overflow: hidden !important;
	}

	.react-flow__node:last-child {
		border: 1px solid #2fc05180 !important;
		color: #2fc051;
	}
`;

const CourseBox = styled.div`
	background: #fff;
	border: 1px solid #fff;
	box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
	border-radius: 4px;
	height: 100%;
	position: relative;

	img {
		object-fit: contain;
	}
`;

const CourseImage = styled.img`
	// height: 177px;
	width: 100%;
	object-fit: fill;
	border-radius: 6px 6px 0 0;
`;

const LearnerCourseBox = styled.div<LCBProps>`
	background: #fff;
	border: ${({ isSelected }) =>
		isSelected ? `3px solid ${colors.green}` : "3px solid #ededed"};
	box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
	border-radius: 4px;
	height: 100%;
	position: relative;
	cursor: pointer;
	transition: transform 0.3s;

	:hover {
		transform: translateY(-0.5rem) scale(1.03);
	}

	// ${CourseImage} {
	// 	height: 150px;
	// }
`;

const CourseTitleWrapper = styled.div`
	height: 60px;
`;

const CourseInfo = styled.div`
	padding: 24px 16px;
	position: relative;
	display: flex;
	flex-direction: column;
	// height: calc(100% - 177px);
`;

export {
	CourseBox,
	CourseInfo,
	CourseImage,
	LearnerCourseBox,
	MarketPlaceWrapper,
	CourseTitleWrapper,
};
