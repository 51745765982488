import React from "react";

import styled from "@emotion/styled/macro";

const Label = styled.label`
  color: #fff;
  font-size: 12px;
  text-transform: capitalize;
`;

interface TextAreaStyleProps {
  theme: string;
}

const TextAreaStyle = styled.textarea<TextAreaStyleProps>`
  height: 119px;
  padding: 6px 12px;
  width: 100%;
  border: 0;
  border-radius: 3px;
  color: ${({ theme }) => (theme === "dark" ? "white" : "#242424")};
  background: ${({ theme }) => (theme === "dark" ? "#2d2d2d" : "white")};
  font-size: 14px;
`;
interface TextAreaProps {
  label: string;
  placeholder: string;
  theme?: string;
  register: any;
}

const TextArea = ({
  label,
  placeholder,
  register,
  theme = "light",
}: TextAreaProps) => (
  <>
    <Label className="label-form">{label}</Label>
    <TextAreaStyle
      {...register("anythingElse")}
      theme={theme}
      placeholder={placeholder}
    />
  </>
);

export default TextArea;
