import React, { useEffect, useMemo, useState } from "react";

interface cartParams {
  carts: [];
  setCarts: (state: any) => null;
  addToCart: (course: any) => null;
  removeFromCart: (course: any) => null;
  isAddedToCart: boolean;
  setIsAddedToCart: (state: any) => null;
  clearCart: () => null;
}

const CartStateContext = React.createContext<cartParams | undefined>(undefined);

CartStateContext.displayName = "CartStateContext";

function CartContextProvider(props: any) {
  const [carts, setCarts] = useState<any>([]);

  useEffect(() => {
    const cartsValue = localStorage.getItem("carts");
    if (cartsValue) {
      setCarts(JSON.parse(cartsValue));
    }
  }, []);

  const value = useMemo(() => {
    const addToCart = (course: any) => {
      if (course) {
        localStorage.setItem("carts", JSON.stringify([...carts, course]));
        setCarts((prevValue: any) => [...prevValue, course]);
      }
    };
    const removeFromCart = (id: string) => {
      if (id) {
        const filteredCarts = carts.filter((cart: any) => cart.id !== id);
        localStorage.setItem("carts", filteredCarts);
        setCarts(filteredCarts);
      }
    };

    const clearCart = () => {
      localStorage.removeItem("carts");
      setCarts([]);
    };

    return {
      carts,
      setCarts,
      addToCart,
      clearCart,
      removeFromCart,
    };
  }, [carts]);

  return <CartStateContext.Provider value={value} {...props} />;
}

function useCartStateContext() {
  const cartsState = React.useContext(CartStateContext);

  if (cartsState === undefined) {
    throw new Error(
      `useCartStateContext must be used within a CartContextProvider`
    );
  }

  return cartsState;
}

export { CartContextProvider, useCartStateContext };
