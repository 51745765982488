import {
	useSelectedListStateContext,
	useIsCheckAllUpdaterContext,
	useSelectedListUpdaterContext,
} from "context";

import { ChangeEvent } from "react";
export interface HandleSelectAllProps {
	event: ChangeEvent<HTMLInputElement>;
	allListData: any[];
	selectedListKey: string;
}

export interface HandleSelectUsersProps {
	event: ChangeEvent<HTMLInputElement>;
	selectedData: any;
}

interface IsCheckedProps {
	selectedData: any;
}

function useMultiCheckbox() {
	const selectedList: any = useSelectedListStateContext();
	const setSelectedList = useSelectedListUpdaterContext();

	const setIsCheckAll = useIsCheckAllUpdaterContext();

	const handleSelectAll = ({
		event,
		allListData,
		selectedListKey,
	}: HandleSelectAllProps) => {
		if (event.target.checked) {
			setIsCheckAll(true);

			const selectedDataArr = allListData.map((data: any) => {
				return data[selectedListKey];
			});

			setSelectedList(selectedDataArr);
		} else {
			setIsCheckAll(false);

			setSelectedList([]);
		}
	};

	const handleSelectUsers = ({
		event,
		selectedData,
	}: HandleSelectUsersProps) => {
		if (event.target.checked) {
			setSelectedList([...selectedList, selectedData]);
		} else {
			setSelectedList(
				selectedList.filter((listData: any) => listData !== selectedData)
			);
		}
	};

	const isChecked = ({ selectedData }: IsCheckedProps) => {
		if (selectedList.includes(selectedData)) {
			return true;
		} else {
			return false;
		}
	};

	return { handleSelectAll, handleSelectUsers, isChecked };
}

export { useMultiCheckbox };
