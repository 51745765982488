import { FC, useMemo, useRef } from "react";
import JoditEditor from "jodit-react";

//Style imports
import { ErrorMessage, Wrapper } from "./editor.styles";

interface IEditorProps {
	error: any;
	defaultValue: string;
	onChange: (...event: any[]) => void;
	optionList: string[];
}

const getConfig = (props: string[]) => {
	const config = {
		readonly: false,
		toolbarAdaptive: false,
		disablePlugins: ["inline-popup"],
		placeholder: "Enter template message",
		tabIndex: -1,
		height: "600px",
		buttons: [
			"source",
			"|",
			"bold",
			"strikethrough",
			"underline",
			"italic",
			"|",
			"ul",
			"ol",
			"|",
			"outdent",
			"indent",
			"|",
			"font",
			"fontsize",
			"brush",
			"paragraph",
			"|",
			"image",
			"video",
			"table",
			"link",
			"|",
			"align",
			"undo",
			"redo",
			"|",
			"hr",
			"eraser",
			"copyformat",
			"|",
			"symbol",
			"fullsize",
			"print",
			"about",
			{
				name: "Email Variables",
				tooltip: "Select email variables",
				list: props,
				exec(editor: any, _: any, { control }: any) {
					let value = control.args && control.args[0];
					if (value) {
						editor.s.insertHTML(`$\{${value}}`);
					}
					editor.setEditorValue();

					return false;
				},
			},
		],
		colors: ["#ff0000", "#00ff00", "#0000ff"],
		colorPickerDefaultTab: "background",
		spellcheck: true,
		editorCssClass: false,
		triggerChangeEvent: true,
		enter: "P",
		showBrowserColorPicker: true,
		iframe: false,
	};
	return config;
};

const Editor: FC<IEditorProps> = ({
	error,
	onChange,
	defaultValue,
	optionList,
}) => {
	const editor = useRef(null);

	const newConfig = useMemo(() => getConfig(optionList), [optionList]);

	return (
		<Wrapper error={error}>
			<JoditEditor
				ref={editor}
				value={defaultValue}
				//@ts-ignore
				config={newConfig}
				onBlur={onChange}
			/>
			{error ? <ErrorMessage>{error?.message}</ErrorMessage> : null}
		</Wrapper>
	);
};

export default Editor;
