/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React, { Fragment } from "react";

// Components import
import { TickCircleIcon } from "components/svg";
import { Modal, ModalOpenButton, ModalContents, PopupVideo } from "components";

// Style import
import { Button } from "styles/components.styles";

import {
	Text,
	Image,
	Title,
	VideoImg,
	ArrowIcon,
	ImgWrapper,
	AssetImage,
	TitleWrapper,
	OnboardingStepWrapper,
} from "components/onboardingSteps/onboardingSteps.styles";

// Image imports
import VideoIcon from "assets/images/common/watch-vido-icon.svg";
import RightArrowIcon from "assets/images/common/step-arrow-right.png";

const OnboardingStep = ({
	index,
	array,
	expandedView,
	onboardingStep,
	onboardingStepsData,
}: any) => {
	const { icon, image, title, active, text, videoSrc, imgSrc } =
		onboardingStepsData[onboardingStep];

	const Icon = icon;

	const checkIfLastStep = array[array.length - 1] === onboardingStep;

	return (
		<OnboardingStepWrapper>
			{expandedView ? <Image src={image} alt={title} /> : null}

			<TitleWrapper>
				{active ? <TickCircleIcon color="#13a10e" /> : <Icon />}
				{/* <Icon /> */}

				{active ? (
					<Title color="#13a10e">{title}</Title>
				) : (
					<Title>{title}</Title>
				)}

				{checkIfLastStep ? null : (
					<ArrowIcon src={RightArrowIcon} alt="arrow" />
				)}
			</TitleWrapper>
			{expandedView ? (
				<Fragment>
					<Text>{text}</Text>
					{videoSrc || imgSrc ? (
						<Modal maxwidth="800px">
							<ModalOpenButton>
								<Button variant="purpleText" css={{ marginLeft: "15px" }}>
									<VideoImg src={VideoIcon} alt="video icon" />
									Watch How
								</Button>
							</ModalOpenButton>
							<ModalContents aria-label="Video Player">
								{/* <ImgWrapper>
									<AssetImage
										src="https://picsum.photos/id/237/200/300"
										alt={title}
									/>
								</ImgWrapper> */}

								{videoSrc ? (
									<PopupVideo title={title} videoSrc={videoSrc} />
								) : imgSrc ? (
									<ImgWrapper>
										<AssetImage src={imgSrc} alt={title} />
									</ImgWrapper>
								) : null}
							</ModalContents>
						</Modal>
					) : null}
				</Fragment>
			) : null}
		</OnboardingStepWrapper>
	);
};

export default OnboardingStep;
