import { useState, useEffect } from "react";

import { client } from "utils/api-client";

import { useQuery, useQueryClient } from "react-query";

import { Notification } from "utils/notification";

import { useVleTokenContext } from "context";

function useGetCourseProviders() {
	// const { setCourseLibraryData, setTotalPages } = useCourseLibraryContext();

	const [courseProviders, setCourseProviders] = useState([]);

	const { vleToken } = useVleTokenContext();

	const queryClient = useQueryClient();

	const getCourseProviders = () => {
		return client({
			method: "get",
			endpoint: "api/client-course-providers",
			optional: {
				token: vleToken,
				params: {
					isActive: true,
				},
			},
		});
	};

	const { data, isLoading, isSuccess, isError } = useQuery(
		"courseProviders",
		getCourseProviders,
		{
			select(data) {
				return data?.data?.data?.map((courseProvider: any) => {
					let optionValue;

					if (courseProvider.hasOwnProperty("provider")) {
						const { id, acct_email } = courseProvider.provider;

						optionValue = `${acct_email} ${id}`;
					} else {
						optionValue = `${courseProvider.title} ${courseProvider._id}`;
					}
					return {
						label: courseProvider?.title,
						value: optionValue,
						isDefault: courseProvider?.isDefault,
					};
				});
			},
		}
	);

	useEffect(() => {
		if (isSuccess) {
			// const courseProviders: [] = data?.data.data;

			setCourseProviders(data);
		}
	}, [data, isSuccess, queryClient]);

	useEffect(() => {
		if (isError) {
			Notification({
				message: "Unable to get course providers, please try again later",
				type: "warning",
				title: "Warning",
			});
		}
	}, [isError]);

	return {
		isLoading,
		courseProviders,
		setCourseProviders,
	};
}

export { useGetCourseProviders };
