import React, { useMemo } from "react";

import { localStorageKeys, useLocalStorageState } from "hooks";

interface LegacyContextParams {
  isLegacy: boolean | string | null;
  setIsLegacy: (state: boolean | string | null) => null;
  removeIsLegacyFromLocalStorage: () => null;

  legacyType: string;
  setLegacyType: (state: string) => null;
  removeLegacyTypeFromLocalStorage: () => null;
}

const LegacyContext = React.createContext<LegacyContextParams | undefined>(
  undefined
);

LegacyContext.displayName = "LegacyContext";

function LegacyProvider(props: any) {
  const [isLegacy, setIsLegacy, removeIsLegacyFromLocalStorage] =
    useLocalStorageState({
      key: localStorageKeys.isLegacy,
    });

  const [legacyType, setLegacyType, removeLegacyTypeFromLocalStorage] =
    useLocalStorageState({
      key: localStorageKeys.legacyType,
    });

  const value = useMemo(() => {
    return {
      isLegacy,
      setIsLegacy,
      removeIsLegacyFromLocalStorage,
      legacyType,
      setLegacyType,
      removeLegacyTypeFromLocalStorage,
    };
  }, [
    isLegacy,
    legacyType,
    setIsLegacy,
    setLegacyType,
    removeIsLegacyFromLocalStorage,
    removeLegacyTypeFromLocalStorage,
  ]);

  return <LegacyContext.Provider value={value} {...props} />;
}

function useLegacyContext() {
  const context = React.useContext(LegacyContext);

  if (context === undefined) {
    throw new Error(`useLegacyContext must be used within a LegacyProvider`);
  }

  const { isLegacy, legacyType } = context;

  const isMykademyLegacy = isLegacy && legacyType === "mykademy";
  const isPortfolioLegacy = isLegacy && legacyType === "portfolio";

  return {
    ...context,

    isMykademyLegacy: isMykademyLegacy,
    isPortfolioLegacy: isPortfolioLegacy,
  };
}

export { LegacyProvider, useLegacyContext };
