import { OnboardingIcon } from "screens/register/register.styles";

interface TickIconProps {
  color?: string;
  width?: string;
  height?: string;
}

const DeadlingIcon = ({
  color,
  width = "20px",
  height = "20px",
}: TickIconProps) => {
  return (
    <OnboardingIcon
      width={width}
      height={height}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path d="M9.354 6.396c.195.196.195.512 0 .708l-1.75 1.75c-.19.189-.494.195-.691.015l-.75-.685c-.204-.186-.218-.502-.032-.706.186-.204.502-.218.706-.032l.397.362 1.412-1.412c.196-.195.512-.195.708 0zm0 5.708c.195-.196.195-.512 0-.707-.196-.196-.512-.196-.708 0l-1.412 1.412-.397-.363c-.204-.187-.52-.172-.706.032-.186.204-.172.52.032.706l.75.685c.197.18.502.174.69-.015l1.75-1.75zm1.146.396c0-.276.224-.5.5-.5h2.5c.276 0 .5.224.5.5s-.224.5-.5.5H11c-.276 0-.5-.224-.5-.5zM11 7c-.276 0-.5.224-.5.5s.224.5.5.5h2.5c.276 0 .5-.224.5-.5s-.224-.5-.5-.5H11zM3 4.5C3 3.672 3.672 3 4.5 3h11c.828 0 1.5.672 1.5 1.5v11c0 .828-.672 1.5-1.5 1.5h-11c-.828 0-1.5-.672-1.5-1.5v-11zM4.5 4c-.276 0-.5.224-.5.5v11c0 .276.224.5.5.5h11c.276 0 .5-.224.5-.5v-11c0-.276-.224-.5-.5-.5h-11z"></path>
    </OnboardingIcon>
  );
};

export default DeadlingIcon;
