/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React, { useState } from "react";

import { Wrapper } from "./profileInformation.styles";

import ShowProfileInfo from "./showProfileInfo";
import EditProfileInfo from "./editProfileInfo";
import EditPassword from "./editPassword";

import { useCreateProfileInfo } from "./useCreateProfileInfo";

const ProfileInformation = ({ showSsoUpdateButton }: any) => {
	const [pageType, setPageType] = useState<
		"showProfileInfo" | "editProfileInfo" | "editPassword"
	>("showProfileInfo");

	const { profileInfo } = useCreateProfileInfo();

	function routeToPage(route: any) {
		setPageType(route);
	}

	return (
		<Wrapper>
			{pageType === "showProfileInfo" ? (
				<ShowProfileInfo
					routeToPage={routeToPage}
					profileInfo={profileInfo}
					showSsoUpdateButton={showSsoUpdateButton}
				/>
			) : pageType === "editProfileInfo" ? (
				<EditProfileInfo routeToPage={routeToPage} profileInfo={profileInfo} />
			) : pageType === "editPassword" ? (
				<EditPassword routeToPage={routeToPage} profileInfo={profileInfo} />
			) : null}
		</Wrapper>
	);
};

export default ProfileInformation;
