import { OnboardingIcon } from "screens/register/register.styles";

const LiveSessionIcon = () => {
  return (
    <OnboardingIcon
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.65849 1.94595C1.91717 0.807726 2.92907 0 4.09632 0H11.9034C13.0707 0 14.0826 0.807726 14.3412 1.94595L15.9321 8.94595C16.2877 10.5104 15.0986 12 13.4943 12H6.99996V11H13.4943C14.4569 11 15.1703 10.1062 14.957 9.16757L13.3661 2.16757C13.2109 1.48464 12.6038 1 11.9034 1H4.09632C3.39597 1 2.78883 1.48464 2.63362 2.16757L2.21716 4H1.49996C1.39406 4 1.2897 4.00659 1.18726 4.01937L1.65849 1.94595ZM6.94995 14H12.5C12.7761 14 13 13.7761 13 13.5C13 13.2239 12.7761 13 12.5 13H6.99996V13.5C6.99996 13.6712 6.98275 13.8384 6.94995 14ZM3 9C3.55228 9 4 8.55228 4 8C4 7.44772 3.55228 7 3 7C2.44772 7 2 7.44772 2 8C2 8.55228 2.44772 9 3 9ZM0 6.5C0 5.67157 0.671573 5 1.5 5H4.5C5.32843 5 6 5.67157 6 6.5V13.5C6 14.3284 5.32843 15 4.5 15H1.5C0.671573 15 0 14.3284 0 13.5V6.5ZM1.5 6C1.22386 6 1 6.22386 1 6.5V13.5C1 13.7761 1.22386 14 1.5 14H4.5C4.77614 14 5 13.7761 5 13.5V6.5C5 6.22386 4.77614 6 4.5 6H1.5Z"></path>
    </OnboardingIcon>
  );
};

export default LiveSessionIcon;
