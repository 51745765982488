import React, { useMemo, useState } from "react";

interface CourseHeaderContextProps {
  showFilter: boolean;
  setShowFilter: React.Dispatch<React.SetStateAction<boolean>>;
}

const CourseHeaderContext = React.createContext<
  CourseHeaderContextProps | undefined
>(undefined);

function CourseHeaderProvider(props: any) {
  const [showFilter, setShowFilter] = useState(false);

  const value = useMemo(() => {
    return {
      showFilter,
      setShowFilter,
    };
  }, [showFilter]);

  return <CourseHeaderContext.Provider value={value} {...props} />;
}

function useCourseHeaderContext() {
  const context = React.useContext(CourseHeaderContext);

  if (context === undefined) {
    throw new Error(
      `useCourseHeaderContext must be used within a CourseHeaderProvider`
    );
  }

  const { showFilter, setShowFilter } = context;

  return {
    showFilter,
    setShowFilter,
  };
}

export { CourseHeaderProvider, useCourseHeaderContext };
