import { useEffect } from "react";

// Third party imports
import { useMutation, useQueryClient } from "react-query";

// Util imports
import { client } from "utils/api-client";
import { Notification } from "utils/notification";

// Context imports
import { useVleTokenContext } from "context";

function useUnEnrollCourse() {
	const queryClient = useQueryClient();

	const { vleToken } = useVleTokenContext();

	const unEnrollFromCourse = (courseId: string) => {
		const apiBody = {
			course_id: courseId,
		};

		return client({
			method: "post",
			endpoint: "api/akademy/unsubscribe-course",
			optional: {
				data: apiBody,
				token: vleToken,
			},
		});
	};

	const { mutate, isLoading, isError, error } = useMutation(
		unEnrollFromCourse,
		{
			onSuccess: () => {
				queryClient.invalidateQueries("courseDetails");
				queryClient.invalidateQueries("mykademyLearnerCourses");
				queryClient.invalidateQueries("pathwayDetails");
				queryClient.invalidateQueries("learnerCourseList");

				queryClient.removeQueries("courseDetails");
				queryClient.removeQueries("mykademyLearnerCourses");
				queryClient.removeQueries("pathwayDetails");
				queryClient.removeQueries("learnerCourseList");

				Notification({
					message: "Course unenrolled Successfully",
					type: "success",
					title: "Success",
				});
			},
		}
	);

	useEffect(() => {
		if (isError) {
			Notification({
				message: "Unable to unenroll course, please try again later",
				type: "warning",
				title: "Warning",
			});
		}
	}, [error, isError]);

	return {
		mutate,
		isLoading,
	};
}

export { useUnEnrollCourse };
