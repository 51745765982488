import React, { useState, useMemo } from "react";

interface CsvDownloadContextParams {
  fileName: string;
  setFileName: (state: string) => void;
  dataToBeExported: any;
  setDataToBeExported: React.Dispatch<React.SetStateAction<any>>;
}

const CsvDownloadContext = React.createContext<
  CsvDownloadContextParams | undefined
>(undefined);

CsvDownloadContext.displayName = "CsvDownloadContext";

function CsvDownloadContextProvider(props: any) {
  const [dataToBeExported, setDataToBeExported] = useState<any>([]);
  const [fileName, setFileName] = useState<string>("");

  const value = useMemo(() => {
    return {
      dataToBeExported,
      setDataToBeExported,
      fileName,
      setFileName,
    };
  }, [dataToBeExported, fileName]);

  return <CsvDownloadContext.Provider value={value} {...props} />;
}

function useCsvDownloadContext() {
  const context = React.useContext(CsvDownloadContext);

  if (context === undefined) {
    throw new Error(
      `useCsvDownloadContext must be used within a CsvDownloadContextProvider`
    );
  }

  return context;
}

export { CsvDownloadContextProvider, useCsvDownloadContext };
