/** @jsxRuntime classic */
/** @jsx jsx */

import styled from "@emotion/styled/macro";

import ArrowWhite from "assets/images/getStarted/select-arrow-white.svg";

interface listProps {
  showDropdown: boolean;
}

const SelectContainer = styled.div({
  display: "inline-block",
  position: "relative",
  width: "100%",
  backgroundColor: "#252423",
  borderRadius: "5px",
  color: "white",
  fontSize: "14px",
});

const SelectedTextWrapper = styled.div((props: listProps) => ({
  padding: "10px 20px",
  cursor: "pointer",
  borderBottom: props.showDropdown ? "2px solid #00ffa6" : 0,
  display: "flex",
  alignItems: "center",

  ":after": {
    color: "#484644",
    content: '""',
    width: "16px",
    height: "8px",
    right: "10px",
    top: "10px",
    background: `url(${ArrowWhite}) no-repeat`,
    transform: props.showDropdown ? "rotate(0deg)" : "rotate(180deg)",
  },
}));

const ListItems = styled.ul((props: listProps) => ({
  position: "absolute",
  width: "100%",
  minHeight: props.showDropdown ? "50px" : 0,
  opacity: props.showDropdown ? 1 : 0,
  visibility: props.showDropdown ? "visible" : "hidden",
  top: "inherit",
  bottom: "100%",
  background: "#252423",
  paddingLeft: 0,
  overflow: "auto",
  margin: 0,
}));

const ListItem = styled.li({
  listStyleType: "none",
  padding: "12px 14px 10px",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",

  ":last-child": {
    borderBottom: "none",
  },

  ":hover": {
    backgroundColor: "#2f2e2d",
  },
});

const TellMeMoreIcon = styled.img({
  marginRight: "15px",
});

const SelectedText = styled.div({
  flex: 1,
});

export {
  SelectContainer,
  SelectedTextWrapper,
  ListItems,
  ListItem,
  TellMeMoreIcon,
  SelectedText,
};
