import styled from "@emotion/styled/macro";

import * as mq from "styles/media-queries";

interface UlProps {
	hideSidebar: boolean;
}

const Ul = styled.ul<UlProps>`
	position: fixed;
	bottom: 0;

	border-right: 1px solid #e0e0e0;
	margin: 0;
	padding: 0;

	display: ${({ hideSidebar }) => (hideSidebar ? "none" : "flex")};
	justify-content: space-between;
	flex-wrap: wrap-reverse;

	${mq.smallPhone} {
		width: 100%;
	}

	${mq.tabletWide} {
		width: 200px;
	}
`;

const Li = styled.li`
	display: flex;
	align-items: center;
	justify-content: center;

	border-left: 1px solid #f2f2f0;
	border-top: 1px solid #f2f2f0;

	a {
		display: flex;
		align-items: center;
		color: #000;
		width: 100%;
		padding: 15px;
	}
`;

const Image = styled.img`
	margin-right: 3px;
	width: 16px;
`;

export { Ul, Li, Image };
