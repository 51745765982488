// Hook imports
import {
  localStorageKeys,
  useDeeplinkRouting,
  useLocalStorageState,
} from "hooks";

function useViewCourseDeeplink() {
  const { setDeeplinkParams } = useDeeplinkRouting();

  const [, setCourseId] = useLocalStorageState({
    key: localStorageKeys.courseId,
  });

  function viewCourseDeeplink(courseId?: string) {
    if (courseId) {
      setCourseId(courseId);
    }

    setDeeplinkParams({
      entityId: "mycourses",
      routeName: "course-list",
      historyPush: false,
      pageRefresh: false,
    });
  }

  return { viewCourseDeeplink };
}

export { useViewCourseDeeplink };
