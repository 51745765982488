import { useState, useEffect, useCallback } from "react";

import { queryCache } from "routes";

import { useDeeplinkRouting } from "hooks";

function useLogout() {
	const [logoutLoader, setLogoutLoader] = useState(false);
	const [logout, setLogout] = useState(false);

	const { setDeeplinkParams } = useDeeplinkRouting();

	const goToGetStartedTab = useCallback(() => {
		setDeeplinkParams({
			// entityId: "getstarted",
			// routeName: "tab",
			entityId: "index",
			routeName: "dashboard",
			historyPush: false,
			pageRefresh: true,
		});
	}, [setDeeplinkParams]);

	useEffect(() => {
		if (logout) {
			setLogoutLoader(true);

			localStorage.clear();
			queryCache.clear();

			goToGetStartedTab();
		}

		return () => setLogout(false);
	}, [goToGetStartedTab, logout]);

	return { logout, setLogout, logoutLoader, setLogoutLoader };
}

export { useLogout };
