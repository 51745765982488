/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React from "react";

import { Table } from "react-bootstrap";

import CoursesTableBody from "components/coursesCommonWidget/coursesTableBody";

import { Tr, Th, Tbody, Thead } from "styles/table.styles";

interface LiveSessionTableProps {
  recentCourses: any;
  viewAllCoursesDeeplink: any;
  title: string;
}

const CoursesCommonTable = ({
  title,
  recentCourses,
  viewAllCoursesDeeplink,
}: LiveSessionTableProps) => {
  return (
    <Table responsive>
      <Thead>
        <Tr>
          <Th css={{ textAlign: "left" }}>Name</Th>
          <Th>Progress</Th>
          <Th></Th>
        </Tr>
      </Thead>

      <Tbody>
        <CoursesTableBody
          title={title}
          recentCourses={recentCourses}
          viewAllCoursesDeeplink={viewAllCoursesDeeplink}
        />
      </Tbody>
    </Table>
  );
};

export default CoursesCommonTable;
