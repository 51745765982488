import React, { Fragment, useState, useEffect } from "react";

// Component imports
import OnboardingStep from "./onboardingStep";

// Style imports
import { Button, BoxShadow, FullPageSpinner } from "styles/components.styles";

import {
	OnboardingStepsHeader,
	OnboardingStepsWrapper,
	OnboardingStepsHeaderWrap,
} from "components/onboardingSteps/onboardingSteps.styles";

// Hooks imports
import { useCreateOnboardingStepsData } from "./useCreateOnboardingStepsData";

// Context imports
import {
	useOnboardingContext,
	useMykademyDataContext,
	usePortfolioDataContext,
} from "context";

// Icon imports
import { FiMinimize2 } from "react-icons/fi";
import { AiOutlineExpandAlt } from "react-icons/ai";

// Interface imports
import { OnboardingAssetProps } from "ts/interfaces/assets.interfaces";

interface OnboardingProperties {
	step_one: boolean;
	step_two: boolean;
	step_three: boolean;
	step_four: boolean;
	step_five: boolean;
	step_six: boolean;
}

interface OnboardingAssetsProps {
	isLoading: boolean;
	onboardingAssets: OnboardingAssetProps[];
}

const OnboardingSteps = ({
	isLoading,
	onboardingAssets,
}: OnboardingAssetsProps) => {
	const [expandedView, setExpandedView] = useState(true);

	const { setOnboardingSteps } = useOnboardingContext();

	const { mykademyUserData } = useMykademyDataContext();
	const { portfolioUserData } = usePortfolioDataContext();

	const { onboardingStepsData } = useCreateOnboardingStepsData({
		onboardingAssets,
	});

	useEffect(() => {
		if (mykademyUserData || portfolioUserData) {
			let onBoardingProcess: any;

			if (mykademyUserData) {
				onBoardingProcess = mykademyUserData.onBoardingProcess;
			} else if (portfolioUserData) {
				onBoardingProcess = portfolioUserData?.onBoardingProcess;
			}

			if (onBoardingProcess) {
				const {
					step_one,
					step_two,
					step_three,
					step_four,
					step_five,
					step_six,
				} = onBoardingProcess;

				const onboardingSteps: OnboardingProperties = {
					step_one: step_one ? true : false,
					step_two: step_two ? true : false,
					step_three: step_three ? true : false,
					step_four: step_four ? true : false,
					step_five: step_five ? true : false,
					step_six: step_six ? true : false,
				};

				setOnboardingSteps(onboardingSteps);
			}
		}
	}, [mykademyUserData, portfolioUserData, setOnboardingSteps]);

	return (
		<Fragment>
			{isLoading ? (
				<FullPageSpinner />
			) : (
				<BoxShadow>
					<OnboardingStepsHeaderWrap>
						<OnboardingStepsHeader>
							Maximising your VLE app
						</OnboardingStepsHeader>
						<Button
							variant="transparent"
							onClick={() => setExpandedView((prevState) => !prevState)}
						>
							{expandedView ? <FiMinimize2 /> : <AiOutlineExpandAlt />}
						</Button>
					</OnboardingStepsHeaderWrap>
					<OnboardingStepsWrapper>
						{Object.keys(onboardingStepsData).map(
							(onboardingStep, index, array: any) => {
								return (
									<OnboardingStep
										key={onboardingStep}
										array={array}
										index={index}
										expandedView={expandedView}
										onboardingStep={onboardingStep}
										onboardingStepsData={onboardingStepsData}
									/>
								);
							}
						)}
					</OnboardingStepsWrapper>
				</BoxShadow>
			)}
		</Fragment>
	);
};

export default OnboardingSteps;
