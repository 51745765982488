/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React, { Fragment, useState, useEffect } from "react";

// Style imports
import { Button, CustomHeightSpinner } from "styles/components.styles";

// Component imports
import { NoDataLayout } from "components";

import TopPathway from "./topPathway";
import PathwayTable from "./pathwayTable";

// Image imports
import AllCoursesCompletedPortfolioImage from "assets/images/common/portfolio-img.png";
import AllCoursesCompletedMykademyImage from "assets/images/getStarted/courses-img.png";
// import { useDeeplinkRouting } from "hooks";

interface PathwayWidgetProps {
	title: string;
	allPathways: any;
	isLoading: boolean;
	deeplinkHandler: any;
	showLearnerMarketPlace: boolean;
}

const CoursesCommonWidget = ({
	title,
	isLoading,
	allPathways,
	deeplinkHandler,
}: // showLearnerMarketPlace,
PathwayWidgetProps) => {
	const [recentPathways, setRecentPathways] = useState([]);
	const [topPathway, setTopPathway] = useState<any | {}>({});
	const [allPathwaysCompleted, setAllPathwaysCompleted] = useState<
		boolean | null
	>(null);
	const [noPathwaysEnrolled, setNoPathwaysEnrolled] = useState<boolean | null>(
		null
	);

	const [src, setSrc] = useState("");
	const [allPathwaysCompletedText, setAllPathwaysCompletedText] = useState("");
	const [buttonName, setButtonName] = useState("");

	useEffect(() => {
		if (allPathways) {
			let allPathwaysInProgress = allPathways.filter(
				(pathway: any) => pathway.pathway_progress !== "100"
			);

			const allPathwaysCount = allPathways.length;

			if (allPathwaysCount !== 0 && allPathwaysInProgress.length === 0) {
				setAllPathwaysCompleted(true);
			}

			if (allPathwaysCount === 0) {
				setNoPathwaysEnrolled(true);
			} else {
				setNoPathwaysEnrolled(false);
			}

			if (allPathways && allPathwaysCount !== 0) {
				const allCoursesExceptFirst = allPathwaysInProgress.slice(1);
				const firstFiveCourses = allCoursesExceptFirst.slice(0, 5);

				setTopPathway(allPathwaysInProgress[0]);
				setRecentPathways(firstFiveCourses);
			}
		}
	}, [allPathways]);

	useEffect(() => {
		if (title === "Portfolio") {
			setSrc(AllCoursesCompletedPortfolioImage);
			setAllPathwaysCompletedText(
				"Currently, you’re not having any programme In Progress"
			);

			setButtonName("View Full Portfolio");
		} else if (title === "Pathway") {
			setSrc(AllCoursesCompletedMykademyImage);
			setAllPathwaysCompletedText(
				"At the moment, you don’t have any Pathways In Progress"
			);

			setButtonName("View All Pathway");
		}
	}, [title]);

	// Render phase
	if (isLoading) {
		return <CustomHeightSpinner height="400px" />;
	} else if (allPathwaysCompleted) {
		return (
			<NoDataLayout
				imgHeight="160px"
				imgSrc={src}
				text={allPathwaysCompletedText}
			/>
		);
	} else if (noPathwaysEnrolled) {
		return (
			<NoDataLayout imgHeight="160px" imgSrc={src}>
				<p>You are not enrolled in any pathways.</p>
			</NoDataLayout>
		);
	} else {
		return (
			<Fragment>
				<TopPathway
					title={title}
					topPathway={topPathway}
					deeplinkHandler={deeplinkHandler}
				/>
				{recentPathways && recentPathways.length > 0 && (
					<Fragment>
						<PathwayTable
							title={title}
							recentPathways={recentPathways}
							deeplinkhandler={deeplinkHandler}
						/>
						<Button
							variant="lightPurpleText"
							hoverColor="#0a58ca"
							onClick={() => {
								deeplinkHandler();
								localStorage.setItem("fromPathwayWidget", "yes");
							}}
							css={{ fontSize: "12px", marginTop: "25px" }}
						>
							{buttonName}
						</Button>
					</Fragment>
				)}
			</Fragment>
		);
	}
};

export default CoursesCommonWidget;
