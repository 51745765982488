/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React, { Fragment } from "react";

//ThirdParty imports
import { Tooltip } from "@material-ui/core";
import { BiLayerPlus } from "react-icons/bi";
import { useHistory, useRouteMatch } from "react-router-dom";

//Hooks imports
import { useShowTooltip } from "hooks";
import { useSubscribeBundle } from "../../useSubscribeBundle";
// import { useUnsubscribeBundle } from "../../useUnsubscribeBundle";

//Styles imports
import { Button } from "styles/components.styles";
import {
	CourseBox,
	CourseInfo,
	CourseImage,
} from "screens/coursesTab/coursesTab.styles";
import {
	BtnText,
	ContentBtnWrap,
	ContentDetailTitle,
} from "styles/courses.styles";

//Icon imports
import BundlesIcon from "assets/images/mykademyAdmin/layer.png";
import { Modal } from "components";
import { useModalContext } from "components/modal/modal";
import UnSubscribeBundlePopup from "../../unSubscribeBundlePopup/unSubscribeBundlePopup";

const withModal =
	(Component: any) =>
	({ ...props }: any) => {
		return (
			<Modal maxwidth="750px">
				<Component {...props} />
			</Modal>
		);
	};

const BundleCard = ({
	title,
	imageUrl,
	courseName,
	totalCourse,
	bundle,
}: any) => {
	const { isEllipseEnabled, titleRef } = useShowTooltip();

	const { isLoading: isSubscribing, mutate: subscribeBundle } =
		useSubscribeBundle();

	const { setIsOpen } = useModalContext();

	const { id, isEnrolled, ...rest } = bundle;

	const history = useHistory();

	const { url } = useRouteMatch();

	const subscribeToBundle = (bundleId: string) => {
		subscribeBundle({
			bundleId,
		});
	};

	return (
		<Fragment>
			<UnSubscribeBundlePopup
				id={id}
				name={bundle?.c_title}
				setIsOpen={setIsOpen}
			/>
			<CourseBox css={{ borderRadius: "6px" }} className="bundle-child">
				<CourseImage src={imageUrl} alt={courseName} />
				<CourseInfo>
					<Tooltip
						title={title}
						placement="top-start"
						disableHoverListener={!isEllipseEnabled}
					>
						<ContentDetailTitle
							ref={titleRef}
							css={{
								fontSize: "14px",
								whiteSpace: "nowrap",
								overflow: "hidden",
								textOverflow: "ellipsis",
							}}
						>
							{title}
						</ContentDetailTitle>
					</Tooltip>
					<div style={{ marginBottom: "20px" }}>
						<img
							src={BundlesIcon}
							style={{ width: "20px", height: "18px", marginRight: "6px" }}
							alt="bundle"
						/>
						<span>{`${totalCourse} in 1`}</span>
					</div>
					<ContentBtnWrap css={{ justifyContent: "flex-end", gap: "10px" }}>
						<Button
							variant={isEnrolled ? "danger" : "purple"}
							fontweight="600"
							disabled={isEnrolled ? false : isSubscribing}
							onClick={() => {
								if (isEnrolled) {
									setIsOpen(true);
								} else {
									subscribeToBundle(id);
								}
							}}
						>
							<BiLayerPlus />
							<BtnText>{isEnrolled ? "Unsubscribe" : "Subscribe"}</BtnText>
						</Button>

						<Button
							variant="white"
							fontweight="600"
							onClick={() =>
								history.push(`${url}/${id}`, {
									state: {
										id,
										isEnrolled,
										...rest,
									},
								})
							}
						>
							<BtnText css={{ margin: "0" }}>Details</BtnText>
						</Button>
					</ContentBtnWrap>
				</CourseInfo>
			</CourseBox>
		</Fragment>
	);
};

export default withModal(BundleCard);
