/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React from "react";

import { Table } from "react-bootstrap";

import PathwayTableBody from "../pathwayTableBody";

import { Tr, Th, Tbody, Thead } from "styles/table.styles";

interface LiveSessionTableProps {
  recentPathways: any;
  title: string;
  deeplinkhandler: any;
}

const PathwayTable = ({
  title,
  recentPathways,
  deeplinkhandler,
}: LiveSessionTableProps) => {
  return (
    <Table responsive>
      <Thead>
        <Tr>
          <Th css={{ textAlign: "left" }}>Name</Th>
          <Th>Progress</Th>
          <Th></Th>
        </Tr>
      </Thead>

      <Tbody>
        <PathwayTableBody
          title={title}
          recentPathways={recentPathways}
          deeplinkhandler={deeplinkhandler}
        />
      </Tbody>
    </Table>
  );
};

export default PathwayTable;
