import React from "react";
import { Route, Redirect } from "react-router-dom";

import { useUserRoleContext, useMykademyDataContext } from "context";

interface RolePrivateRouteProps {
	roles: string[];
	children: any;
	exact: boolean;
	path: string;
	tenantIdCheck?: boolean;
}

function RolePrivateRoute({
	roles,
	tenantIdCheck,
	children,
	...rest
}: RolePrivateRouteProps) {
	const { userRole } = useUserRoleContext();

	const { mykademyUserData } = useMykademyDataContext();

	const isValidRole = roles.includes(userRole);

	function showRouteCheck() {
		if (tenantIdCheck) {
			if (isValidRole && mykademyUserData.userData.tenant_id) {
				return children;
			} else {
				return <Redirect to={{ pathname: "/homeTab" }} />;
			}
		} else {
			if (isValidRole) {
				return children;
			} else {
				return <Redirect to={{ pathname: "/homeTab" }} />;
			}
		}
	}

	return <Route {...rest} render={() => showRouteCheck()} />;
}

export default RolePrivateRoute;
