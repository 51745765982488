/** @jsxRuntime classic */
/** @jsx jsx */

import styled from "@emotion/styled/macro";

const OnboardingWrapper = styled.div`
  padding: 40px 0;
`;

const AddContentTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const AddContentTitle = styled.h2`
  font-size: 18px;
  font-weight: bold;
  color: #242424;
  margin: 0;
`;

const AddContentSkipButton = styled.button`
  margin-left: 10px;
  font-size: 14px;
  color: #616161;
  border: 0;
  background: transparent;
  padding: 0;
`;

const AddContentWrapper = styled.div`
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  padding: 20px;
  background: #fff;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 14%), 0px 0px 2px rgb(0 0 0 / 12%);
  border-radius: 4px;
`;

const InfoWrap = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
  margin-right: 5px;

  font-size: 14px;
  line-height: 20px;
  color: #252424;
`;

const ContentImg = styled.img`
  width: 70px;
  border-radius: 4px;
  margin-right: 10px;
`;

const ContentTitle = styled.span``;

const AddBtnWrapper = styled.div`
  display: flex;
  align-self: center;
  justify-content: flex-end;
`;

const FooterFixed = styled.footer`
  background: #f5f5f5;
  border-top: 1px solid #e0e0e0;
  padding: 15px 0;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
`;

const ContinueIcon = styled.img`
  margin-left: 15px;
`;

const ContinueDescription = styled.span`
  margin-left: 20px;
  color: #616161;
  font-size: 14px;
`;

export {
  InfoWrap,
  ContentImg,
  FooterFixed,
  ContinueIcon,
  ContentTitle,
  AddBtnWrapper,
  AddContentTitle,
  AddContentWrapper,
  OnboardingWrapper,
  ContinueDescription,
  AddContentSkipButton,
  AddContentTitleWrapper,
};
