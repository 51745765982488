import React from "react";
import { useHistory, useLocation } from "react-router-dom";

//Icon imports
import ArrowLeftIcon from "assets/images/common/arrow-left.svg";
import { Button } from "styles/components.styles";

const GoBackButton = () => {
  const history = useHistory();

  const { pathname, search }: any = useLocation();
  const params = new URLSearchParams(search);
  const isNewMembersData = params.get("newMembers");

  //Check if current page is pathway enrollment page
  const isViewPathwayEnrollmentPage = pathname.includes(
    "/mykademyAdmin/viewPathwayEnrollment"
  );
  //Check if current page is videoclassroom all data page
  const isVideoClassRoomAllDataPage =
    pathname === "/mykademyAdmin/videoClassRoomAllData";

  //Check if current page is new members all data page
  const isNewMembersAllDataPage =
    pathname === "/mykademyAdmin/learners" && !!isNewMembersData;

  //Check if current page is active members all data page
  const isActiveMembersAllDataPage =
    pathname === "/mykademyAdmin/activeMembersAllData";

  //Check if current page is course all data page
  const isCourseAllDataPage = pathname === "/mykademyAdmin/courseAllData";

  //Check if current page is enrollment report all data page
  const isConsolidatedEnrollmentReportPage =
    pathname === "/mykademyAdmin/consolidatedEndrollmentReport";

  //Check if current page is market place details page
  const isMarketPlaceDetailsPage = pathname.match(
    /\/(learner-market-place|market-place)\/details/
  );

  //Check if current page is certificate page
  const isCertificatePage = pathname.match(/\/course-list\/my-pathways\/.+/);

  //Check if current page is flow diagram page
  const isFlowDiagramPage = pathname.includes("/coursesTab/flow-diagram");

  //Check if page is bundle details page
  const isBundleDetailPage = pathname.match(
    /\/(course-list|coursesTab)\/bundle-library\/.+/
  );

  //Check if page is cart page
  const isCartPage = pathname.match(/\/(course-list|coursesTab)\/cart/);
  return (
    <Button
      variant="greyText"
      hoverColor="#000"
      onClick={() => history.goBack()}
      style={{
        display:
          isViewPathwayEnrollmentPage ||
          isVideoClassRoomAllDataPage ||
          isNewMembersAllDataPage ||
          isConsolidatedEnrollmentReportPage ||
          isActiveMembersAllDataPage ||
          isCourseAllDataPage ||
          isMarketPlaceDetailsPage ||
          isCertificatePage ||
          isFlowDiagramPage ||
          isBundleDetailPage ||
          isCartPage
            ? "flex"
            : "none",
      }}
    >
      <img
        style={{ marginRight: "8px" }}
        src={ArrowLeftIcon}
        alt="arrow left icon"
      />
      Back
    </Button>
  );
};

export default GoBackButton;
