import React from "react";
import { Route, Redirect } from "react-router-dom";

import { useGetUserPayments } from "screens/membershipPlans/useGetUserPayments";

interface PrivateRouteProps {
	children: React.ReactNode;
	path: string;
}

function PrivateRoute({ children, ...rest }: PrivateRouteProps) {
	const { hasPendingBill } = useGetUserPayments();

	return (
		<Route
			{...rest}
			render={(props) =>
				hasPendingBill ? <Redirect to={{ pathname: "/homeTab" }} /> : children
			}
		/>
	);
}

export default PrivateRoute;
