import * as React from "react";
import { SsoProvider } from "./ssoContext";
import { LegacyProvider } from "./legacyContext";
import { DomainProvider } from "./domainContext";
import { CartContextProvider } from "./cartContext";
import { UserRoleProvider } from "./userRoleContext";
import { VleTokenProvider } from "./vleTokenContext";
import { CheckboxProvider } from "./checkboxContext";
import { OnboardingProvider } from "./onboardingContext";
import { PathwayContextProvider } from "./pathwayContext";
import { CourseHeaderProvider } from "./courseHeaderContext";
import { MykademyDataProvider } from "./mykademyDataContext";
import { AddedCoursesProvider } from "./addedCoursesContext";
import { PathwayTokenProvider } from "./pathwayTokenContext";
import { CoursesFilterProvider } from "./coursesFilterContext";
import { PortfolioDataProvider } from "./portfolioDataContext";
import { MykademyAdminProvider } from "./mykademyAdminContext";
import { SelectedOptionProvider } from "./selectedOptionContext";
import { LearnersFilterProvider } from "./learnersFilterContext";
import { CsvDownloadContextProvider } from "./csvDownloadContext";
import { CertificateContextProvider } from "./certificateContext";
import { PaymentResponseProvider } from "./paymentResponseContext";
import { NewMembersFilterProvider } from "./newMembersFilterContext";
import { MarketPlaceFilterProvider } from "./marketPlaceFilterContext";
import { CourseLibraryDataProvider } from "./courseLibraryDataContext";
import { RecentCoursesDataProvider } from "./recentCoursesDataContext";
import { ActiveMembersFilterProvider } from "./activeMembersFilterContext";
import { TrendingCoursesDataProvider } from "./trendingCoursesDataContext";
import { VideoClassRoomFilterProvider } from "./videoClassRoomDataContext";
import { PurchaseHistoryFilterProvider } from "./purchaseHistoryFilterContext";
import { CoursesPopularityFilterProvider } from "./coursesPopularityContext";
import { LearnerCourseFilterContextProvider } from "./learnerCourseFilterContext";

/**  --- Read the instructions.md file in this folder before using context --- */
function AppProviders({ children }: any) {
  return (
    <SelectedOptionProvider>
      <MykademyDataProvider>
        <PortfolioDataProvider>
          <OnboardingProvider>
            <UserRoleProvider>
              <VleTokenProvider>
                <LegacyProvider>
                  <DomainProvider>
                    <PathwayContextProvider>
                      <AddedCoursesProvider>
                        <CourseLibraryDataProvider>
                          <MarketPlaceFilterProvider>
                            <PaymentResponseProvider>
                              <CertificateContextProvider>
                                <CsvDownloadContextProvider>
                                  <PurchaseHistoryFilterProvider>
                                    <CoursesFilterProvider>
                                      <RecentCoursesDataProvider>
                                        <TrendingCoursesDataProvider>
                                          <PathwayTokenProvider>
                                            <ActiveMembersFilterProvider>
                                              <VideoClassRoomFilterProvider>
                                                <NewMembersFilterProvider>
                                                  <CoursesPopularityFilterProvider>
                                                    <LearnersFilterProvider>
                                                      <MykademyAdminProvider>
                                                        <CourseHeaderProvider>
                                                          <LearnerCourseFilterContextProvider>
                                                            <CartContextProvider>
                                                              <SsoProvider>
                                                                <CheckboxProvider>
                                                                  {children}
                                                                </CheckboxProvider>
                                                              </SsoProvider>
                                                            </CartContextProvider>
                                                          </LearnerCourseFilterContextProvider>
                                                        </CourseHeaderProvider>
                                                      </MykademyAdminProvider>
                                                    </LearnersFilterProvider>
                                                  </CoursesPopularityFilterProvider>
                                                </NewMembersFilterProvider>
                                              </VideoClassRoomFilterProvider>
                                            </ActiveMembersFilterProvider>
                                          </PathwayTokenProvider>
                                        </TrendingCoursesDataProvider>
                                      </RecentCoursesDataProvider>
                                    </CoursesFilterProvider>
                                  </PurchaseHistoryFilterProvider>
                                </CsvDownloadContextProvider>
                              </CertificateContextProvider>
                            </PaymentResponseProvider>
                          </MarketPlaceFilterProvider>
                        </CourseLibraryDataProvider>
                      </AddedCoursesProvider>
                    </PathwayContextProvider>
                  </DomainProvider>
                </LegacyProvider>
              </VleTokenProvider>
            </UserRoleProvider>
          </OnboardingProvider>
        </PortfolioDataProvider>
      </MykademyDataProvider>
    </SelectedOptionProvider>
  );
}

export default AppProviders;
