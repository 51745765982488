/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React from "react";

import CoursesTableRow from "components/coursesCommonWidget/coursesTableRow";

interface TableBodyProps {
  title: string;
  recentCourses: any;
  viewAllCoursesDeeplink: any;
}

function CoursesTableBody({
  title,
  recentCourses,
  viewAllCoursesDeeplink,
}: TableBodyProps) {
  if (title === "Courses") {
    return recentCourses.map(({ cb_title, cs_percentage, course_id }: any) => {
      return (
        <CoursesTableRow
          key={course_id}
          name={cb_title}
          progress={cs_percentage}
          courseId={course_id}
          viewAllCoursesDeeplink={viewAllCoursesDeeplink}
        />
      );
    });
  } else if (title === "Portfolio") {
    return recentCourses.map(({ occupation_name, progress, id }: any) => {
      return (
        <CoursesTableRow
          key={id}
          name={occupation_name}
          progress={progress}
          courseId={id}
          viewAllCoursesDeeplink={viewAllCoursesDeeplink}
        />
      );
    });
  } else {
    return null;
  }
}

export default CoursesTableBody;
