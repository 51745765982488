/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React from "react";

import { CreateAddLearningIcon } from "components/svg";

import { Button } from "styles/components.styles";

import { Tr, Td, NameWrapper } from "styles/table.styles";
// import { useHistory } from "react-router-dom";
// import { usePathwayUpdateContext } from "context";

interface DeadlinesTableRowProps {
	name: string;
	progress: string;
	pathwayId: string;
	deeplinkhandler: any;
}

const PathwayTableRow = ({
	name,
	progress,
	pathwayId,
	deeplinkhandler,
}: DeadlinesTableRowProps) => {
	//Route to coursesTab and set pathway data in local storage
	const handleDeepLink = () => {
		const pathwayData = {
			id: pathwayId,
			title: name,
			progress,
		};
		deeplinkhandler();
		localStorage.setItem("pathwayData", JSON.stringify(pathwayData));
	};

	return (
		<Tr key={name}>
			<Td>
				<NameWrapper>
					<CreateAddLearningIcon />
					<span css={{ marginLeft: "10px" }}>{name}</span>
				</NameWrapper>
			</Td>
			<Td>{progress ? progress : "0"} %</Td>
			<Td>
				<Button
					variant="lightPurpleText"
					hoverColor="#0a58ca"
					onClick={() => handleDeepLink()}
					css={{ fontSize: "12px" }}
				>
					{parseInt(progress) ? "Resume" : "Start"}
				</Button>
			</Td>
		</Tr>
	);
};

export default PathwayTableRow;
