import React, { ReactNode } from "react";

import styled from "@emotion/styled/macro";

interface ImgProps {
  imgHeight?: string;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 20px 15px;
`;

const Image = styled.img<ImgProps>`
  margin-bottom: 30px;
  height: ${({ imgHeight }) => (imgHeight ? imgHeight : "auto")};
`;

const Text = styled.p``;

interface NoDataLayoutProps {
  imgSrc: string;
  text?: string;
  imgHeight?: string;
  children?: ReactNode;
}

const NoDataLayout = ({
  imgHeight,
  imgSrc,
  text,
  children,
}: NoDataLayoutProps) => {
  return (
    <Wrapper>
      <Image src={imgSrc} alt={text} imgHeight={imgHeight} />
      <Text>{text}</Text>
      {children}
    </Wrapper>
  );
};

export default NoDataLayout;
