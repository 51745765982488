/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React from "react";

// Style imports
import { Icon, Input, Label, Indicator } from "./radioButton.styles";

// Context imports
import { useSelectedOptionContext } from "context";

interface RadioProps {
  item: any;
  chooseOption: ({ item }: { item: object }) => void;
}

const RadioButton = ({ item, chooseOption }: RadioProps) => {
  const { selectedOption } = useSelectedOptionContext();

  return (
    <Label htmlFor={item.value}>
      <Icon src={item.icon_path} alt={item.value} />
      {item.label}
      <Input
        type="radio"
        id={item.value}
        name="occupation"
        checked={item === selectedOption}
        onChange={() => chooseOption({ item })}
      />
      <Indicator />
    </Label>
  );
};

export default RadioButton;
