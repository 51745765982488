import styled from "@emotion/styled/macro";

const HelpResourcesWrapper = styled.div`
  padding: 0 20px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.h2`
  margin-bottom: 20px;
`;

const ChildWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 20px;
`;
const IconWrapper = styled.div`
  border: 1px solid black;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 34px;
  // height: 34px;
`;

const ChildHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  a {
    color: black;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const ChildHeader = styled.div`
  font-size: 10px;
`;
export {
  Title,
  ChildHeader,
  IconWrapper,
  ChildWrapper,
  ChildHeaderWrapper,
  HelpResourcesWrapper,
};
