/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React, { useState, useEffect, Fragment, useRef } from "react";

// Third party imports
import { useHistory } from "react-router-dom";

// Component imports
import NavbarDropdown from "components/navbar/navbarDropdown";

import {
	KeyIcon,
	PlanIcon,
	EmailIcon,
	LabelIcon,
	ProfileIcon,
	CoursesIcon,
	SuitcaseIcon,
	AdminToolsIcon,
} from "components/svg";

// Hook imports
import { useModalContext } from "components/modal/modal";
import { useLogout, useLinkSso, useIsMobileView } from "hooks";

// Style imports
import { Button, FullPageSpinner } from "styles/components.styles";

import {
	MenuItemsLi,
	MenuItemsTitle,
	MenuItemsImage,
} from "components/navbar/navbar.styles";

import { OliveLinkTransparent } from "screens/aiContentPro/aiContent.styles";

// Asset imports
import RightArrowIcon from "assets/images/common/arrow-right.svg";
import ArrowDownblue from "assets/images/common/arrow-down-blue.svg";

import BranchIcon from "assets/images/icon/branch.svg";
import BatchesIcon from "assets/images/mykademyAdmin/files.png";
import PathwayIcon from "assets/images/mykademyAdmin/pathway.svg";
import EnrollmentIcon from "assets/images/icon/pathwayEnrollment.svg";

// import ReportIcon from "assets/images/mykademyAdmin/report.png";
import ReportIcon from "assets/images/icon/report.png";

import EnrollIcon from "assets/images/icon/enroll.png";
import BundlesIcon from "assets/images/mykademyAdmin/layer.png";

import WindowsIcon from "assets/images/common/windows-icon.svg";
import LearnersIcon from "assets/images/getStarted/learners-icon.svg";
import CourseListIcon from "assets/images/getStarted/courses-icon.svg";
import VideoClassroomIcon from "assets/images/getStarted/video-classroom-icon.svg";

interface SubMenusParams {
	title: string;
	icon: any;
}

interface MenuItemDataProps {
	title: string;
	icon: any;
	route?: string;

	submenus?: SubMenusParams[];
}

interface MenuItemsProps {
	hasPendingBill?: boolean;
	showSsoButton: any;
	depthLevel: number;
	item: MenuItemDataProps;
	setType: React.Dispatch<React.SetStateAction<string>>;
}

const MenuItems = ({
	item,
	setType,
	depthLevel,
	showSsoButton,
	hasPendingBill,
}: MenuItemsProps) => {
	const [dropdown, setDropdown] = useState(false);

	const history = useHistory();

	const { setLinkSso } = useLinkSso({ url: null });

	const { isSmallTabletView } = useIsMobileView();

	const { setLogout, logoutLoader } = useLogout();

	const { setIsOpen } = useModalContext();

	const { title, icon } = item;

	const route = item?.route ?? "";

	const submenus = item?.submenus ?? null;

	const ref = useRef<HTMLLIElement>(null);

	const depthLeveLGreaterThanZero = depthLevel > 0;

	useEffect(() => {
		const handler = (event: any) => {
			if (dropdown && ref.current && !ref.current.contains(event.target)) {
				setDropdown(false);
			}
		};
		document.addEventListener("mousedown", handler);
		document.addEventListener("touchstart", handler);
		return () => {
			// Cleanup the event listener
			document.removeEventListener("mousedown", handler);
			document.removeEventListener("touchstart", handler);
		};
	}, [dropdown]);

	function routeToDestination(route: string, title: string) {
		if (route) {
			history.push(route);
		} else if (title === "Sign out") {
			setLogout(true);
		} else if (
			title === "Mykademy Profile" ||
			title === "Myportfolio Profile" ||
			title === "VLE Profile"
		) {
			setType("Profile");
			setIsOpen(true);
			console.log("Profile");
		} else if (title === "Help Resources") {
			setType("Help Resources");
			setIsOpen(true);
			console.log("Help");
		}

		// else if (title === "Configure Microsoft SSO") {
		// 	setLinkSso(true);
		// }
	}

	function getIcon(icon: string) {
		if (icon === "ProfileIcon") {
			return <ProfileIcon />;
		} else if (icon === "KeyIcon") {
			return <KeyIcon />;
		} else if (icon === "PlanIcon") {
			return <PlanIcon />;
		} else if (icon === "EmailIcon") {
			return <EmailIcon />;
		} else if (icon === "AdminToolsIcon") {
			return <AdminToolsIcon />;
		} else if (icon === "CoursesIcon") {
			return <CoursesIcon />;
		} else if (icon === "SuitcaseIcon") {
			return <SuitcaseIcon />;
		} else if (icon === "LearnersIcon") {
			return <img src={LearnersIcon} alt="learners icon" />;
		} else if (icon === "VideoIcon") {
			return <img src={VideoClassroomIcon} alt="video icon" />;
		} else if (icon === "CourseListIcon") {
			return <img src={CourseListIcon} alt="course list icon" />;
		} else if (icon === "BranchIcon") {
			return (
				<img src={BranchIcon} css={{ padding: "3px" }} alt="branch icon" />
			);
		} else if (icon === "BatchesIcon") {
			return (
				<img src={BatchesIcon} css={{ padding: "3px" }} alt="batches icon" />
			);
		} else if (icon === "ReportIcon") {
			return (
				<img src={ReportIcon} css={{ padding: "3px" }} alt="report icon" />
			);
		} else if (icon === "EnrollIcon") {
			return (
				<img src={EnrollIcon} css={{ padding: "3px" }} alt="enroll icon" />
			);
		} else if (icon === "BundlesIcon") {
			return (
				<img src={BundlesIcon} css={{ padding: "3px" }} alt="bundles icon" />
			);
		} else if (icon === "PathwayIcon") {
			return (
				<img src={PathwayIcon} css={{ padding: "3px" }} alt="pathway icon" />
			);
		} else if (icon === "EnrollmentIcon") {
			return (
				<img
					src={EnrollmentIcon}
					css={{ padding: "3px" }}
					alt="enrollment icon"
				/>
			);
		} else if (icon === "LabelIcon") {
			return <LabelIcon />;
		}
	}

	function onSubMenuClick(title: string, route: string | undefined) {
		if (route && !isSmallTabletView) {
			history.push(route);
		} else {
			setDropdown((prev) => !prev);
		}
	}

	const onMouseEnter = () => {
		if (depthLeveLGreaterThanZero && !isSmallTabletView) {
			setDropdown(true);
		}
	};

	const onMouseLeave = (event: any) => {
		if (depthLeveLGreaterThanZero && !isSmallTabletView) {
			setDropdown(false);
		}
	};

	const dropDownButtonVariant = depthLeveLGreaterThanZero
		? "blackText"
		: "purpleText";

	const dropDownButtonPadding = depthLeveLGreaterThanZero ? "10px 15px" : "";

	const dropDownHoverColor = depthLeveLGreaterThanZero ? "#6264a7" : "";
	const dropDownHoverBgColor = depthLeveLGreaterThanZero
		? "rgba(98,100,183,.07058823529411765)"
		: "";

	// console.log({ title, showSsoButton });

	return (
		<Fragment>
			{logoutLoader ? (
				<FullPageSpinner />
			) : (
				<MenuItemsLi
					ref={ref}
					onMouseEnter={onMouseEnter}
					onMouseLeave={onMouseLeave}
				>
					{submenus ? (
						<Fragment>
							<Button
								variant={dropDownButtonVariant}
								hoverColor={dropDownHoverColor}
								hoverBgColor={dropDownHoverBgColor}
								onClick={() => onSubMenuClick(title, route)}
								css={{
									width: "100%",
									padding: dropDownButtonPadding,
								}}
							>
								<div
									css={{
										flex: 1,
										textAlign: "left",
										display: "flex",
										alignItems: "center",
									}}
								>
									{getIcon(icon)}
									<MenuItemsTitle depthLevel={depthLevel} dropdown={dropdown}>
										{title}
									</MenuItemsTitle>
								</div>

								{depthLeveLGreaterThanZero && !isSmallTabletView ? (
									<img src={RightArrowIcon} alt="right arrow" />
								) : (
									<MenuItemsImage
										src={ArrowDownblue}
										alt="arrow down blue"
										dropdown={dropdown}
									/>
								)}
							</Button>
							<NavbarDropdown
								setType={setType}
								submenus={submenus}
								dropdown={dropdown}
								depthLevel={depthLevel}
								showSsoButton={showSsoButton}
							/>
						</Fragment>
					) : (
						<Fragment>
							{/* @ts-ignore */}
							{hasPendingBill && title === "AI Content Pro" ? (
								<OliveLinkTransparent
									href="https://contentpro.ai/"
									target="_blank"
									className="btn olive-ai-btn"
								>
									Olive AI Content Pro
								</OliveLinkTransparent>
							) : //  @ts-ignore
							title === "Configure Microsoft SSO" && showSsoButton ? (
								<Button variant="microsoftBg" onClick={() => setLinkSso(true)}>
									{getIcon(icon)}
									<MenuItemsTitle dropdown={dropdown}>
										{title}
										<img
											css={{ marginLeft: "10px", display: "flex" }}
											src={WindowsIcon}
											alt="Configure Microsoft SSO"
										/>
									</MenuItemsTitle>
								</Button>
							) : title === "Help Resources" ? (
								<span
									css={{
										width: "100%",
										display: "flex",
										alignItems: "center",
										justifyContent: "flex-start",
										padding: dropDownButtonPadding,
										cursor: "pointer",
									}}
									onClick={() => routeToDestination(route, title)}
								>
									<SuitcaseIcon />
									<span
										css={{
											marginLeft: "5px",
											color: "#212529",
											fontSize: "14px",
										}}
									>
										Help Resources
									</span>
								</span>
							) : (
								<Button
									variant={dropDownButtonVariant}
									hoverColor={dropDownHoverColor}
									hoverBgColor={dropDownHoverBgColor}
									onClick={() => routeToDestination(route, title)}
									css={{
										width: "100%",
										justifyContent: "flex-start",
										padding: dropDownButtonPadding,
									}}
								>
									<span>{getIcon(icon)}</span>
									<MenuItemsTitle dropdown={dropdown}>{title}</MenuItemsTitle>
								</Button>
							)}
						</Fragment>
					)}
				</MenuItemsLi>
			)}
		</Fragment>
	);
};

export default MenuItems;
