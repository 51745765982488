import styled from "@emotion/styled/macro";

import * as mq from "styles/media-queries";

interface DropdownUlProps {
  dropdown: boolean;
  depthLevel: number;
  isSmallTabletView?: boolean;
}

interface DropdownProps {
  dropdown: boolean;
  depthLevel?: number;
}

const MenuItemsTitle = styled.span<DropdownProps>`
  margin: 0 5px;
  font-weight: ${({ dropdown, depthLevel }) =>
    dropdown && depthLevel === 0 ? "600" : "normal"};
`;

const MenuItemsLi = styled.li`
  position: relative;
  min-width: 75px;

  ${mq.smallPhone} {
    :not(:last-child) {
      margin-bottom: 8px;
    }
  }

  ${mq.tabletWide} {
    :not(:last-child) {
      margin-bottom: 0px;
    }
  }

  button {
    span {
      display: flex;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }
`;

const MenuItemsImage = styled.img<DropdownProps>`
  transform: ${({ dropdown }) => (dropdown ? "rotate(180deg)" : "none")};
`;

const NavbarUl = styled.ul`
  margin: 0;
  display: flex;
  align-items: center;
  white-space: nowrap;

  ${mq.smallPhone} {
    flex-direction: column;
  }

  ${mq.tabletWide} {
    flex-direction: row;
  }
`;

const DropdownUl = styled.ul<DropdownUlProps>`
  display: ${({ dropdown }) => (dropdown ? "block" : "none")};
  position: absolute;
  top: ${({ depthLevel, isSmallTabletView }) =>
    depthLevel > 1 && !isSmallTabletView
      ? "-4px"
      : isSmallTabletView
      ? "41px"
      : "35px"};
  left: ${({ depthLevel, isSmallTabletView }) =>
    depthLevel > 1 && !isSmallTabletView
      ? "228px"
      : isSmallTabletView
      ? "50%"
      : "auto"};

  right: 0;
  z-index: 1000;
  width: 230px;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 0;

  box-shadow: 0px 0.6px 1.8px rgb(0 0 0 / 11%), 0px 3.2px 7.2px rgb(0 0 0 / 13%);
  border-radius: 3px;

  transform: ${({ isSmallTabletView }) =>
    isSmallTabletView ? "translate(-50%)" : ""};
`;

const DropdownTitle = styled.span`
  margin-left: 10px;
`;

export {
  NavbarUl,
  DropdownUl,
  MenuItemsLi,
  DropdownTitle,
  MenuItemsTitle,
  MenuItemsImage,
};
