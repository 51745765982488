import React, { FC, Fragment } from "react";

//Styles imports
import { PathwayStackWrapper } from "./pathwayImageStack.styles";

interface IPathwayImageStackProps {
  imgArray: any;
  isHomeTab: boolean;
  imageHeight: string;
}

const ImageComponent = ({ image_url, imageHeight }: any) => {
  return (
    <div className="card-item">
      <img
        src={image_url}
        alt="pathway"
        style={{
          borderRadius: "6px 6px 0 0",
          height: imageHeight,
          width: "100%",
        }}
      />
    </div>
  );
};

const PathwayImageStack: FC<IPathwayImageStackProps> = ({
  imgArray,
  isHomeTab,
  imageHeight,
}) => {
  let children;

  if (imgArray?.length === 1) {
    children = [1, 2, 3].map((item: any) => (
      <ImageComponent
        image_url={imgArray[0]?.cb_image || imgArray[0]?.image}
        imageHeight={imageHeight}
        key={item}
      />
    ));
  }
  if (imgArray?.length === 2) {
    children = (
      <Fragment>
        <ImageComponent
          image_url={imgArray[1]?.cb_image || imgArray[1]?.image}
          imageHeight={imageHeight}
          key={1}
        />
        <ImageComponent
          key={2}
          image_url={imgArray[0]?.cb_image || imgArray[0]?.image}
          imageHeight={imageHeight}
        />
        <ImageComponent
          key={3}
          image_url={imgArray[1]?.cb_image || imgArray[1]?.image}
          imageHeight={imageHeight}
        />
      </Fragment>
    );
  }

  if (imgArray?.length === 3 || imgArray?.length > 3) {
    children = imgArray
      ?.slice(0, 3)
      .reverse()
      .map((item: any, index: any) => (
        <ImageComponent
          key={index}
          image_url={item?.cb_image || item?.image}
          imageHeight={imageHeight}
        />
      ));
  }

  return (
    <PathwayStackWrapper isHomeTab={isHomeTab}>{children}</PathwayStackWrapper>
  );
};

export default PathwayImageStack;
