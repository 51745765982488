import { OnboardingIcon } from "screens/register/register.styles";

const KeyIcon = () => {
  return (
    <OnboardingIcon
      width="12px"
      height="12px"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 4C9.55228 4 10 3.55228 10 3C10 2.44772 9.55228 2 9 2C8.44772 2 8 2.44772 8 3C8 3.55228 8.44772 4 9 4Z"></path>
      <path d="M5.5 10V9.5H6.5C6.77614 9.5 7 9.27614 7 9V8H8C10.2091 8 12 6.20914 12 4C12 1.79086 10.2091 0 8 0C5.79086 0 4 1.79086 4 4C4 4.39211 4.05656 4.77171 4.16219 5.13071L0.292893 9C0.105356 9.18754 0 9.44189 0 9.70711V11C0 11.5523 0.447715 12 1 12H3C3.55229 12 4 11.5523 4 11V10.5H5C5.27614 10.5 5.5 10.2761 5.5 10ZM5 4C5 2.34315 6.34315 1 8 1C9.65685 1 11 2.34315 11 4C11 5.65685 9.65685 7 8 7H6.5C6.22386 7 6 7.22386 6 7.5V8.5H5C4.72386 8.5 4.5 8.72386 4.5 9V9.5H3.5C3.22386 9.5 3 9.72386 3 10V11H1V9.70711L5.08898 5.61813C5.22904 5.47807 5.27321 5.26854 5.20163 5.08386C5.07157 4.74835 5 4.38313 5 4Z"></path>
    </OnboardingIcon>
  );
};

export default KeyIcon;
