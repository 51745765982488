/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React, { Fragment } from "react";

// Third party imports
import { Row, Col } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { Select, MenuItem } from "@material-ui/core";
import { useHistory } from "react-router-dom";

// Component imports
import ContactSalesPopup from "./contactSalesPopup";
import { Input, TextArea, ModalContentsBase } from "components";

// Style imports
import { Button, Spinner } from "styles/components.styles";
import {
	Label,
	ErrorForm,
	SignInAnchor,
	ButtonWrapper,
	SubmitWrapper,
	SignInWrapper,
	ContactSalesWrapper,
} from "./contactSalesForm.styles";

// Utils imports
import { countryWithCode } from "utils/countryWithCode";

interface ContactSalesFormProps {
	error: any;
	errors: any;
	control: any;
	onSubmit: any;
	register: any;
	isLoading: boolean;
	clearErrors: any;
	handleSubmit: any;
	formType?: any;
	theme?: string;
}

const ContactSalesForm = ({
	error,
	errors,
	control,
	onSubmit,
	register,
	isLoading,
	clearErrors,
	handleSubmit,
	formType = "demoRequest",
	theme = "light",
}: ContactSalesFormProps) => {
	const history = useHistory();
	return (
		<ContactSalesWrapper theme={theme}>
			<ModalContentsBase>
				<ContactSalesPopup error={error} formType={formType} />
			</ModalContentsBase>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Row>
					<Col lg={6}>
						<Input
							label="Name"
							placeholder="Name"
							register={register}
							errors={errors}
							clearErrors={clearErrors}
							labelcolor="white"
							color={theme === "dark" ? "white" : "#242424"}
							backgroundcolor={theme === "dark" ? "#2d2d2d" : "white"}
							required
						/>
					</Col>
					<Col lg={6}>
						<Input
							label="Work Email Address"
							placeholder="Email Address"
							type="email"
							register={register}
							errors={errors}
							clearErrors={clearErrors}
							labelcolor="white"
							color={theme === "dark" ? "white" : "#242424"}
							backgroundcolor={theme === "dark" ? "#2d2d2d" : "white"}
							required
						/>
					</Col>
				</Row>

				<Row>
					<Col lg={6}>
						<Label className="label-form">Country</Label>

						<Controller
							render={({ field }) => (
								<Select
									{...field}
									displayEmpty
									value={field.value}
									onChange={(e) => {
										clearErrors("Country");
										field.onChange(e);
									}}
									MenuProps={{ disablePortal: true }}
									renderValue={(selected: any) => {
										if (!selected) {
											return (
												<Fragment>
													<p style={{ color: "#5c5c5c" }}>Choose country</p>
												</Fragment>
											);
										}

										/* --- This code is if we need country code as value but we need to show 
                           corresponding country name on the selected dropdown --- 
                    */

										// if (selected) {
										//   const selectedCountry = countryWithCode.find(
										//     (country) => selected === country.code
										//   );

										//   return selectedCountry.name;
										// }

										/* --- --- */

										// if (selected) {
										//   return (
										//     <Fragment>
										//       {/* <span css={{ fontSize: "10px" }}>Country</span> */}
										//       <p>{selected}</p>
										//     </Fragment>
										//   );
										// }

										if (selected) {
											return selected;
										}
									}}
								>
									<MenuItem value="" disabled>
										<p style={{ color: "#5c5c5c" }}>Choose country</p>
									</MenuItem>

									{countryWithCode.map((country: any) => {
										return (
											<MenuItem key={country.id} value={country.name}>
												{country.name}
											</MenuItem>
										);
									})}
								</Select>
							)}
							control={control}
							name="Country"
							rules={{ required: true }}
						/>
						{errors["Country"]?.type === "required" && (
							<ErrorForm>Country cannot be empty</ErrorForm>
						)}
					</Col>

					<Col lg={6}>
						<Input
							label="Company"
							placeholder="Company Name"
							register={register}
							errors={errors}
							clearErrors={clearErrors}
							labelcolor="white"
							color={theme === "dark" ? "white" : "#242424"}
							backgroundcolor={theme === "dark" ? "#2d2d2d" : "white"}
							required
						/>
					</Col>
				</Row>

				<Row>
					<Col lg={6}>
						<Input
							label="Industry"
							placeholder="Industry"
							register={register}
							errors={errors}
							clearErrors={clearErrors}
							labelcolor="white"
							color={theme === "dark" ? "white" : "#242424"}
							backgroundcolor={theme === "dark" ? "#2d2d2d" : "white"}
							required
						/>
					</Col>

					<Col lg={3}>
						<Label>Code</Label>

						<Controller
							render={({ field }: any) => {
								return (
									<Select
										{...field}
										displayEmpty
										value={field.phoneNumberExtension}
										onChange={(e) => {
											clearErrors("phoneNumberExtension");
											field.onChange(e);
										}}
										MenuProps={{ disablePortal: true }}
										renderValue={(selected: any) => {
											if (!selected) {
												return <p style={{ color: "#5c5c5c" }}>Code</p>;
											}

											// if (selected) {
											//   return (
											//     <Fragment>
											//       {/* <span css={{ fontSize: "10px" }}>Code</span> */}
											//       <p>{selected}</p>
											//     </Fragment>
											//   );
											// }

											if (selected) {
												return selected;
											}
										}}
									>
										<MenuItem value="" disabled selected>
											<p style={{ color: "#5c5c5c" }}>Code</p>
										</MenuItem>

										{countryWithCode.map((phoneCode: any) => {
											return (
												<MenuItem
													key={phoneCode.id}
													value={phoneCode.dial_code}
												>
													{phoneCode.dial_code}
												</MenuItem>
											);
										})}
									</Select>
								);
							}}
							control={control}
							name="phoneNumberExtension"
							rules={{ required: true }}
						/>
						{errors["phoneNumberExtension"]?.type === "required" && (
							<ErrorForm>Phone code cannot be empty</ErrorForm>
						)}
					</Col>
					<Col lg={3}>
						<Input
							type="text"
							label="Phone Number"
							placeholder="Phone Number"
							register={register}
							errors={errors}
							clearErrors={clearErrors}
							labelcolor="white"
							color={theme === "dark" ? "white" : "#242424"}
							backgroundcolor={theme === "dark" ? "#2d2d2d" : "white"}
							required
						/>
					</Col>
				</Row>

				<Row>
					<Col lg={12}>
						<TextArea
							theme={theme}
							label="anything else? (optional)"
							placeholder="Please describe what you’re looking for with OLIVE VLE"
							register={register}
						/>
					</Col>
				</Row>

				{formType === "talkToSales" ? (
					<ButtonWrapper>
						<Button
							variant="light"
							type="button"
							onClick={() => history.push("/getStartedTab")}
						>
							Cancel
						</Button>
						<Button
							variant="success"
							type="submit"
							fontweight="600"
							padding="8px 20px 9px"
							css={{ marginLeft: "8px" }}
							disabled={isLoading}
						>
							Submit
						</Button>
					</ButtonWrapper>
				) : formType === "demoRequest" ? (
					<SubmitWrapper>
						<SignInWrapper>
							Already an account{" "}
							<SignInAnchor
								href="https://staging-olive-automation.enfinlabs.com/"
								target="_blank"
							>
								Sign in
							</SignInAnchor>
						</SignInWrapper>
						<Button
							variant="blueBg"
							type="submit"
							fontweight="600"
							disabled={isLoading}
							css={{ width: "72px", height: "32px" }}
						>
							{isLoading ? (
								<Spinner css={{ width: "20px", height: "auto" }} />
							) : (
								"Submit"
							)}
							{/* <Spinner css={{ width: "20px", height: "auto" }} /> */}
						</Button>
					</SubmitWrapper>
				) : null}
			</form>
		</ContactSalesWrapper>
	);
};

export default ContactSalesForm;
