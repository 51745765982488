import React, { useState } from "react";

const LearnerIdStateContext = React.createContext<"" | undefined>(undefined);

const LearnerIdUpdaterContext = React.createContext<any | undefined>(undefined);

LearnerIdStateContext.displayName = "LearnerIdStateContext";
LearnerIdUpdaterContext.displayName = "LearnerIdUpdaterContext";

function MykademyAdminProvider(props: any) {
  const [learnerId, setLearnerId] = useState<"" | undefined>("");

  return (
    <LearnerIdStateContext.Provider value={learnerId}>
      <LearnerIdUpdaterContext.Provider value={setLearnerId}>
        {props.children}
      </LearnerIdUpdaterContext.Provider>
    </LearnerIdStateContext.Provider>
  );
}

function useLearnerIdStateContext() {
  const learnerIdState = React.useContext(LearnerIdStateContext);

  if (learnerIdState === undefined) {
    throw new Error(
      `useLearnerIdStateContext must be used within a MykademyAdminProvider`
    );
  }

  return learnerIdState;
}

function useLearnerIdUpdaterContext() {
  const setLearnerId = React.useContext(LearnerIdUpdaterContext);

  if (setLearnerId === undefined) {
    throw new Error(
      `useLearnerIdUpdaterContext must be used within a MykademyAdminProvider`
    );
  }

  return setLearnerId;
}

export {
  MykademyAdminProvider,
  useLearnerIdStateContext,
  useLearnerIdUpdaterContext,
};
