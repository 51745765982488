import { CheckboxContent } from "styles/checkbox.styles";

import { ChangeEvent } from "react";

import {
  HandleSelectAllProps,
  HandleSelectUsersProps,
} from "hooks/useMultiCheckbox/useMultiCheckbox";
import { Checkbox } from "@mui/material";

interface CommonCheckBoxProps {
  id: string;
  name: string;
  isChecked: boolean;
  allListData?: any[];
  selectedListKey?: string;
  selectedData?: any;
  checkAll?: boolean;

  handleSelectAll?: ({
    event,
    allListData,
    selectedListKey,
  }: HandleSelectAllProps) => void;
  handleSelectUsers?: ({ event, selectedData }: HandleSelectUsersProps) => void;
}

const CustomCheckbox = ({
  id,
  name,
  checkAll,
  isChecked,
  allListData,
  selectedData,
  selectedListKey,
  handleSelectAll,
  handleSelectUsers,
}: CommonCheckBoxProps) => {
  function onCheckboxChange(event: ChangeEvent<HTMLInputElement>) {
    if (checkAll && handleSelectAll && allListData && selectedListKey) {
      handleSelectAll({ event, allListData, selectedListKey });
    } else {
      if (handleSelectUsers) {
        handleSelectUsers({ event, selectedData });
      }
    }
  }

  return (
    <CheckboxContent>
      <Checkbox
        id={id}
        size="small"
        name={name}
        checked={isChecked}
        disableRipple
        sx={{
          "&.Mui-checked": {
            color: "#6264a7",
          },
        }}
        onChange={(e) => onCheckboxChange(e)}
      />
    </CheckboxContent>
  );
};

export default CustomCheckbox;
