/** @jsxRuntime classic */
/** @jsx jsx */

// Third party imports
import styled from "@emotion/styled/macro";

const OnboardingStepsHeaderWrap = styled.div`
	display: flex;
`;

const OnboardingStepsHeader = styled.h4`
	flex: 1;
	margin-bottom: 2px;
	color: #000;
`;

const OnboardingStepsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
`;

const OnboardingStepWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	margin-top: 20px;
	width: 280px;
	padding: 0 10px;
	flex: 1;
`;

const Image = styled.img`
	width: 75px;
	height: 60px;
	margin-bottom: 15px;
`;

const TitleWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 12px;
	position: relative;
	width: 100%;
	height: 34px;
`;

const Title = styled.span`
	font-weight: 600;
	display: inline-block;
	vertical-align: middle;
	margin-left: 10px;
	margin-right: 15px;
	color: ${({ color }) => (color ? color : "inherit")};
`;

const Text = styled.div`
	margin-left: 25px;
	margin-bottom: 12px;
	color: #212121;
	flex: 1;
`;

const VideoImg = styled.img`
	margin-right: 10px;
`;

const ArrowIcon = styled.img`
	width: 5px;
	height: 8px;
`;

const ImgWrapper = styled.div`
	min-height: 400px;
	background: #000;
	width: 100%;
	position: relative;
`;

const AssetImage = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
	object-fit: contain;
`;

export {
	Text,
	Title,
	Image,
	VideoImg,
	ArrowIcon,
	ImgWrapper,
	AssetImage,
	TitleWrapper,
	OnboardingStepWrapper,
	OnboardingStepsHeader,
	OnboardingStepsWrapper,
	OnboardingStepsHeaderWrap,
};
