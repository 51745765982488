/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React, { Fragment } from "react";

// Component imports
import { ModalContents } from "components";

// Hook imports

// Style imports
import { Button, CustomHeightSpinner } from "styles/components.styles";
import { useUnsubscribeBundle } from "../useUnsubscribeBundle";

interface UnSubscribeBundleProps {
	id: string;
	name: string;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const UnSubscribeBundlePopup = ({
	id,
	name,
	setIsOpen,
}: UnSubscribeBundleProps) => {
	const { isLoading, mutate } = useUnsubscribeBundle();

	function unSubscribeFromBundle() {
		mutate({
			bundleId: id,
		});
	}

	return (
		<ModalContents aria-label="unsubscribe bundle">
			{isLoading ? (
				<CustomHeightSpinner height="100px" />
			) : (
				<Fragment>
					<div
						css={{
							fontWeight: "bold",
							fontSize: "18px",
							marginBottom: "20px",
							marginTop: "20px",
							textAlign: "center",
						}}
					>
						Are you sure you want to unsubscribe from the bundle <br></br>{" "}
						{name} ?
					</div>

					<div css={{ display: "flex", justifyContent: "center" }}>
						<Button
							variant="blueBg"
							css={{ marginRight: "10px" }}
							onClick={() => unSubscribeFromBundle()}
						>
							Yes
						</Button>
						<Button variant="charcoalGrey" onClick={() => setIsOpen(false)}>
							No
						</Button>
					</div>
				</Fragment>
			)}
		</ModalContents>
	);
};

export default UnSubscribeBundlePopup;
