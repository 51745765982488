import { OnboardingIcon } from "screens/register/register.styles";

const PlanIcon = () => {
  return (
    <OnboardingIcon
      width="12px"
      height="10px"
      viewBox="0 0 12 10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 6C4.93191 6 5.71496 6.63738 5.93699 7.49998L11.5 7.5C11.7761 7.5 12 7.72386 12 8C12 8.24546 11.8231 8.44961 11.5899 8.49194L11.5 8.5L5.93673 8.50102C5.71435 9.36312 4.93155 10 4 10C3.06845 10 2.28565 9.36312 2.06327 8.50102L0.5 8.5C0.223858 8.5 0 8.27614 0 8C0 7.75454 0.176875 7.55039 0.410124 7.50806L0.5 7.5L2.06301 7.49998C2.28504 6.63738 3.06809 6 4 6ZM4 7C3.44772 7 3 7.44772 3 8C3 8.55228 3.44772 9 4 9C4.55228 9 5 8.55228 5 8C5 7.44772 4.55228 7 4 7ZM8 0C8.93191 0 9.71496 0.637377 9.93699 1.49998L11.5 1.5C11.7761 1.5 12 1.72386 12 2C12 2.24546 11.8231 2.44961 11.5899 2.49194L11.5 2.5L9.93673 2.50102C9.71435 3.36312 8.93155 4 8 4C7.06845 4 6.28565 3.36312 6.06327 2.50102L0.5 2.5C0.223858 2.5 0 2.27614 0 2C0 1.75454 0.176875 1.55039 0.410124 1.50806L0.5 1.5L6.06301 1.49998C6.28504 0.637377 7.06809 0 8 0ZM8 1C7.44772 1 7 1.44772 7 2C7 2.55228 7.44772 3 8 3C8.55228 3 9 2.55228 9 2C9 1.44772 8.55228 1 8 1Z"></path>
    </OnboardingIcon>
  );
};

export default PlanIcon;
