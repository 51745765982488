import React, { useState, useMemo } from "react";

interface CoursesPopularityFilterContextParams {
	dateRange: string | null;
	setDateRange: React.Dispatch<React.SetStateAction<null[]>>;
	filterBy: string;
	setFilterBy: (state: string) => void;
}

const CoursesPopularityFilterContext = React.createContext<
	CoursesPopularityFilterContextParams | undefined
>(undefined);

CoursesPopularityFilterContext.displayName = "CoursesPopularityFilterContext";

function CoursesPopularityFilterProvider(props: any) {
	const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
		null,
		null,
	]);
	const [filterBy, setFilterBy] = useState<string>("");

	const value = useMemo(() => {
		return {
			dateRange,
			setDateRange,
			filterBy,
			setFilterBy,
		};
	}, [dateRange, setDateRange, filterBy, setFilterBy]);

	return <CoursesPopularityFilterContext.Provider value={value} {...props} />;
}

function useCoursesPopularityFilterContext() {
	const context = React.useContext(CoursesPopularityFilterContext);

	if (context === undefined) {
		throw new Error(
			`useCoursesPopularityFilterContext must be used within a CoursesPopularityFilterProvider`
		);
	}

	return context;
}

export { CoursesPopularityFilterProvider, useCoursesPopularityFilterContext };
