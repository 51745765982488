import React from "react";
import { OnboardingIcon } from "screens/register/register.styles";

const LabelIcon = ({ width = "12px", height = "12px", color }: any) => {
  return (
    <OnboardingIcon
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      viewBox="0 0 278.045 278.045"
      xmlSpace="preserve"
      width={width}
      height={height}
      color={color}
    >
      <g>
        <path d="M224.093,0H53.952c-4.971,0-9,4.029-9,9v260.045c0,3.592,2.136,6.839,5.433,8.263c3.298,1.425,7.126,0.75,9.74-1.713   l78.897-74.352l78.897,74.352c1.704,1.605,3.923,2.45,6.175,2.45c1.203,0,2.417-0.241,3.565-0.737   c3.297-1.423,5.433-4.671,5.433-8.263V9C233.093,4.029,229.064,0,224.093,0z M215.093,18v13.158H62.952V18H215.093z    M145.196,182.326c-1.733-1.633-3.953-2.45-6.173-2.45s-4.439,0.817-6.173,2.45l-69.897,65.871V49.158h152.141v199.039   L145.196,182.326z" />
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </OnboardingIcon>
  );
};

export default LabelIcon;
