/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React from "react";

import { CreateAddLearningIcon } from "components/svg";

import { Button } from "styles/components.styles";

import { Tr, Td, NameWrapper } from "styles/table.styles";

interface DeadlinesTableRowProps {
  name: string;
  progress: string;
  courseId: string;
  viewAllCoursesDeeplink: any;
}

const CoursesTableRow = ({
  name,
  progress,
  courseId,
  viewAllCoursesDeeplink,
}: DeadlinesTableRowProps) => {
  return (
    <Tr key={name}>
      <Td>
        <NameWrapper>
          <CreateAddLearningIcon />
          <span css={{ marginLeft: "10px" }}>{name}</span>
        </NameWrapper>
      </Td>
      <Td>{progress ? progress : "0"} %</Td>
      <Td>
        <Button
          variant="lightPurpleText"
          hoverColor="#0a58ca"
          onClick={() => viewAllCoursesDeeplink(courseId)}
          css={{ fontSize: "12px" }}
        >
          {parseInt(progress) ? "Resume" : "Start"}
        </Button>
      </Td>
    </Tr>
  );
};

export default CoursesTableRow;
