import React, { useState } from "react";

const SelectedListStateContext = React.createContext<[] | undefined>(undefined);

const SelectedListUpdaterContext = React.createContext<any | undefined>(
  undefined
);

const IsCheckAllStateContext = React.createContext<boolean | undefined>(
  undefined
);

const IsCheckAllUpdaterContext = React.createContext<any | undefined>(
  undefined
);

SelectedListStateContext.displayName = "SelectedListStateContext";
SelectedListUpdaterContext.displayName = "SelectedListUpdaterContext";

function CheckboxProvider(props: any) {
  const [selectedList, setSelectedList] = useState<any>([]);
  const [isCheckAll, setIsCheckAll] = useState(false);

  return (
    <SelectedListStateContext.Provider value={selectedList}>
      <SelectedListUpdaterContext.Provider value={setSelectedList}>
        <IsCheckAllStateContext.Provider value={isCheckAll}>
          <IsCheckAllUpdaterContext.Provider value={setIsCheckAll}>
            {props.children}
          </IsCheckAllUpdaterContext.Provider>
        </IsCheckAllStateContext.Provider>
      </SelectedListUpdaterContext.Provider>
    </SelectedListStateContext.Provider>
  );
}

function useSelectedListStateContext() {
  const selectedList = React.useContext(SelectedListStateContext);

  if (selectedList === undefined) {
    throw new Error(
      `useSelectedListStateContext must be used within a CheckboxProvider`
    );
  }

  return selectedList;
}

function useSelectedListUpdaterContext() {
  const setSelectedList = React.useContext(SelectedListUpdaterContext);

  if (setSelectedList === undefined) {
    throw new Error(
      `useSelectedListUpdaterContext must be used within a CheckboxProvider`
    );
  }

  return setSelectedList;
}

function useIsCheckAllStateContext() {
  const isCheckAll = React.useContext(IsCheckAllStateContext);

  if (isCheckAll === undefined) {
    throw new Error(
      `useIsCheckAllStateContext must be used within a CheckboxProvider`
    );
  }

  return isCheckAll;
}

function useIsCheckAllUpdaterContext() {
  const setIsCheckAll = React.useContext(IsCheckAllUpdaterContext);

  if (setIsCheckAll === undefined) {
    throw new Error(
      `useIsCheckAllUpdaterContext must be used within a CheckboxProvider`
    );
  }

  return setIsCheckAll;
}

export {
  CheckboxProvider,
  useIsCheckAllStateContext,
  useSelectedListStateContext,
  useIsCheckAllUpdaterContext,
  useSelectedListUpdaterContext,
};
