// Third party imports
import styled from "@emotion/styled/macro";

import * as mq from "styles/media-queries";

const Text = styled.div`
  font-size: 12px;
  letter-spacing: -0.3px;
  margin: 0 0 6px;
`;

const Title = styled.h4`
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.35px;
`;

const CourseImage = styled.img`
  height: 145px;
  width: 100%;

  ${mq.smallPhone} {
    width: 100%;
    margin: 0;
  }

  ${mq.tabletWide} {
    width: 200px;
    margin: 0 15px 0 0;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

const TopCourseWrapper = styled.div`
  flex: 1;
  align-self: center;

  ${mq.smallPhone} {
    margin-top: 20px;
    width: 100%;
    padding: 0 20px 15px;
  }

  ${mq.tabletWide} {
    padding: 0 20px 10px 5px;

    margin-top: 0px;
    width: auto;
  }
`;
interface ProgressProps {
  height?: string;
}

const Progress = styled.div<ProgressProps>`
  height: ${({ height }) => (height ? height : "2px")};
  margin: 0;
  border-radius: 0;
  display: flex;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
`;

const ProgressWrapper = styled.div`
  flex: 1;
`;

const ProgressCompleted = styled.span`
  color: #484848;
  margin-bottom: 12px;
  display: block;
  font-size: 12px;
`;

interface ProgressBarProps {
  background?: string;
  width?: string;
}

const ProgressBar = styled.div<ProgressBarProps>`
  background: ${(background: any) => (background ? background : "#6264a7")};
  color: #484848;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  transition: width 0.6s ease;
  width: ${({ width }) => (width ? width : "auto")};
`;

const Wrapper = styled.div`
  display: flex;

  ${mq.smallPhone} {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  ${mq.tabletWide} {
    flex-direction: row;
  }
`;

export {
  Text,
  Title,
  Wrapper,
  Progress,
  CourseImage,
  ProgressBar,
  ButtonWrapper,
  ProgressWrapper,
  TopCourseWrapper,
  ProgressCompleted,
};
