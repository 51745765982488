import styled from "@emotion/styled/macro";

const BundleStackWrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;

  .bundle-child {
    height: auto;
  }

  .bundle-child:nth-last-of-type(3) {
    position: absolute;
    top: -4px;
    left: 24px;
    transform: rotate(6deg);
    margin: 0 auto;
    z-index: 1;
    width: 100%;
  }
  .bundle-child:nth-last-of-type(2) {
    position: absolute;
    top: -2px;
    left: 12px;
    transform: rotate(3deg);
    z-index: 2;
    margin: 0 auto;
    width: 100%;
  }
  .bundle-child:nth-last-of-type(1) {
    position: relative;
    z-index: 3;
    width: 100%;
  }
`;

export { BundleStackWrapper };
