/** @jsxRuntime classic */
/** @jsx jsx */

import styled from "@emotion/styled/macro";

import * as mq from "styles/media-queries";

import BgBlack from "assets/images/common/bg-header-01.png";
import BgImageOne from "assets/images/getStarted/landing-tick.png";

const IntroBanner = styled.div`
  display: flex;
  justify-content: center;
  min-height: 100vh;
  padding: 80px 10px;
  align-items: center;
  // align-items: flex-start;

  background: url(${BgImageOne}) no-repeat calc(50% + 40px) 100%/1120px auto,
    url(${BgBlack}) no-repeat 50% / cover;

  ${mq.hdMinus} {
    align-items: flex-start;
  }
`;

const OliveImg = styled.img`
  ${mq.smallPhone} {
    height: 20px;
    margin-bottom: 0;
  }

  ${mq.tablet} {
    height: 34px;
    margin-bottom: 40px;
  }
`;

const NotFoundHeader = styled.h1`
  font-weight: 700;
  color: #fff;
  line-height: 1.15;

  ${mq.smallPhone} {
    font-size: 16px;
    margin: 15px 0;
  }

  ${mq.tablet} {
    font-size: 46px;
    margin-bottom: 50px;
  }
`;

const TeachLearn = styled.p`
  color: #fff;

  ${mq.smallPhone} {
    font-size: 14px;
    margin-bottom: 20px;
  }

  ${mq.tablet} {
    font-size: 24px;
    margin-bottom: 50px;
  }
`;

const WelcomeImgHolder = styled.div`
  height: 100% !important;
  display: flex;
  align-items: center;

  ${mq.smallPhone} {
    max-width: 400px;
    margin: 20px auto 0;
  }

  ${mq.tablet} {
    max-width: 500px;
  }

  ${mq.tabletWide} {
    max-width: 660px;
    margin: 0;
  }
`;

const Imgwrap = styled.div`
  width: 100%;
  height: 100% !important;

  ${mq.smallPhone} {
    margin: 0 auto;
  }
`;

const TabImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export {
  TabImg,
  Imgwrap,
  OliveImg,
  TeachLearn,
  IntroBanner,
  NotFoundHeader,
  WelcomeImgHolder,
};
