import { useState, useEffect } from "react";

import { Notification } from "utils/notification";

function useImageUpload() {
  const [image, setImage] = useState("");
  const [selectedFile, setSelectedFile] = useState("");

  const [imageUploadEvent, setImageUploadEvent] = useState<any>(null);

  useEffect(() => {
    if (imageUploadEvent) {
      let extension;

      if (imageUploadEvent.target?.files[0]?.name) {
        extension = imageUploadEvent.target.files[0].name
          .split(".")
          .pop()
          .toLowerCase();
      }

      let allowed_types = ["png", "jpg", "jpeg"];

      const imageIsValid = allowed_types.includes(extension);

      if (!imageIsValid) {
        Notification({
          type: "warning",
          title: "Warning",
          message: "Allowed file formates are .png, .jpg and .jpeg!",
        });

        return;
      }

      let files;

      if (imageUploadEvent.dataTransfer) {
        // if event is from a drag event
        files = imageUploadEvent.dataTransfer.files;
      } else if (imageUploadEvent.target) {
        files = imageUploadEvent.target.files;
      }

      /** FileReader object is used to asynchronously read the contents
       * of files using "File" or "Blob" objects
       */
      const reader = new FileReader();

      /** Event triggered each time the reading operation is successfully completed */
      reader.onload = () => {
        // @ts-ignore
        let base64Data = reader?.result?.split(",");

        setImage(reader.result as any);
        setSelectedFile(base64Data[1]);
      };

      /** Starts reading the contents of teh specified "BLob",
       * once finished, the result attribute contains a data: URL
       * representing the file's data.
       */
      reader.readAsDataURL(files[0]);
    }
  }, [imageUploadEvent]);

  return {
    image,
    setImage,
    selectedFile,
    imageUploadEvent,
    setImageUploadEvent,
  };
}

export { useImageUpload };
