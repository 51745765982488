import React, { useState, useMemo } from "react";

// import { localStorageKeys, useLocalStorageState } from "hooks";

interface AddedCoursesContextParams {
	addedCourses: [];
	setAddedCourses: (state: any) => null;
	removeAddedCoursesFromLocalStorage: () => null;
}

const AddedCoursesContext = React.createContext<
	AddedCoursesContextParams | undefined
>(undefined);

AddedCoursesContext.displayName = "AddedCoursesContext";

function AddedCoursesProvider(props: any) {
	// const [addedCourses, setAddedCourses, removeAddedCoursesFromLocalStorage] =
	//   useLocalStorageState({
	//     key: localStorageKeys.addedCourses,
	//     defaultValue: [],
	//   });

	const [addedCourses, setAddedCourses] = useState([]);

	const value = useMemo(() => {
		return {
			addedCourses,
			setAddedCourses,
		};
	}, [addedCourses, setAddedCourses]);

	return <AddedCoursesContext.Provider value={value} {...props} />;
}

function useAddedCoursesContext() {
	const context = React.useContext(AddedCoursesContext);

	if (context === undefined) {
		throw new Error(
			`useAddedCoursesContext must be used within a AddedCoursesProvider`
		);
	}

	return context;
}

export { AddedCoursesProvider, useAddedCoursesContext };
