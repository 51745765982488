import { OnboardingIcon } from "screens/register/register.styles";

const NewsPositionIcon = () => {
  return (
    <OnboardingIcon
      width="16px"
      height="15px"
      viewBox="0 0 16 15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3 3.5C3 3.22386 3.22386 3 3.5 3H10.5C10.7761 3 11 3.22386 11 3.5C11 3.77614 10.7761 4 10.5 4H3.5C3.22386 4 3 3.77614 3 3.5ZM8.5 6C8.22386 6 8 6.22386 8 6.5C8 6.77614 8.22386 7 8.5 7H10.5C10.7761 7 11 6.77614 11 6.5C11 6.22386 10.7761 6 10.5 6H8.5ZM8 9.5C8 9.22386 8.22386 9 8.5 9H10.5C10.7761 9 11 9.22386 11 9.5C11 9.77614 10.7761 10 10.5 10H8.5C8.22386 10 8 9.77614 8 9.5ZM3.5 6C3.22386 6 3 6.22386 3 6.5V9.5C3 9.77614 3.22386 10 3.5 10H6.5C6.77614 10 7 9.77614 7 9.5V6.5C7 6.22386 6.77614 6 6.5 6H3.5ZM4 9V7H6V9H4ZM0 2C0 0.89543 0.895431 0 2 0H12C13.1046 0 14 0.895431 14 2V3C15.1046 3 16 3.89543 16 5V10.5C16 11.8807 14.8807 13 13.5 13H2.5C1.11929 13 0 11.8807 0 10.5V2ZM13 2C13 1.44772 12.5523 1 12 1L2 1C1.44771 1 1 1.44772 1 2L1 10.5C1 11.3284 1.67157 12 2.5 12H13.5C14.3284 12 15 11.3284 15 10.5V5C15 4.44772 14.5523 4 14 4V10.5C14 10.7761 13.7761 11 13.5 11C13.2239 11 13 10.7761 13 10.5V2Z"></path>
    </OnboardingIcon>
  );
};

export default NewsPositionIcon;
