import { OnboardingIcon } from "screens/register/register.styles";

const SuitcaseIcon = () => {
	return (
		<OnboardingIcon
			width="12"
			height="10"
			viewBox="0 0 12 10"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M3.00696 1.5V2H2C0.89543 2 0 2.89543 0 4V7.99945C0 9.10402 0.89543 9.99945 2 9.99945H10C11.1046 9.99945 12 9.10402 12 7.99945V4C12 2.89543 11.1046 2 10 2H9.00696V1.5C9.00696 0.671573 8.33539 0 7.50696 0H4.50696C3.67853 0 3.00696 0.671573 3.00696 1.5ZM4.50696 1H7.50696C7.7831 1 8.00696 1.22386 8.00696 1.5V2H4.00696V1.5C4.00696 1.22386 4.23082 1 4.50696 1ZM2 3H10C10.5523 3 11 3.44772 11 4V5H9V4.5C9 4.22386 8.77614 4 8.5 4C8.22386 4 8 4.22386 8 4.5V5H4V4.50003C4 4.22389 3.77614 4.00003 3.5 4.00003C3.22386 4.00003 3 4.22389 3 4.50003V5H1V4C1 3.44772 1.44772 3 2 3ZM8 6V6.49997C8 6.77611 8.22386 6.99997 8.5 6.99997C8.77614 6.99997 9 6.77611 9 6.49997V6H11V7.99945C11 8.55174 10.5523 8.99945 10 8.99945H2C1.44772 8.99945 1 8.55174 1 7.99945V6H3V6.50345C3 6.77959 3.22386 7.00345 3.5 7.00345C3.77614 7.00345 4 6.77959 4 6.50345V6H8Z"></path>
		</OnboardingIcon>
	);
};

export default SuitcaseIcon;
