import React, { useState, useMemo } from "react";

interface MarketPlaceFilterContextParams {
	filterValue: string | null;
	setFilterValue: (state: string) => null;
	filterBy: string | null;
	setFilterBy: (state: string) => null;
	courseProviderValue: string | null;
	setCourseProviderValue: (state: string) => null;
}

const MarketPlaceFilterContext = React.createContext<
	MarketPlaceFilterContextParams | undefined
>(undefined);

MarketPlaceFilterContext.displayName = "MarketPlaceFilterContext";

function MarketPlaceFilterProvider(props: any) {
	const [filterValue, setFilterValue] = useState(null);
	const [filterBy, setFilterBy] = useState(null);

	const [courseProviderValue, setCourseProviderValue] = useState(null);

	const value = useMemo(() => {
		return {
			filterValue,
			setFilterValue,
			filterBy,
			setFilterBy,
			courseProviderValue,
			setCourseProviderValue,
		};
	}, [filterValue, filterBy, courseProviderValue]);

	return <MarketPlaceFilterContext.Provider value={value} {...props} />;
}

function useMarketPlaceFilterContext() {
	const context = React.useContext(MarketPlaceFilterContext);

	if (context === undefined) {
		throw new Error(
			`useMarketPlaceFilterContext must be used within a MarketPlaceFilterProvider`
		);
	}

	return context;
}

export { MarketPlaceFilterProvider, useMarketPlaceFilterContext };
