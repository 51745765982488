import { OnboardingIcon } from "screens/register/register.styles";

const ExplorePortfolioIcon = () => {
  return (
    <OnboardingIcon
      width="17px"
      height="16px"
      viewBox="0 0 17 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16 1.5L16 7.25716C15.6929 7.00353 15.3578 6.78261 15 6.59971V1.5C15 1.22386 14.7761 1 14.5 1L10 0.999999C9.17157 0.999999 8.5 1.67157 8.5 2.5V7.72506C8.09115 8.15813 7.75187 8.65757 7.5 9.20551V2.5C7.5 1.67157 6.82843 1 6 1H1.5C1.22386 1 1 1.22386 1 1.5V12.5C1 12.7761 1.22386 13 1.5 13H6C6.43214 13 6.82159 12.8173 7.09531 12.5248C7.16355 12.8869 7.26726 13.2364 7.40271 13.5697C7.00274 13.8413 6.5199 14 6 14H1.5C0.671573 14 0 13.3284 0 12.5V1.5C0 0.671573 0.671573 0 1.5 0H6C6.8178 0 7.54389 0.392673 8 0.999759C8.45612 0.392672 9.1822 0 10 0H14.5C15.3284 0 16 0.671573 16 1.5Z"></path>
      <path d="M11.0102 11C11.0568 9.87174 11.2592 8.87686 11.5499 8.15016C11.7169 7.73261 11.9033 7.42826 12.0847 7.23648C12.265 7.04575 12.4055 7 12.4999 7C12.5942 7 12.7347 7.04575 12.9151 7.23648C13.0965 7.42826 13.2828 7.73261 13.4499 8.15016C13.7405 8.87686 13.9429 9.87174 13.9896 11H11.0102Z"></path>
      <path d="M10.6214 7.77877C10.6854 7.6189 10.7547 7.46542 10.8294 7.32024C9.31913 7.92438 8.21268 9.32339 8.02734 11H10.0094C10.0565 9.76642 10.2766 8.64088 10.6214 7.77877Z"></path>
      <path d="M14.3783 7.77877C14.3144 7.6189 14.2451 7.46542 14.1703 7.32024C15.6806 7.92438 16.7871 9.32339 16.9724 11H14.9904C14.9433 9.76642 14.7232 8.64088 14.3783 7.77877Z"></path>
      <path d="M14.9904 12H16.9724C16.7871 13.6766 15.6806 15.0756 14.1703 15.6798C14.2451 15.5346 14.3144 15.3811 14.3783 15.2212C14.7232 14.3591 14.9433 13.2336 14.9904 12Z"></path>
      <path d="M12.9151 15.7635C12.7347 15.9543 12.5942 16 12.4999 16C12.4055 16 12.265 15.9543 12.0847 15.7635C11.9033 15.5717 11.7169 15.2674 11.5499 14.8498C11.2592 14.1231 11.0568 13.1283 11.0102 12H13.9896C13.9429 13.1283 13.7405 14.1231 13.4499 14.8498C13.2828 15.2674 13.0965 15.5717 12.9151 15.7635Z"></path>
      <path d="M8.02734 12C8.21268 13.6766 9.31913 15.0756 10.8294 15.6798C10.7547 15.5346 10.6854 15.3811 10.6214 15.2212C10.2766 14.3591 10.0565 13.2336 10.0094 12H8.02734Z"></path>
    </OnboardingIcon>
  );
};

export default ExplorePortfolioIcon;
