import React, { useMemo } from "react";

import { localStorageKeys, useLocalStorageState } from "hooks";

interface PortfolioDataContextParams {
  portfolioUserData: {
    portfolio: {
      loginType: string;
      member_details: {
        id: number;
        first_name: string;
        last_name: string;
        email: string;
        mobile_number: string;
        country_code: string;
        image_url: string;
        organisation_details: {
          id: number;
          name: string;
          domain: string;
          branding_logo_url: string;
        };
      };
      portfolio_user_roles: {
        Admin: "Administrator";
        Employer: "Employer";
        Learner: "Apprentice";
        Assessor: "Assessor";
        Verifier: "Verifier";
      };
      token: string;
      unseen_notification_count: string;
      _token: string;
    };
    onBoardingProcess: {
      step_one: number;
      step_two: number;
      step_three: number;
      step_four: number;
      step_five: number;
      step_six: number;
    };
    vleToken: string;
  };
  setPortfolioUserData: (state: any) => null;
  removePortfolioUserDataFromLocalStorage: () => null;
}

const PortfolioDataContext = React.createContext<
  PortfolioDataContextParams | undefined
>(undefined);

PortfolioDataContext.displayName = "PortfolioDataContext";

function PortfolioDataProvider(props: any) {
  const [
    portfolioUserData,
    setPortfolioUserData,
    removePortfolioUserDataFromLocalStorage,
  ] = useLocalStorageState({
    key: localStorageKeys.portfolioUserData,
  });

  const value = useMemo(() => {
    return {
      portfolioUserData,
      setPortfolioUserData,
      removePortfolioUserDataFromLocalStorage,
    };
  }, [
    portfolioUserData,
    setPortfolioUserData,
    removePortfolioUserDataFromLocalStorage,
  ]);

  return <PortfolioDataContext.Provider value={value} {...props} />;
}

function usePortfolioDataContext() {
  const context = React.useContext(PortfolioDataContext);

  if (context === undefined) {
    throw new Error(
      `usePortfolioDataContext must be used within a PortfolioDataProvider`
    );
  }

  return {
    ...context,
    isPortfolio: context.portfolioUserData ? true : false,
  };
}

export { PortfolioDataProvider, usePortfolioDataContext };
