// Third party imports
import styled from "@emotion/styled/macro";

const Tr = styled.tr``;

const Th = styled.th`
  border: none;
  font-size: 14px;
  font-weight: normal;
  padding-bottom: 15px;
  text-align: center;
`;

const Td = styled.td`
  padding: 12px 8px;
  border-color: #f0f0f0;
  text-align: center;
`;

const Thead = styled.thead``;

const Tbody = styled.tbody``;

const NameWrapper = styled.div`
  text-align: left;
  display: flex;
  align-items: center;
`;

export { Tr, Th, Td, Tbody, Thead, NameWrapper };
