export const countryWithCode = [
  {
    id: "3",
    code: "AF",
    dial_code: "+93",
    name: "Afghanistan",
  },
  {
    id: "15",
    code: "AX",
    dial_code: "+358",
    name: "Aland Islands",
  },
  {
    id: "6",
    code: "AL",
    dial_code: "+355",
    name: "Albania",
  },
  {
    id: "60",
    code: "DZ",
    dial_code: "+213",
    name: "Algeria",
  },
  {
    id: "11",
    code: "AS",
    dial_code: "+684",
    name: "American Samoa",
  },
  {
    id: "1",
    code: "AD",
    dial_code: "+376",
    name: "Andorra",
  },
  {
    id: "8",
    code: "AO",
    dial_code: "+244",
    name: "Angola",
  },
  {
    id: "5",
    code: "AI",
    dial_code: "+1",
    name: "Anguilla",
  },
  {
    id: "9",
    code: "AQ",
    dial_code: "+672",
    name: "Antarctica",
  },
  {
    id: "4",
    code: "AG",
    dial_code: "+1",
    name: "Antigua and Barbuda",
  },
  {
    id: "10",
    code: "AR",
    dial_code: "+54",
    name: "Argentina",
  },
  {
    id: "7",
    code: "AM",
    dial_code: "+374",
    name: "Armenia",
  },
  {
    id: "14",
    code: "AW",
    dial_code: "+297",
    name: "Aruba",
  },
  {
    id: "13",
    code: "AU",
    dial_code: "+61",
    name: "Australia",
  },
  {
    id: "12",
    code: "AT",
    dial_code: "+43",
    name: "Austria",
  },
  {
    id: "16",
    code: "AZ",
    dial_code: "+994",
    name: "Azerbaijan",
  },
  {
    id: "31",
    code: "BS",
    dial_code: "+1",
    name: "Bahamas",
  },
  {
    id: "23",
    code: "BH",
    dial_code: "+973",
    name: "Bahrain",
  },
  {
    id: "19",
    code: "BD",
    dial_code: "+880",
    name: "Bangladesh",
  },
  {
    id: "18",
    code: "BB",
    dial_code: "+1",
    name: "Barbados",
  },
  {
    id: "34",
    code: "BY",
    dial_code: "+375",
    name: "Belarus",
  },
  {
    id: "20",
    code: "BE",
    dial_code: "+32",
    name: "Belgium",
  },
  {
    id: "35",
    code: "BZ",
    dial_code: "+501",
    name: "Belize",
  },
  {
    id: "25",
    code: "BJ",
    dial_code: "+229",
    name: "Benin",
  },
  {
    id: "27",
    code: "BM",
    dial_code: "+1",
    name: "Bermuda",
  },
  {
    id: "32",
    code: "BT",
    dial_code: "+975",
    name: "Bhutan",
  },
  {
    id: "29",
    code: "BO",
    dial_code: "+591",
    name: "Bolivia, Plurinational State Of",
  },
  {
    id: "17",
    code: "BA",
    dial_code: "+387",
    name: "Bosnia And Herzegovina",
  },
  {
    id: "33",
    code: "BW",
    dial_code: "+267",
    name: "Botswana",
  },
  {
    id: "30",
    code: "BR",
    dial_code: "+55",
    name: "Brazil",
  },
  {
    id: "102",
    code: "IO",
    dial_code: "+246",
    name: "British Indian Ocean Territory",
  },
  {
    id: "231",
    code: "VG",
    dial_code: "+1",
    name: "British Virgin Islands",
  },
  {
    id: "28",
    code: "BN",
    dial_code: "+673",
    name: "Brunei Darussalam",
  },
  {
    id: "22",
    code: "BG",
    dial_code: "+359",
    name: "Bulgaria",
  },
  {
    id: "21",
    code: "BF",
    dial_code: "+226",
    name: "Burkina Faso",
  },
  {
    id: "24",
    code: "BI",
    dial_code: "+257",
    name: "Burundi",
  },
  {
    id: "112",
    code: "KH",
    dial_code: "+855",
    name: "Cambodia",
  },
  {
    id: "45",
    code: "CM",
    dial_code: "+237",
    name: "Cameroon",
  },
  {
    id: "36",
    code: "CA",
    dial_code: "+1",
    name: "Canada",
  },
  {
    id: "50",
    code: "CV",
    dial_code: "+238",
    name: "Cape Verde",
  },
  {
    id: "119",
    code: "KY",
    dial_code: "+1",
    name: "Cayman Islands",
  },
  {
    id: "39",
    code: "CF",
    dial_code: "+236",
    name: "Central African Republic",
  },
  {
    id: "209",
    code: "TD",
    dial_code: "+235",
    name: "Chad",
  },
  {
    id: "44",
    code: "CL",
    dial_code: "+56",
    name: "Chile",
  },
  {
    id: "46",
    code: "CN",
    dial_code: "+86",
    name: "China",
  },
  {
    id: "52",
    code: "CX",
    dial_code: "+61",
    name: "Christmas Island",
  },
  {
    id: "37",
    code: "CC",
    dial_code: "+61",
    name: "Cocos (keeling) Islands",
  },
  {
    id: "47",
    code: "CO",
    dial_code: "+57",
    name: "Colombia",
  },
  {
    id: "114",
    code: "KM",
    dial_code: "+269",
    name: "Comoros",
  },
  {
    id: "40",
    code: "CG",
    dial_code: "+242",
    name: "Congo",
  },
  {
    id: "38",
    code: "CD",
    dial_code: "+243",
    name: "Congo, The Democratic Republic Of The",
  },
  {
    id: "43",
    code: "CK",
    dial_code: "+682",
    name: "Cook Islands",
  },
  {
    id: "48",
    code: "CR",
    dial_code: "+506",
    name: "Costa Rica",
  },
  {
    id: "93",
    code: "HR",
    dial_code: "+385",
    name: "Croatia",
  },
  {
    id: "49",
    code: "CU",
    dial_code: "+53",
    name: "Cuba",
  },
  {
    id: "51",
    code: "CW",
    dial_code: "+599",
    name: "CuraAao",
  },
  {
    id: "42",
    code: "CI",
    dial_code: "+225",
    name: "Cute Divoire",
  },
  {
    id: "53",
    code: "CY",
    dial_code: "+357",
    name: "Cyprus",
  },
  {
    id: "54",
    code: "CZ",
    dial_code: "+420",
    name: "Czech Republic",
  },
  {
    id: "57",
    code: "DK",
    dial_code: "+45",
    name: "Denmark",
  },
  {
    id: "56",
    code: "DJ",
    dial_code: "+253",
    name: "Djibouti",
  },
  {
    id: "58",
    code: "DM",
    dial_code: "+1",
    name: "Dominica",
  },
  {
    id: "59",
    code: "DO",
    dial_code: "+1",
    name: "Dominican Republic",
  },
  {
    id: "61",
    code: "EC",
    dial_code: "+593",
    name: "Ecuador",
  },
  {
    id: "63",
    code: "EG",
    dial_code: "+20",
    name: "Egypt",
  },
  {
    id: "204",
    code: "SV",
    dial_code: "+503",
    name: "El Salvador",
  },
  {
    id: "85",
    code: "GQ",
    dial_code: "+240",
    name: "Equatorial Guinea",
  },
  {
    id: "64",
    code: "ER",
    dial_code: "+291",
    name: "Eritrea",
  },
  {
    id: "62",
    code: "EE",
    dial_code: "+372",
    name: "Estonia",
  },
  {
    id: "66",
    code: "ET",
    dial_code: "+251",
    name: "Ethiopia",
  },
  {
    id: "69",
    code: "FK",
    dial_code: "+500",
    name: "Falkland Islands (malvinas)",
  },
  {
    id: "71",
    code: "FO",
    dial_code: "+298",
    name: "Faroe Islands",
  },
  {
    id: "68",
    code: "FJ",
    dial_code: "+679",
    name: "Fiji",
  },
  {
    id: "67",
    code: "FI",
    dial_code: "+358",
    name: "Finland",
  },
  {
    id: "72",
    code: "FR",
    dial_code: "+33",
    name: "France",
  },
  {
    id: "77",
    code: "GF",
    dial_code: "+594",
    name: "French Guyana",
  },
  {
    id: "170",
    code: "PF",
    dial_code: "+689",
    name: "French Polynesia",
  },
  {
    id: "73",
    code: "GA",
    dial_code: "+241",
    name: "Gabon",
  },
  {
    id: "82",
    code: "GM",
    dial_code: "+220",
    name: "Gambia",
  },
  {
    id: "76",
    code: "GE",
    dial_code: "+995",
    name: "Georgia",
  },
  {
    id: "55",
    code: "DE",
    dial_code: "+49",
    name: "Germany",
  },
  {
    id: "79",
    code: "GH",
    dial_code: "+233",
    name: "Ghana",
  },
  {
    id: "80",
    code: "GI",
    dial_code: "+350",
    name: "Gibraltar",
  },
  {
    id: "86",
    code: "GR",
    dial_code: "+30",
    name: "Greece",
  },
  {
    id: "81",
    code: "GL",
    dial_code: "+299",
    name: "Greenland",
  },
  {
    id: "75",
    code: "GD",
    dial_code: "+1",
    name: "Grenada",
  },
  {
    id: "84",
    code: "GP",
    dial_code: "+590",
    name: "Guadeloupe",
  },
  {
    id: "88",
    code: "GU",
    dial_code: "+1",
    name: "Guam",
  },
  {
    id: "87",
    code: "GT",
    dial_code: "+502",
    name: "Guatemala",
  },
  {
    id: "78",
    code: "GG",
    dial_code: "+44",
    name: "Guernsey",
  },
  {
    id: "83",
    code: "GN",
    dial_code: "+224",
    name: "Guinea",
  },
  {
    id: "89",
    code: "GW",
    dial_code: "+245",
    name: "Guinea-bissau",
  },
  {
    id: "90",
    code: "GY",
    dial_code: "+592",
    name: "Guyana",
  },
  {
    id: "94",
    code: "HT",
    dial_code: "+509",
    name: "Haiti",
  },
  {
    id: "228",
    code: "VA",
    dial_code: "+379",
    name: "   ",
  },
  {
    id: "92",
    code: "HN",
    dial_code: "+504",
    name: "Honduras",
  },
  {
    id: "91",
    code: "HK",
    dial_code: "+852",
    name: "Hong Kong",
  },
  {
    id: "95",
    code: "HU",
    dial_code: "+36",
    name: "Hungary",
  },
  {
    id: "100",
    code: "IS",
    dial_code: "+354",
    name: "Iceland",
  },
  {
    id: "101",
    code: "IN",
    dial_code: "+91",
    name: "India",
  },
  {
    id: "96",
    code: "ID",
    dial_code: "+62",
    name: "Indonesia",
  },
  {
    id: "104",
    code: "IR",
    dial_code: "+98",
    name: "Iran, Islamic Republic Of",
  },
  {
    id: "103",
    code: "IQ",
    dial_code: "+964",
    name: "Iraq",
  },
  {
    id: "97",
    code: "IE",
    dial_code: "+353",
    name: "Ireland",
  },
  {
    id: "99",
    code: "IM",
    dial_code: "+44",
    name: "Isle Of Man",
  },
  {
    id: "98",
    code: "IL",
    dial_code: "+972",
    name: "Israel",
  },
  {
    id: "105",
    code: "IT",
    dial_code: "+39",
    name: "Italy",
  },
  {
    id: "107",
    code: "JM",
    dial_code: "+1",
    name: "Jamaica",
  },
  {
    id: "109",
    code: "JP",
    dial_code: "+81",
    name: "Japan",
  },
  {
    id: "106",
    code: "JE",
    dial_code: "+44",
    name: "Jersey",
  },
  {
    id: "108",
    code: "JO",
    dial_code: "+962",
    name: "Jordan",
  },
  {
    id: "120",
    code: "KZ",
    dial_code: "+7",
    name: "Kazakhstan",
  },
  {
    id: "110",
    code: "KE",
    dial_code: "+254",
    name: "Kenya",
  },
  {
    id: "113",
    code: "KI",
    dial_code: "+686",
    name: "Kiribati",
  },
  {
    id: "237",
    code: "XK",
    dial_code: "+383",
    name: "Kosovo",
  },
  {
    id: "118",
    code: "KW",
    dial_code: "+965",
    name: "Kuwait",
  },
  {
    id: "111",
    code: "KG",
    dial_code: "+996",
    name: "Kyrgyzstan",
  },
  {
    id: "121",
    code: "LA",
    dial_code: "+856",
    name: "Lao People's Democratic Republic",
  },
  {
    id: "130",
    code: "LV",
    dial_code: "+371",
    name: "Latvia",
  },
  {
    id: "122",
    code: "LB",
    dial_code: "+961",
    name: "Lebanon",
  },
  {
    id: "127",
    code: "LS",
    dial_code: "+266",
    name: "Lesotho",
  },
  {
    id: "126",
    code: "LR",
    dial_code: "+231",
    name: "Liberia",
  },
  {
    id: "131",
    code: "LY",
    dial_code: "+218",
    name: "Libya",
  },
  {
    id: "124",
    code: "LI",
    dial_code: "+423",
    name: "Liechtenstein",
  },
  {
    id: "128",
    code: "LT",
    dial_code: "+370",
    name: "Lithuania",
  },
  {
    id: "129",
    code: "LU",
    dial_code: "+352",
    name: "Luxembourg",
  },
  {
    id: "143",
    code: "MO",
    dial_code: "+853",
    name: "Macau",
  },
  {
    id: "139",
    code: "MK",
    dial_code: "+389",
    name: "Macedonia (FYROM)",
  },
  {
    id: "137",
    code: "MG",
    dial_code: "+261",
    name: "Madagascar",
  },
  {
    id: "151",
    code: "MW",
    dial_code: "+265",
    name: "Malawi",
  },
  {
    id: "153",
    code: "MY",
    dial_code: "+60",
    name: "Malaysia",
  },
  {
    id: "150",
    code: "MV",
    dial_code: "+960",
    name: "Maldives",
  },
  {
    id: "140",
    code: "ML",
    dial_code: "+223",
    name: "Mali",
  },
  {
    id: "148",
    code: "MT",
    dial_code: "+356",
    name: "Malta",
  },
  {
    id: "138",
    code: "MH",
    dial_code: "+692",
    name: "Marshall Islands",
  },
  {
    id: "145",
    code: "MQ",
    dial_code: "+596",
    name: "Martinique",
  },
  {
    id: "146",
    code: "MR",
    dial_code: "+222",
    name: "Mauritania",
  },
  {
    id: "149",
    code: "MU",
    dial_code: "+230",
    name: "Mauritius",
  },
  {
    id: "239",
    code: "YT",
    dial_code: "+262",
    name: "Mayotte",
  },
  {
    id: "152",
    code: "MX",
    dial_code: "+52",
    name: "Mexico",
  },
  {
    id: "70",
    code: "FM",
    dial_code: "+691",
    name: "Micronesia, Federated States Of",
  },
  {
    id: "134",
    code: "MD",
    dial_code: "+373",
    name: "Moldova, Republic Of",
  },
  {
    id: "133",
    code: "MC",
    dial_code: "+377",
    name: "Monaco",
  },
  {
    id: "142",
    code: "MN",
    dial_code: "+976",
    name: "Mongolia",
  },
  {
    id: "135",
    code: "ME",
    dial_code: "+382",
    name: "Montenegro",
  },
  {
    id: "147",
    code: "MS",
    dial_code: "+1",
    name: "Montserrat",
  },
  {
    id: "132",
    code: "MA",
    dial_code: "+212",
    name: "Morocco",
  },
  {
    id: "154",
    code: "MZ",
    dial_code: "+258",
    name: "Mozambique",
  },
  {
    id: "141",
    code: "MM",
    dial_code: "+95",
    name: "Myanmar",
  },
  {
    id: "155",
    code: "NA",
    dial_code: "+264",
    name: "Namibia",
  },
  {
    id: "164",
    code: "NR",
    dial_code: "+674",
    name: "Nauru",
  },
  {
    id: "163",
    code: "NP",
    dial_code: "+977",
    name: "Nepal",
  },
  {
    id: "161",
    code: "NL",
    dial_code: "+31",
    name: "Netherlands",
  },
  {
    id: "156",
    code: "NC",
    dial_code: "+687",
    name: "New Caledonia",
  },
  {
    id: "166",
    code: "NZ",
    dial_code: "+64",
    name: "New Zealand",
  },
  {
    id: "160",
    code: "NI",
    dial_code: "+505",
    name: "Nicaragua",
  },
  {
    id: "157",
    code: "NE",
    dial_code: "+227",
    name: "Niger",
  },
  {
    id: "159",
    code: "NG",
    dial_code: "+234",
    name: "Nigeria",
  },
  {
    id: "165",
    code: "NU",
    dial_code: "+683",
    name: "Niue",
  },
  {
    id: "158",
    code: "NF",
    dial_code: "+672",
    name: "Norfolk Islands",
  },
  {
    id: "116",
    code: "KP",
    dial_code: "+850",
    name: "North Korea",
  },
  {
    id: "144",
    code: "MP",
    dial_code: "+1",
    name: "Northern Mariana Islands",
  },
  {
    id: "162",
    code: "NO",
    dial_code: "+47",
    name: "Norway",
  },
  {
    id: "167",
    code: "OM",
    dial_code: "+968",
    name: "Oman",
  },
  {
    id: "173",
    code: "PK",
    dial_code: "+92",
    name: "Pakistan",
  },
  {
    id: "180",
    code: "PW",
    dial_code: "+680",
    name: "Palau",
  },
  {
    id: "178",
    code: "PS",
    dial_code: "+970",
    name: "Palestine",
  },
  {
    id: "168",
    code: "PA",
    dial_code: "+507",
    name: "Panama",
  },
  {
    id: "171",
    code: "PG",
    dial_code: "+675",
    name: "Papua New Guinea",
  },
  {
    id: "181",
    code: "PY",
    dial_code: "+595",
    name: "Paraguay",
  },
  {
    id: "169",
    code: "PE",
    dial_code: "+51",
    name: "Peru",
  },
  {
    id: "172",
    code: "PH",
    dial_code: "+63",
    name: "Philippines",
  },
  {
    id: "176",
    code: "PN",
    dial_code: "+870",
    name: "Pitcairn Islands",
  },
  {
    id: "174",
    code: "PL",
    dial_code: "+48",
    name: "Poland",
  },
  {
    id: "179",
    code: "PT",
    dial_code: "+351",
    name: "Portugal",
  },
  {
    id: "177",
    code: "PR",
    dial_code: "+1",
    name: "Puerto Rico",
  },
  {
    id: "182",
    code: "QA",
    dial_code: "+974",
    name: "Qatar",
  },
  {
    id: "183",
    code: "RE",
    dial_code: "+262",
    name: "Reunion",
  },
  {
    id: "184",
    code: "RO",
    dial_code: "+40",
    name: "Romania",
  },
  {
    id: "186",
    code: "RU",
    dial_code: "+7",
    name: "Russian Federation",
  },
  {
    id: "187",
    code: "RW",
    dial_code: "+250",
    name: "Rwanda",
  },
  {
    id: "26",
    code: "BL",
    dial_code: "+590",
    name: "Saint BarthElemy",
  },
  {
    id: "194",
    code: "SH",
    dial_code: "+290",
    name: "Saint Helena, Ascension And Tristan Da Cunha",
  },
  {
    id: "115",
    code: "KN",
    dial_code: "+1",
    name: "Saint Kitts and Nevis",
  },
  {
    id: "123",
    code: "LC",
    dial_code: "+1",
    name: "Saint Lucia",
  },
  {
    id: "136",
    code: "MF",
    dial_code: "+590",
    name: "Saint Martin",
  },
  {
    id: "175",
    code: "PM",
    dial_code: "+508",
    name: "Saint Pierre And Miquelon",
  },
  {
    id: "229",
    code: "VC",
    dial_code: "+1",
    name: "Saint Vincent & The Grenadines",
  },
  {
    id: "236",
    code: "WS",
    dial_code: "+685",
    name: "Samoa",
  },
  {
    id: "198",
    code: "SM",
    dial_code: "+378",
    name: "San Marino",
  },
  {
    id: "203",
    code: "ST",
    dial_code: "+239",
    name: "Sao Tome And Principe",
  },
  {
    id: "188",
    code: "SA",
    dial_code: "+966",
    name: "Saudi Arabia",
  },
  {
    id: "199",
    code: "SN",
    dial_code: "+221",
    name: "Senegal",
  },
  {
    id: "185",
    code: "RS",
    dial_code: "+381",
    name: "Serbia",
  },
  {
    id: "190",
    code: "SC",
    dial_code: "+248",
    name: "Seychelles",
  },
  {
    id: "197",
    code: "SL",
    dial_code: "+232",
    name: "Sierra Leone",
  },
  {
    id: "193",
    code: "SG",
    dial_code: "+65",
    name: "Singapore",
  },
  {
    id: "205",
    code: "SX",
    dial_code: "+1",
    name: "Sint Maarten",
  },
  {
    id: "196",
    code: "SK",
    dial_code: "+421",
    name: "Slovakia",
  },
  {
    id: "195",
    code: "SI",
    dial_code: "+386",
    name: "Slovenia",
  },
  {
    id: "189",
    code: "SB",
    dial_code: "+677",
    name: "Solomon Islands",
  },
  {
    id: "200",
    code: "SO",
    dial_code: "+252",
    name: "Somalia",
  },
  {
    id: "240",
    code: "ZA",
    dial_code: "+27",
    name: "South Africa",
  },
  {
    id: "117",
    code: "KR",
    dial_code: "+82",
    name: "South Korea",
  },
  {
    id: "202",
    code: "SS",
    dial_code: "+211",
    name: "South Sudan",
  },
  {
    id: "65",
    code: "ES",
    dial_code: "+34",
    name: "Spain",
  },
  {
    id: "125",
    code: "LK",
    dial_code: "+94",
    name: "Sri Lanka",
  },
  {
    id: "191",
    code: "SD",
    dial_code: "+249",
    name: "Sudan",
  },
  {
    id: "201",
    code: "SR",
    dial_code: "+597",
    name: "Suriname",
  },
  {
    id: "207",
    code: "SZ",
    dial_code: "+268",
    name: "Swaziland",
  },
  {
    id: "192",
    code: "SE",
    dial_code: "+46",
    name: "Sweden",
  },
  {
    id: "41",
    code: "CH",
    dial_code: "+41",
    name: "Switzerland",
  },
  {
    id: "206",
    code: "SY",
    dial_code: "+963",
    name: "Syrian Arab Republic",
  },
  {
    id: "221",
    code: "TW",
    dial_code: "+886",
    name: "Taiwan",
  },
  {
    id: "212",
    code: "TJ",
    dial_code: "+992",
    name: "Tajikistan",
  },
  {
    id: "222",
    code: "TZ",
    dial_code: "+255",
    name: "Tanzania, United Republic Of",
  },
  {
    id: "211",
    code: "TH",
    dial_code: "+66",
    name: "Thailand",
  },
  {
    id: "214",
    code: "TL",
    dial_code: "+670",
    name: "Timor-leste",
  },
  {
    id: "210",
    code: "TG",
    dial_code: "+228",
    name: "Togo",
  },
  {
    id: "213",
    code: "TK",
    dial_code: "+690",
    name: "Tokelau",
  },
  {
    id: "217",
    code: "TO",
    dial_code: "+676",
    name: "Tonga",
  },
  {
    id: "219",
    code: "TT",
    dial_code: "+1",
    name: "Trinidad & Tobago",
  },
  {
    id: "216",
    code: "TN",
    dial_code: "+216",
    name: "Tunisia",
  },
  {
    id: "218",
    code: "TR",
    dial_code: "+90",
    name: "Turkey",
  },
  {
    id: "215",
    code: "TM",
    dial_code: "+993",
    name: "Turkmenistan",
  },
  {
    id: "208",
    code: "TC",
    dial_code: "+1",
    name: "Turks and Caicos Islands",
  },
  {
    id: "220",
    code: "TV",
    dial_code: "+688",
    name: "Tuvalu",
  },
  {
    id: "224",
    code: "UG",
    dial_code: "+256",
    name: "Uganda",
  },
  {
    id: "223",
    code: "UA",
    dial_code: "+380",
    name: "Ukraine",
  },
  {
    id: "2",
    code: "AE",
    dial_code: "+971",
    name: "United Arab Emirates (UAE)",
  },
  {
    id: "74",
    code: "GB",
    dial_code: "+44",
    name: "United Kingdom",
  },
  {
    id: "225",
    code: "US",
    dial_code: "+1",
    name: "United States (USA)",
  },
  {
    id: "226",
    code: "UY",
    dial_code: "+598",
    name: "Uruguay",
  },
  {
    id: "232",
    code: "VI",
    dial_code: "+1",
    name: "US Virgin Islands",
  },
  {
    id: "227",
    code: "UZ",
    dial_code: "+998",
    name: "Uzbekistan",
  },
  {
    id: "234",
    code: "VU",
    dial_code: "+678",
    name: "Vanuatu",
  },
  {
    id: "230",
    code: "VE",
    dial_code: "+58",
    name: "Venezuela, Bolivarian Republic Of",
  },
  {
    id: "233",
    code: "VN",
    dial_code: "+84",
    name: "Vietnam",
  },
  {
    id: "235",
    code: "WF",
    dial_code: "+681",
    name: "Wallis And Futuna",
  },
  {
    id: "238",
    code: "YE",
    dial_code: "+967",
    name: "Yemen",
  },
  {
    id: "241",
    code: "ZM",
    dial_code: "+260",
    name: "Zambia",
  },
  {
    id: "242",
    code: "ZW",
    dial_code: "+263",
    name: "Zimbabwe",
  },
];
