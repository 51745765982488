import { useState, useEffect } from "react";

import { client } from "utils/api-client";

import { useQuery } from "react-query";

import { Notification } from "utils/notification";

import { useVleTokenContext } from "context";

function useGetUserAnalytics() {
  const [totalActiveMembers, setTotalActiveMembers] = useState<any | []>([]);
  const [totalActiveMembersCount, setTotalActiveMembersCount] = useState<
    any | string | number
  >(0);

  const { vleToken } = useVleTokenContext();

  const getUserAnalytics = () => {
    return client({
      method: "get",
      endpoint: "api/akademy/user-analytics",
      optional: {
        token: vleToken,
      },
    });
  };

  const { data, error, isSuccess, isLoading, isError } = useQuery(
    "userAnalytics",
    getUserAnalytics,
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (isSuccess) {
      const userAnalytics = data?.data?.data;

      setTotalActiveMembers(userAnalytics.data);
      setTotalActiveMembersCount(userAnalytics.total);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (isError) {
      console.log({ error });

      Notification({
        message:
          "Unable to get the user analytics data, please try again later",
        type: "warning",
        title: "Warning",
      });
    }
  }, [error, isError]);

  return {
    totalActiveMembers,
    totalActiveMembersCount,
    isLoadingUserAnalytics: isLoading,
  };
}

export { useGetUserAnalytics };
