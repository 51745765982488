/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import styled from "@emotion/styled/macro";

import ArrowRight from "assets/images/common/step-arrow-right.png";

import { TickCircleIcon } from "components/svg";

const OnboardingAccountSteps = styled.ul`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	list-style: none;
	margin: 0 0 35px;
	padding: 0;
`;

const OnboardingAccountStep = styled.li`
	display: block;
	text-align: center;
	position: relative;
	margin: 5px 20px;

	+ ::before {
		content: "";
		float: left;
		width: 81px;
		height: 2px;
		background: #e4e6ea;
		// background-image: url(${ArrowRight});
		// background-repeat: no-repeat;
		margin-left: 20px;
		position: absolute;
		left: -100%;
		top: 10px;
	}
`;

const StepText = styled.span<RegisterStepsStyleProps>`
	font-weight: ${({ isActive }) => (isActive ? "600" : "")};
	color: ${({ isActive, isCompleted }) =>
		isActive ? "#13171F" : isCompleted ? "#13171F" : "#13171F"};
	display: inline-block;
	vertical-align: middle;
	flex: 1;
	margin-left: 10px;
	margin-right: 15px;
	font-size: 10px;
	line-height: 1.29;
	text-align: left;
	cursor: pointer;
`;

const CircleStep = styled.div`
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 23px;
	height: 23px;
	font-size: 11px;
	color: #13171f;
	border: 2px solid #a1a4c9;
	border-radius: 50%;
	svg {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`;

interface RegisterStepsProps {
	active: "step-1" | "step-2" | "step-3" | "none";
	completed: {
		"step-1": boolean;
		"step-2": boolean;
		"step-3": boolean;
	};
}

interface RegisterStepsStyleProps {
	isActive: boolean;
	isCompleted?: boolean;
}

const RegisterStepsSso = ({ active, completed }: RegisterStepsProps) => {
	const stepOneComplete = completed["step-1"] === true;
	// const stepTwoComplete = completed["step-2"] === true;
	const stepThreeComplete = completed["step-3"] === true;

	return (
		<OnboardingAccountSteps>
			<OnboardingAccountStep>
				{stepOneComplete ? (
					<CircleStep
						css={{
							border: "transparent",
						}}
					>
						<TickCircleIcon color="#454B93" />
					</CircleStep>
				) : (
					<CircleStep>1</CircleStep>
				)}
				<StepText isActive={active === "step-1"} isCompleted={stepOneComplete}>
					Create Account
				</StepText>
			</OnboardingAccountStep>

			<OnboardingAccountStep>
				{stepThreeComplete ? (
					<CircleStep
						css={{
							border: "transparent",
						}}
					>
						<TickCircleIcon color="green" />
					</CircleStep>
				) : (
					<CircleStep>2</CircleStep>
				)}

				<StepText
					isActive={active === "step-3"}
					isCompleted={stepThreeComplete}
				>
					Add Member
				</StepText>
			</OnboardingAccountStep>
		</OnboardingAccountSteps>
	);
};

export default RegisterStepsSso;
