import { Store } from "react-notifications-component";

interface NotficationArgs {
	message?: string | React.ReactNode | React.FunctionComponent | undefined;
	type?: "success" | "danger" | "info" | "default" | "warning" | undefined;
	title?: string | React.ReactNode | React.FunctionComponent | undefined;
	container?: any;
}

export interface DismissOptions {
	duration?: number | undefined;
	onScreen?: boolean | undefined;
	pauseOnHover?: boolean | undefined;
	waitForAnimation?: boolean | undefined;
	click?: boolean | undefined;
	touch?: boolean | undefined;
	showIcon?: boolean | undefined;
}

export interface ReactNotificationOptions {
	title?: string | React.ReactNode | React.FunctionComponent | undefined;
	message?: string | React.ReactNode | React.FunctionComponent | undefined;
	type?: "success" | "danger" | "info" | "default" | "warning" | undefined;
	insert?: "top" | "bottom" | undefined;
	dismiss?: DismissOptions | undefined;
	container:
		| "top-full"
		| "top-left"
		| "top-right"
		| "top-center"
		| "center"
		| "bottom-full"
		| "bottom-left"
		| "bottom-right"
		| "bottom-center";
	animationIn?: string[] | undefined;
	animationOut?: string[] | undefined;
}

let notification = {
	title: "Success",
	message: "Task successfull",
	type: "success",
	insert: "top",
	dismiss: {
		duration: 5000,
		pauseOnHover: true,
	},
	container: "top-right",
	animationIn: ["animate__animated animate__fadeIn"], // `animate.css v4` classes
	animationOut: ["animate__animated animate__fadeOut"], // `animate.css v4` classes
} as ReactNotificationOptions;

export const Notification = ({
	message,
	type = "default",
	title,
	container = "top-right",
}: NotficationArgs) => {
	Store.addNotification({
		...notification,
		type: type,
		title: title,
		message: message,
		insert: "top",
		container: container,
		animationIn: ["animate__animated", "animate__fadeIn"],
		animationOut: ["animate__animated", "animate__fadeOut"],
		dismiss: {
			duration: 5000,
			onScreen: true,
		},
	});
};
