import { useEffect, useState } from "react";

// Hook imports
import { useGetOrgData, useIsMobileView, usePreviousValue } from "hooks";
import { useGetUserPayments } from "screens/membershipPlans/useGetUserPayments";

// Context imports
import {
	useLegacyContext,
	useUserRoleContext,
	useMykademyDataContext,
	usePortfolioDataContext,
} from "context";

// Data imports
import { useProfileData } from "./useProfileData";

import { profileWithGetStarted, profileWithoutGetStarted } from "./helpData";

import { useGetAdminToolsData } from "./useGetAdminToolsData";

import {
	legacySettingsItems,
	msLegacySettingsItems,
	settingsWithPendingList,
	msSettingsWithPendingList,
	settingsWithoutPendingList,
	msSettingsWithoutPendingList,
	tutorSettingsWithPendingList,
} from "./settingsData";

interface SubMenusParams {
	title: string;
	icon: any;
	route?: string;
}

interface MenuItemDataProps {
	title: string;
	icon: any;
	route?: string;

	submenus?: SubMenusParams[];
}

interface MenuItemsDataProps extends Array<MenuItemDataProps> {}

const initialMenuItems = [
	{
		title: "AI Content Pro",
		icon: "LearnerIcon",
		route: "/aiContentPro",
	},
	{
		title: "Admin Tools",
		icon: "AdminToolsIcon",
	},
	{
		title: "Settings",
		icon: "LearnerIcon",
	},
	{
		title: "Help",
		icon: "LearnerIcon",
	},
	{
		title: "Profile",
		icon: "ProfileIcon",
	},
];

function useMenuItemsData() {
	const [menuItemsData, setMenuItemsData] =
		useState<MenuItemsDataProps>(initialMenuItems);

	const { profileWithSignout, profileWithoutSignout } = useProfileData();

	// Api hooks
	const { hasPendingBill } = useGetUserPayments();

	const {
		showLoginPage,
		showSsoButton,
		showContentPro,
		showGetStartedTab,
		showVideoClassRoom,
		showLearningPathway,
		videoClassroomLabelData,
		showPendingUserInvitation,
	} = useGetOrgData();

	// Custom hooks
	const { isMobileView } = useIsMobileView();

	const prevShowLoginPageValue = usePreviousValue(showLoginPage);
	const prevShowContentProValue = usePreviousValue(showContentPro);
	const prevShowGetStartedTabValue = usePreviousValue(showGetStartedTab);
	const prevShowVideoClassRoomValue = usePreviousValue(showVideoClassRoom);
	const prevShowPendingUserInvitation = usePreviousValue(
		showPendingUserInvitation
	);
	const prevShowLearningPathwayValue = usePreviousValue(showLearningPathway);

	// Context hooks
	const { isPortfolio } = usePortfolioDataContext();
	const { isMykademyLegacy } = useLegacyContext();
	const { isMykademy, mykademyUserData } = useMykademyDataContext();

	const {
		isAsessor,
		isMykademyAdmin,
		isMykademyTutor,
		isMykademyMentor,
		isPortfolioAdmin,
		isMykademyLearner,
		isMykademyAdminOnly,
		isMykademyBranchAdmin,
		isMykademyContentEditor,
	} = useUserRoleContext();

	const {
		reportCe,
		vleAdmin,
		vleMentor,
		reportAdmin,
		pathwayAdmin,
		vleBranchAdmin,
		vleTutorLegacy,
		vleContentEditor,
	} = useGetAdminToolsData({
		videoClassroomLabelData: videoClassroomLabelData?.labels,
	});

	// Check to show or hide sign out button and check for profile name i.e Mykademy or Myportfolio
	useEffect(() => {
		if (mykademyUserData) {
			const updatedSubmenus = showLoginPage
				? profileWithSignout
				: profileWithoutSignout;

			setMenuItemsData((prevData: any) =>
				prevData?.map((item: any) =>
					item.title === "Profile"
						? { ...item, submenus: updatedSubmenus }
						: item
				)
			);
		}
	}, [
		isMykademy,
		isPortfolio,
		showLoginPage,
		isMykademyLegacy,
		mykademyUserData,
		profileWithSignout,
		profileWithoutSignout,
		prevShowLoginPageValue,
	]);

	// Check to show or hide settings
	useEffect(() => {
		if (
			isMobileView ||
			isMykademyMentor ||
			isMykademyLearner ||
			isMykademyBranchAdmin ||
			isMykademyContentEditor ||
			(showPendingUserInvitation === false && isMykademyTutor)
		) {
			setMenuItemsData((prevData) =>
				prevData?.filter((item) => item.title !== "Settings")
			);

			return;
		}

		if ((isMykademyAdminOnly || isMykademyTutor) && !isMobileView) {
			if (mykademyUserData) {
				const { tenant_id, isLegacy } = mykademyUserData.userData;

				const getSubmenus = () => {
					if (isMykademyAdminOnly) {
						if (tenant_id) {
							if (isLegacy) {
								return msLegacySettingsItems;
							} else {
								if (showPendingUserInvitation) {
									return msSettingsWithPendingList;
								} else {
									return msSettingsWithoutPendingList;
								}
							}
						} else {
							if (isLegacy) {
								return legacySettingsItems;
							} else {
								if (showPendingUserInvitation) {
									return settingsWithPendingList;
								} else {
									return settingsWithoutPendingList;
								}
							}
						}
					} else if (isMykademyTutor) {
						if (showPendingUserInvitation) {
							return tutorSettingsWithPendingList;
						}
					}
				};

				setMenuItemsData((prevData) =>
					prevData?.map((item) =>
						item.title === "Settings"
							? { ...item, submenus: getSubmenus() }
							: item
					)
				);
			}
		}
	}, [
		isMobileView,
		isMykademyTutor,
		mykademyUserData,
		isMykademyMentor,
		isMykademyLearner,
		isMykademyAdminOnly,
		isMykademyBranchAdmin,
		isMykademyContentEditor,
		showPendingUserInvitation,
		prevShowPendingUserInvitation,
	]);

	// Check to show or Configure Microsoft SSO
	useEffect(() => {
		if (isMykademyAdminOnly) {
			let menuItemsDataCopy = JSON.parse(JSON.stringify(menuItemsData));

			const menuItemsIncludesSsoLink = menuItemsDataCopy.findIndex(
				//@ts-ignore
				(item) => item.title === "Configure Microsoft SSO"
			);

			if (mykademyUserData && !mykademyUserData.userData.tenant_id) {
				const ssoLink: any = {
					title: "Configure Microsoft SSO",
				};

				if (menuItemsIncludesSsoLink === -1 && showSsoButton) {
					menuItemsDataCopy.unshift(ssoLink);

					setMenuItemsData(menuItemsDataCopy);
				}
			} else if (mykademyUserData && mykademyUserData.userData.tenant_id) {
				if (menuItemsIncludesSsoLink !== -1) {
					menuItemsDataCopy.splice(menuItemsIncludesSsoLink, 1);

					setMenuItemsData(menuItemsDataCopy);
				}
			}
		}
	}, [menuItemsData, isMykademyAdminOnly, mykademyUserData, showSsoButton]);

	// Check whether to show ai content pro
	useEffect(() => {
		if (!isMykademyAdminOnly || isMobileView || !showContentPro) {
			setMenuItemsData((prevData: any) =>
				prevData?.filter((item: any) => item.title !== "AI Content Pro")
			);
		}
	}, [
		isMobileView,
		showContentPro,
		isMykademyAdminOnly,
		prevShowContentProValue,
	]);

	// Check whether to show admin tools
	useEffect(() => {
		const toolsNameAccordingToRole = isMykademyBranchAdmin
			? "Branch Admin Tools"
			: isMykademyContentEditor
			? "Content Editor Tools"
			: isMykademyMentor
			? "Mentor Tools"
			: isMykademyTutor
			? "Tutor Tools"
			: "Admin Tools";

		setMenuItemsData((prevData) =>
			prevData?.map(({ title, ...rest }) => ({
				...rest,
				title: title === "Admin Tools" ? toolsNameAccordingToRole : title,
			}))
		);

		if (isMobileView || !isMykademyAdmin) {
			setMenuItemsData((prevData) =>
				prevData?.filter((item) => item.title !== toolsNameAccordingToRole)
			);

			return;
		}

		if ((isMykademyAdmin || isPortfolioAdmin || isAsessor) && !isMobileView) {
			// if (isMykademyAdminOnly && !isMykademyLegacy) {
			// 	adminTools.submenus.push({
			// 		title: "Plan",
			// 		icon: "PlanIcon",
			// 		route: "/membershipPlans",
			// 	});
			// }

			if (!hasPendingBill) {
				const getSubmenus = () => {
					function getCurrentCoursesSubmenus() {
						if (isMykademyAdminOnly) {
							if (showVideoClassRoom) {
								return vleAdmin;
							} else {
								return vleAdmin.filter(
									(item: any) => item.title !== "Video Classroom"
								);
							}
						} else if (isMykademyBranchAdmin) {
							if (showVideoClassRoom) {
								return vleBranchAdmin;
							} else {
								return vleBranchAdmin.filter(
									(item: any) => item.title !== "Video Classroom"
								);
							}
						} else if (isMykademyMentor) {
							if (showVideoClassRoom) {
								return vleMentor;
							} else {
								return vleMentor.filter(
									(item: any) => item.title !== "Video Classroom"
								);
							}
						} else if (isMykademyContentEditor) {
							return vleContentEditor;
						} else {
							if (showVideoClassRoom) {
								return vleTutorLegacy;
							} else {
								return vleTutorLegacy.filter(
									(item: any) => item.title !== "Video Classroom"
								);
							}
						}
					}

					function getCurrentReportsSubmenus() {
						if (
							(isMykademyAdminOnly && !isMykademyLegacy) ||
							isMykademyBranchAdmin ||
							isMykademyMentor
						) {
							return reportAdmin;
						} else if (isMykademyContentEditor) {
							return reportCe;
						} else {
							return reportAdmin;
						}
					}

					const adminToolsArr = [
						{
							title: "Courses",
							icon: "CoursesIcon",
							route: "/mykademyAdmin",

							submenus: getCurrentCoursesSubmenus(),
						},
						{
							title: "Reports",
							icon: "SuitcaseIcon",
							route: "/mykademyAdmin",

							submenus: getCurrentReportsSubmenus(),
						},
						{
							title: "Learning Pathway",
							icon: "PlanIcon",
							route: "/mykademyAdmin",

							submenus: pathwayAdmin,
						},
					];

					if (isMykademyAdminOnly && showLearningPathway) {
						return adminToolsArr;
					} else {
						return adminToolsArr.filter(
							(item) => item.title !== "Learning Pathway"
						);
					}
				};

				setMenuItemsData((prevData) =>
					prevData?.map((item) =>
						item.title === toolsNameAccordingToRole
							? { ...item, submenus: getSubmenus() }
							: item
					)
				);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		isAsessor,
		isMykademy,
		isMobileView,
		hasPendingBill,
		isMykademyAdmin,
		isMykademyTutor,
		isMykademyLegacy,
		isPortfolioAdmin,
		isMykademyMentor,
		showVideoClassRoom,
		isMykademyAdminOnly,
		isMykademyBranchAdmin,
		isMykademyContentEditor,
		prevShowVideoClassRoomValue,
		showLearningPathway,
		prevShowLearningPathwayValue,
	]);

	useEffect(() => {
		const { isLegacy } = mykademyUserData.userData;

		const updatedSubmenus =
			showGetStartedTab || isLegacy
				? profileWithGetStarted
				: profileWithoutGetStarted;

		setMenuItemsData((prevData) =>
			prevData?.map((item) =>
				item.title === "Help" ? { ...item, submenus: updatedSubmenus } : item
			)
		);
	}, [
		showGetStartedTab,
		mykademyUserData.userData,
		prevShowGetStartedTabValue,
	]);

	return { menuItemsData };
}

export { useMenuItemsData };
