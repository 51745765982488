import styled from "@emotion/styled/macro";

import * as mq from "styles/media-queries";

export const DatePickerInputWrapper = styled.div`
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	width: 220px;
	margin-left: 20px;

	input {
		width: 100%;
		border: transparent;
		border-radius: 4px;
		background-color: #fdfdfd;
		color: #000;
		padding: 0.375rem 0.75rem;

		:focus {
			outline: none;
		}
	}

	img {
		position: absolute;
		right: 10px;
		top: 8px;
	}

	${mq.smallPhone} {
		margin-bottom: 12;
	}

	${mq.tablet} {
		margin-top: 0;
	}
`;
