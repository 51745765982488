import { Fragment } from "react";

import {
  CheckboxInput,
  CheckboxLabel,
  CheckboxContent,
} from "styles/checkbox.styles";

interface CommonCheckBoxProps {
  id?: string;
  selectedUserList: any;
  setSelectedUserList: any;
}

const CommonCheckbox = ({
  id,
  selectedUserList,
  setSelectedUserList,
}: CommonCheckBoxProps) => {
  const handleOnChange = (e: any) => {
    if (e.target.checked) {
      setSelectedUserList([...selectedUserList, id]);
    } else {
      setSelectedUserList(
        selectedUserList.filter((userId: string) => userId !== id)
      );
    }
  };

  const isChecked = () => {
    if (selectedUserList.includes(id)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Fragment>
      <CheckboxContent>
        <CheckboxInput
          id={id}
          type="checkbox"
          onChange={handleOnChange}
          checked={isChecked()}
        />
        <CheckboxLabel htmlFor={id}></CheckboxLabel>
      </CheckboxContent>
    </Fragment>
  );
};

export default CommonCheckbox;
