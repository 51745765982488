import styled from "@emotion/styled/macro";
import * as mq from "styles/media-queries";

const Wrapper = styled.div`
	padding: 0 20px;

	display: flex;
	flex-direction: column;
	justify-content: center;

	${mq.hdMinus} {
		justify-content: self-start;
	}
`;

const Header = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 30px;
	position: relative;
`;

const InputWrapper = styled.div`
	position: absolute;
	top: 28px;
	left: 32px;
	border-radius: 50%;
	background: #f0f0f0;
	width: 24px;
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid transparent;
	box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 12%);
`;

const Image = styled.img`
	width: 40px;
	height: 40px;
	object-fit: cover;
`;

const Title = styled.h2`
	margin: 0 15px;
`;

const SubHeader = styled.div`
	margin-bottom: 15px;
	display: flex;
	align-items: center;
`;

const Text = styled.span`
	font-weight: bold;
	margin-right: 10px;
`;

const Label = styled.div`
	font-size: 12px;
	color: #616161;
	margin-bottom: 14px;
`;

const Content = styled.p``;

export {
	Text,
	Label,
	Image,
	Title,
	Header,
	Content,
	Wrapper,
	SubHeader,
	InputWrapper,
};
