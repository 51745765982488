import styled from "@emotion/styled";

export const RadioLabel = styled.label`
	display: flex;
	gap: 10px;
	align-items: start;
	p {
		font-size: 10px;
		color: #8f939e;
	}
`;
