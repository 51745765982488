import React, { FC } from "react";
import { Controller } from "react-hook-form";
import { components, StylesConfig } from "react-select";
import { AsyncPaginate, LoadOptions } from "react-select-async-paginate";
import { GroupBase } from "react-select";
import styled from "@emotion/styled";

export const getCustomStyles = ({
  error,
  touched,
}: {
  error?: any;
  touched?: any;
}) => {
  const customStyles: StylesConfig = {
    control: (base, state) => ({
      ...base,
      height: "40px",
      borderRadius: "3px",
      background: state.isFocused ? "#fff" : "#f2f2f0",
      border: "none",
      borderBottom: state.isFocused
        ? error && touched
          ? "2px solid #f46582"
          : "2px solid #7175AC"
        : error && touched
        ? "2px solid #f46582"
        : "2px solid #f2f2f0",
      boxShadow: error && touched ? "2px solid #f46582" : "2px solid #7175AC",

      "&:hover": {
        borderBottom:
          error && touched ? "2px solid #f46582" : "2px solid #7175AC",
      },

      outline: "none",
    }),

    option: (styles) => ({
      ":active": {
        ...styles[":active"],
      },
      ":hover": {
        background: "#EBECF4",
      },
      padding: "10px",
      margin: "10px",
      borderRadius: "5px",
      cursor: "pointer",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 9999,
    }),

    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      transition: "transform 0.2s ease",
      transform: state.isFocused || state.hasValue ? "rotate(180deg)" : "",
    }),
    clearIndicator: (base, state) => ({
      ...base,
      cursor: "pointer",
    }),
  };
  return customStyles;
};

export const Control = (props: any) => {
  return (
    <React.Fragment>
      <label
        className="mb-1"
        style={{
          color: "#242424",
          fontSize: "12px",
        }}
      >
        {props.label}
      </label>
      <components.Control {...props} />
    </React.Fragment>
  );
};

interface AutoCompleteProps {
  handleOptions: LoadOptions<
    any,
    GroupBase<any>,
    {
      page: any;
    }
  >;
  name: string;
  placeholder: string;
  control: any;
  errors: any;
  label: string;
}

const AutoCompleteWrapper = styled.div`
  margin-bottom: 15px;
`;

const AutoComplete: FC<AutoCompleteProps> = ({
  control,
  errors,
  handleOptions,
  name,
  placeholder,
  label,
}) => {
  return (
    <AutoCompleteWrapper>
      <Controller
        name={name}
        render={({ field }) => (
          <AsyncPaginate
            value={field?.value}
            defaultOptions
            isDisabled={false}
            onChange={field?.onChange}
            onBlur={field?.onBlur}
            placeholder={placeholder}
            isClearable={true}
            loadOptions={handleOptions}
            additional={{
              page: 1,
            }}
            components={{
              Control: (props) => <Control {...props} label={label} />,
            }}
            styles={getCustomStyles({
              error: errors[name],
              touched: errors[name],
            })}
            debounceTimeout={1500}
            loadOptionsOnMenuOpen={false}
          />
        )}
        control={control}
      />
      <span className="text-danger">
        {errors[name] ? errors[name].message : null}
      </span>
    </AutoCompleteWrapper>
  );
};

export { AutoComplete };
