import styled from "@emotion/styled/macro";
import * as mq from "styles/media-queries";

interface IStackImageProps {
  isHomeTab?: boolean;
}

const PathwayStackWrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;

  ${mq.smallPhone} {
    margin: 0;
  }

  ${mq.tabletWide} {
    width: ${(props: IStackImageProps) => (props.isHomeTab ? "200px" : "100%")};
    margin: 0 15px 0 0;
  }

  .card-item {
    height: auto;
  }

  .card-item:nth-last-of-type(3) {
    position: absolute;
    top: -16px;
    right: 0;
    left: 0;
    width: 90%;
    margin: 0 auto;
    z-index: 1;
  }
  .card-item:nth-last-of-type(2) {
    position: absolute;
    top: -8px;
    right: 0;
    left: 0;
    width: 95%;
    z-index: 2;
    margin: 0 auto;
  }
  .card-item:nth-last-of-type(1) {
    width: 100%;
    position: relative;
    z-index: 3;
  }
`;

export { PathwayStackWrapper };
