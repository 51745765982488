import { useState, useEffect } from "react";

import { useAddedCoursesContext } from "context";

function useAddOrRemoveCourse() {
  const [isSelected, setIsSelected] = useState(false);
  const [currentCourse, setCurrentCourse] = useState<any>({});

  const { addedCourses, setAddedCourses } = useAddedCoursesContext();

  useEffect(() => {
    if (Object.keys(currentCourse).length !== 0) {
      const isSelected = addedCourses.some(
        (addedCourse: any) =>
          parseInt(currentCourse.id) === addedCourse.course_id
      );

      setIsSelected(isSelected);
    }
  }, [addedCourses, currentCourse]);

  function addCourse({ course }: any) {
    if (course) {
      const newCourse = {
        course_id: parseInt(course.id),
        licence_no: 200000,
      };

      let addedCoursesCopy = [...addedCourses];

      let newAddedCourses: any = [...addedCoursesCopy, newCourse];

      setAddedCourses(newAddedCourses);
    }
  }

  function removeCourse({ course }: any) {
    if (course) {
      let addedCoursesCopy = [...addedCourses];

      const courseIndex = addedCoursesCopy.findIndex((addedCourse: any) => {
        return addedCourse.course_id === parseInt(course.id);
      });

      addedCoursesCopy.splice(courseIndex, 1);

      setAddedCourses(addedCoursesCopy);
    }
  }

  return {
    addCourse,
    isSelected,
    removeCourse,
    setIsSelected,
    currentCourse,
    setCurrentCourse,
  };
}

export { useAddOrRemoveCourse };
