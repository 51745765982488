import styled from "@emotion/styled/macro";

const AiContentContainer = styled.div`
  color: white;
  background-color: #1f2021;
  overflow-x: hidden;
`;

const AiContentWrapper = styled.div`
  height: 100%;
`;

const OliveLink = styled.a`
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: normal;
  height: auto;
  padding: 7px 12px;
  border-radius: 4px;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white !important;
  background: #468be8;
  padding: 8px 20px !important;
  display: inline-block;
`;

const OliveLinkTransparent = styled.a`
  color: #6264a7 !important;
  padding: 0;
  margin: 0;
  font-size: 14px;
`;

export {
  OliveLink,
  AiContentWrapper,
  AiContentContainer,
  OliveLinkTransparent,
};
