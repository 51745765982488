import { useState, useEffect } from "react";

import { useMykademyDataContext, usePortfolioDataContext } from "context";

import { countryWithCode } from "utils/countryWithCode";

function useCreateProfileInfo() {
  const [profileInfo, setProfileInfo] = useState<any>({});

  const { isMykademy, mykademyUserData } = useMykademyDataContext();
  const { isPortfolio, portfolioUserData } = usePortfolioDataContext();

  useEffect(() => {
    let profileInfo = {};

    if (isMykademy) {
      const { us_country, us_name, us_phone, us_email, us_image } =
        mykademyUserData?.akademy?.user_data;

      const country: any = countryWithCode.find(
        (country) => country.code === us_country
      );

      let countryOption;

      if (country) {
        countryOption = `${country.dial_code} ${country.code} ${country.name}`;
      }

      profileInfo = {
        firstName: us_name.split(" ")[0],
        lastName: us_name.split(" ")[1],
        email: us_email,
        country: countryOption,
        phone: us_phone,
        imageUrl: us_image,
        domain: mykademyUserData?.userData?.domain,
        organizationName: mykademyUserData?.userData?.orgName,
        organizationLogo: mykademyUserData?.akademy?.org_logo,
      };
    } else if (isPortfolio) {
      const {
        first_name,
        last_name,
        email,
        mobile_number,
        country_code,
        image_url,
        organisation_details: { name, domain, branding_logo_url },
      } = portfolioUserData?.portfolio?.member_details;

      const country: any = countryWithCode.find(
        (country) => country.dial_code === country_code
      );

      const countryOption = `${country.dial_code} ${country.code} ${country.name}`;

      profileInfo = {
        firstName: first_name,
        lastName: last_name,
        email: email,
        country: countryOption,
        phone: mobile_number,
        imageUrl: image_url,
        domain: domain,
        organizationName: name,
        organizationLogo: branding_logo_url,
      };
    }

    setProfileInfo(profileInfo);
  }, [isMykademy, isPortfolio, mykademyUserData, portfolioUserData]);

  return { profileInfo };
}

export { useCreateProfileInfo };
