/** @jsxRuntime classic */
/** @jsx jsx */

import styled from "@emotion/styled/macro";

// interface SvgProps {
//   width: string;
// }

const OnboardingIcon = styled.svg`
  color: ${({ color }) => (color ? color : "#6264a7")};
  min-width: ${({ width }) => (width ? width : "16px")};
  fill: currentColor;
  vertical-align: middle;
  height: 100%;
`;

const AssociatedQuestion = styled.div`
  display: flex;
  font-size: 12px;
  color: #484644;
  margin-left: 10px;
  margin-bottom: 15px;
`;

const AssociatedQuestionImage = styled.img`
  margin-right: 9px;
`;

const AssociatedQuestionText = styled.p`
  margin-bottom: 0;
`;

const OnboardingCompanyLogoButton = styled.div``;

export {
  OnboardingIcon,
  AssociatedQuestion,
  AssociatedQuestionText,
  AssociatedQuestionImage,
  OnboardingCompanyLogoButton,
};
