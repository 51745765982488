import { useState, useEffect } from "react";

import { Notification } from "utils/notification";

import { localStorageKeys, useLocalStorageState } from "hooks";

import * as microsoftTeams from "@microsoft/teams-js";

import { useVcTokenValidUpdaterContext } from "context";

function useTeamsLogin() {
  const baseURL = process.env.REACT_APP_TEAMS_API_URL;
  const teamsAuthURL = process.env.REACT_APP_TEAMS_AUTH;

  const setVcTokenIsValid = useVcTokenValidUpdaterContext();

  const [openCommonLogin, setCommonLogin] = useState(false);

  const [vcTokenData, setVcTokenData] = useLocalStorageState({
    key: localStorageKeys.vcTokenData,
    defaultValue: {},
  });

  useEffect(() => {
    if (openCommonLogin) {
      microsoftTeams.authentication.authenticate({
        // url:
        //   process.env.REACT_APP_TEAMS_AUTH_URL ||
        //   "https://refactorauth.vle.olivegroup.io/api/auth/signin",

        url: `/${teamsAuthURL}`,
        width: 500,
        height: 504,

        successCallback: async (result: any) => {
          setCommonLogin(false);

          if (result.access_token) {
            Notification({
              message: "Authentication Successful",
              type: "success",
              title: "Success",
            });

            let ttl = new Date();

            let vcTokenData = {
              token: result.access_token,
              ttl: ttl,
            };

            setVcTokenData(vcTokenData);

            setVcTokenIsValid(true);
          } else {
            Notification({
              message: "Authentication Failed! No access_token.",
              type: "danger",
              title: "Warning",
            });
          }
        },
        failureCallback: (reason) => {
          setCommonLogin(false);

          Notification({
            message: "Authentication Failed!",
            type: "danger",
            title: "Warning",
          });
        },
      });
    }
  }, [
    baseURL,
    teamsAuthURL,
    setVcTokenData,
    openCommonLogin,
    setVcTokenIsValid,
  ]);

  return { vcTokenData, setCommonLogin };
}

export { useTeamsLogin };
