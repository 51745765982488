import React, { useRef, useReducer, useCallback, useLayoutEffect } from "react";

interface stateProps {
  status: string;
  data: {} | null;
  error: {} | null;
}

interface actionProps {
  type: string;
  data: {} | null;
  error: {} | null;
}

interface dataType {
  isIdle: boolean;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;

  run: (promise: any) => {};

  status: string;
  data: any;
  error: any | null;

  reset: () => null;
  setData: (data: any) => null;
  setError: (error: any) => null;
}

function useSafeDispatch(dispatch: (...args: any) => any) {
  const mountedRef = useRef(false);

  useLayoutEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  return useCallback(
    (...args) => (mountedRef.current ? dispatch(...args) : void 0),
    [dispatch]
  );
}

const asyncReducer: React.Reducer<stateProps, actionProps> = (
  state,
  action
) => {
  switch (action.type) {
    case "pending": {
      return { status: "pending", data: null, error: null };
    }

    case "resolved": {
      return { status: "resolved", data: action.data, error: null };
    }

    case "rejected": {
      return { status: "rejected", data: null, error: action.error };
    }

    default: {
      throw new Error(`Unhandled action type ${action.type}`);
    }
  }
};

const defaultInitialState: stateProps = {
  status: "idle",
  data: null,
  error: null,
};

function useAsync(initialState?: stateProps) {
  const initialStateRef = useRef({
    ...defaultInitialState,
    ...initialState,
  });

  let initialStateRefCurrent = initialStateRef.current;

  const [state, unsafeDispatch] = useReducer<
    React.Reducer<stateProps, actionProps>
  >(asyncReducer, initialStateRefCurrent);

  const safeSetState = useSafeDispatch(unsafeDispatch);

  const { status, data, error } = state;

  const setData = useCallback(
    (data) => {
      safeSetState({ data, type: "resolved" });
    },
    [safeSetState]
  );

  const setError = useCallback(
    (error) => {
      safeSetState({ error, type: "rejected" });
    },
    [safeSetState]
  );

  const reset = useCallback(() => {
    safeSetState(initialStateRefCurrent);
  }, [safeSetState, initialStateRefCurrent]);

  const run = useCallback(
    (promise) => {
      if (!promise || !promise.then) {
        throw new Error(
          `The argument passed to useAsync().run should be a promise. Maybe a function your are passing isnt returning anything!`
        );
      }

      safeSetState({ type: "pending" });

      return promise.then(
        (data: any) => {
          setData(data);
          return data;
        },
        (error: {} | null) => {
          setError(error);
          return Promise.reject(error);
        }
      );
    },
    [safeSetState, setData, setError]
  );

  return {
    isIdle: status === "idle",
    isLoading: status === "pending",
    isSuccess: status === "resolved",
    isError: status === "rejected",

    run,

    data,
    error,
    status,

    reset,
    setData,
    setError,
  } as dataType;
}

export { useAsync };
