import React, { useMemo, useState } from "react";

interface RecentCoursesContextParams {
  recentCoursesData: any;
  firstFiveCourses: any;
  totalPages: number;
  setRecentCoursesData: (state: any) => null;
  setFirstFiveCourses: (state: any) => null;
  setTotalPages: (state: number) => null;
}

const RecentCoursesContext = React.createContext<
  RecentCoursesContextParams | undefined
>(undefined);

RecentCoursesContext.displayName = "RecentCoursesContext";

function RecentCoursesDataProvider(props: any) {
  const [recentCoursesData, setRecentCoursesData] = useState([]);
  const [firstFiveCourses, setFirstFiveCourses] = useState([]);

  const [totalPages, setTotalPages] = useState<number>();

  const value = useMemo(() => {
    return {
      recentCoursesData,
      setRecentCoursesData,
      firstFiveCourses,
      setFirstFiveCourses,
      totalPages,
      setTotalPages,
    };
  }, [recentCoursesData, firstFiveCourses, totalPages]);

  return <RecentCoursesContext.Provider value={value} {...props} />;
}

function useRecentCoursesContext() {
  const context = React.useContext(RecentCoursesContext);

  if (context === undefined) {
    throw new Error(
      `useRecentCoursesContext must be used within a RecentCoursesDataProvider`
    );
  }

  return context;
}

export { RecentCoursesDataProvider, useRecentCoursesContext };
