import { useState, useEffect } from "react";

import { useMykademyDataContext, usePortfolioDataContext } from "context";

import { countryWithCode } from "utils/countryWithCode";

function useUpdateProfileInfo() {
  const [updatedData, setUpdatedData] = useState<any>({});

  const { isMykademy, mykademyUserData, setMykademyUserData } =
    useMykademyDataContext();
  const { isPortfolio, portfolioUserData, setPortfolioUserData } =
    usePortfolioDataContext();

  useEffect(() => {
    let updatedDataLocal: any;

    if (Object.keys(updatedData).length !== 0) {
      if (isMykademy) {
        updatedDataLocal = updatedData?.data?.data?.mykademy?.data?.user;

        const mykademyUserDataCopy = JSON.parse(
          JSON.stringify(mykademyUserData)
        );

        const { user_data } = mykademyUserDataCopy.akademy;

        const country: any = countryWithCode.find(
          (country) => country.code === updatedDataLocal.us_country
        );

        let countryOption;

        if (country) {
          countryOption = country.code;
        }

        user_data.us_name = updatedDataLocal.us_name;
        user_data.us_phone = updatedDataLocal.us_phone.split(" ")[1];
        user_data.us_email = updatedDataLocal.us_email;
        user_data.us_image = updatedDataLocal.us_image;
        user_data.us_country = countryOption;

        setMykademyUserData(mykademyUserDataCopy);
      } else if (isPortfolio) {
        updatedDataLocal = updatedData?.data?.data?.portfolio;

        const portfolioUserDataCopy = JSON.parse(
          JSON.stringify(portfolioUserData)
        );

        const { member_details } = portfolioUserDataCopy.portfolio;

        const country: any = countryWithCode.find(
          (country) => country.dial_code === updatedDataLocal.country_code
        );

        let countryOption;

        if (country) {
          countryOption = country.dial_code;
        }

        member_details.first_name = updatedDataLocal.first_name;
        member_details.last_name = updatedDataLocal.last_name;
        member_details.mobile_number = updatedDataLocal.mobile_number;
        member_details.email = updatedDataLocal.email;
        member_details.image_url = updatedDataLocal.image_url;
        member_details.country_code = countryOption;

        setPortfolioUserData(portfolioUserDataCopy);
      }
    }
  }, [
    isMykademy,
    isPortfolio,
    updatedData,
    mykademyUserData,
    portfolioUserData,
    setMykademyUserData,
    setPortfolioUserData,
  ]);

  return { updatedData, setUpdatedData };
}

export { useUpdateProfileInfo };
