/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React, { Fragment, useState } from "react";

// Third party imports
import { Row, Col } from "react-bootstrap";

// Component imports
import CropSelector from "components/imgCropper/cropSelector";
import { UseFormRegister, FieldValues } from "react-hook-form";
import UploadImageInitial from "components/imgCropper/uploadImageInitial";

// Style imports
import { Button } from "styles/components.styles";

import {
  PreviewImg,
  PreviewTitle,
  PreviewImgWrap,
  UploadLogoTitle,
  CompanyButtonWrapper,
} from "./imgCropper.styles";

// Hooks imports
import { useImageUpload } from "hooks";

// Image imports
import ImgPreviewImg from "assets/images/register/preview-img.jpg";

// Context imports
import { useModalContext } from "components/modal/modal";
interface companyProps {
  setError: any;
  register: UseFormRegister<FieldValues>;
  setCropData: any;
}

const ImgCropper = ({ setError, register, setCropData }: companyProps) => {
  const [cropper, setCropper] = useState<any>();

  const { image, setImage, setImageUploadEvent } = useImageUpload();

  const { setIsOpen } = useModalContext();

  const { onChange } = register("avatar");

  function deleteSelectedImage() {
    setImage("");
  }

  function cancelCrop() {
    setError("avatar", {
      type: "required",
      message: "",
    });
    setIsOpen(false);
  }

  function getCropData() {
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas().toDataURL());
      setIsOpen(false);
    }
  }

  function onChangeHandler(e: any) {
    e.preventDefault();

    setImageUploadEvent(e);

    onChange(e);
  }

  return (
    <Fragment>
      <Row>
        <Col md={6}>
          <UploadLogoTitle>Your Logo</UploadLogoTitle>
          {image ? (
            <CropSelector
              image={image}
              register={register}
              setCropper={setCropper}
              onChangeHandler={onChangeHandler}
              deleteSelectedImage={deleteSelectedImage}
            />
          ) : (
            <UploadImageInitial
              image={image}
              register={register}
              onChangeHandler={onChangeHandler}
            />
          )}
        </Col>
        <Col md={6}>
          <PreviewTitle>Preview</PreviewTitle>
          <PreviewImgWrap>
            {image ? (
              <div className="preview-img-wrap img-preview"></div>
            ) : (
              <PreviewImg src={ImgPreviewImg} alt="sample image" />
            )}
          </PreviewImgWrap>
        </Col>
      </Row>

      <CompanyButtonWrapper>
        <Button variant="dark" onClick={() => cancelCrop()}>
          Cancel
        </Button>
        <Button
          variant="purple"
          css={{ marginLeft: "10px" }}
          onClick={() => getCropData()}
          disabled={!image}
        >
          Save
        </Button>
      </CompanyButtonWrapper>
    </Fragment>
  );
};

export default ImgCropper;
