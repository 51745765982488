import React from "react";

import {
  Wrapper,
  LeftArrow,
  RightArrow,
  PageDescription,
} from "components/pagination/pagination.styles";

import { Button } from "styles/components.styles";

import LeftArrowIcon from "assets/images/common/arrow-left.svg";
import RightArrowIcon from "assets/images/common/arrow-right.svg";

interface PaginationProps {
  pageNumber: number;
  totalPages: number | undefined;
  setPageNumber: any;
}

/** Pagination using api calls */

const PaginationServer = ({
  pageNumber,
  totalPages,
  setPageNumber,
}: PaginationProps) => {
  function showPreviousPage() {
    setPageNumber((prevState: any) => prevState - 1);
  }

  function showNextPage() {
    setPageNumber((prevState: any) => prevState + 1);
  }

  return (
    <Wrapper>
      <Button
        variant="transparent"
        disabled={pageNumber === 1}
        padding="0"
        onClick={showPreviousPage}
      >
        <LeftArrow src={LeftArrowIcon} alt="left arrow" />
      </Button>
      <PageDescription>
        Showing {pageNumber} of {totalPages}
      </PageDescription>
      <Button
        variant="transparent"
        disabled={pageNumber === totalPages}
        padding="0"
        onClick={showNextPage}
      >
        <RightArrow src={RightArrowIcon} alt="left arrow" />
      </Button>
    </Wrapper>
  );
};

export default PaginationServer;
