let msLegacySettingsItems = [
	// {
	// 	title: "Payment settings",
	// 	icon: "ProfileIcon",
	// 	route: "settings/paymentSettings",
	// },
	{
		title: "SSO settings",
		icon: "KeyIcon",
		route: "settings/ssoSettings",
	},

	{
		title: "Email settings",
		icon: "EmailIcon",
		route: "settings/emailSettings",
	},
	{
		title: "Admin settings",
		icon: "ProfileIcon",
		route: "settings/adminSettings",
	},
	{
		title: "Label settings",
		icon: "LabelIcon",
		route: "settings/labelSettings",
	},
];

let msSettingsWithPendingList = [
	// {
	// 	title: "Payment settings",
	// 	icon: "ProfileIcon",
	// 	route: "settings/paymentSettings",
	// },
	{
		title: "SSO settings",
		icon: "KeyIcon",
		route: "settings/ssoSettings",
	},

	{
		title: "Email settings",
		icon: "EmailIcon",
		route: "settings/emailSettings",
	},
	{
		title: "Admin settings",
		icon: "ProfileIcon",
		route: "settings/adminSettings",
	},
	{
		title: "Label settings",
		icon: "LabelIcon",
		route: "settings/labelSettings",
	},
	{
		title: "Pending User Invitation",
		icon: "ProfileIcon",
		route: "settings/pendingUserInvitation",
	},
];

let msSettingsWithoutPendingList = [
	// {
	// 	title: "Payment settings",
	// 	icon: "ProfileIcon",
	// 	route: "settings/paymentSettings",
	// },
	{
		title: "SSO settings",
		icon: "KeyIcon",
		route: "settings/ssoSettings",
	},
	{
		title: "Email settings",
		icon: "EmailIcon",
		route: "settings/emailSettings",
	},
	{
		title: "Admin settings",
		icon: "ProfileIcon",
		route: "settings/adminSettings",
	},
	{
		title: "Label settings",
		icon: "LabelIcon",
		route: "settings/labelSettings",
	},
];

let legacySettingsItems = [
	// {
	// 	title: "Payment settings",
	// 	icon: "ProfileIcon",
	// 	route: "settings/paymentSettings",
	// },
	{
		title: "Email settings",
		icon: "EmailIcon",
		route: "settings/emailSettings",
	},
	{
		title: "Admin settings",
		icon: "ProfileIcon",
		route: "settings/adminSettings",
	},
	{
		title: "Label settings",
		icon: "LabelIcon",
		route: "settings/labelSettings",
	},
];

let settingsWithPendingList = [
	{
		title: "Email settings",
		icon: "EmailIcon",
		route: "settings/emailSettings",
	},
	{
		title: "Admin settings",
		icon: "ProfileIcon",
		route: "settings/adminSettings",
	},
	{
		title: "Label settings",
		icon: "LabelIcon",
		route: "settings/labelSettings",
	},
	{
		title: "Pending User Invitation",
		icon: "ProfileIcon",
		route: "settings/pendingUserInvitation",
	},
];

let settingsWithoutPendingList = [
	{
		title: "Email settings",
		icon: "EmailIcon",
		route: "settings/emailSettings",
	},
	{
		title: "Admin settings",
		icon: "ProfileIcon",
		route: "settings/adminSettings",
	},
	{
		title: "Label settings",
		icon: "LabelIcon",
		route: "settings/labelSettings",
	},
];

let tutorSettingsWithPendingList = [
	{
		title: "Pending User Invitation",
		icon: "ProfileIcon",
		route: "settings/pendingUserInvitation",
	},
];

export {
	legacySettingsItems,
	msLegacySettingsItems,
	settingsWithPendingList,
	msSettingsWithPendingList,
	settingsWithoutPendingList,
	msSettingsWithoutPendingList,
	tutorSettingsWithPendingList,
};
