import styled from "@emotion/styled/macro";

const ErrorForm = styled.p`
	margin-left: 10px;
	font-size: 12px;
	color: #c4314b;
`;

const ReachOut = styled.div``;

const ContactSalesWrapper = styled.div`
	/* Material UI overrides */

	.MuiInputBase-root {
		width: 100%;
		color: ${({ theme }: any) =>
			theme === "dark" ? "white !important" : "#242424 !important"};
		background: ${({ theme }) => (theme === "dark" ? "#2d2d2d" : "white")};

		border-radius: 4px;
		margin-bottom: 11px;
	}

	.MuiInputBase-root p {
		margin: 0;
	}

	.MuiSelect-select.MuiSelect-select {
		font-size: 14px;
		padding: 8px 15px;
		border: 2px solid transparent;
	}

	li.MuiListItem-root {
		font-size: 14px !important;
	}

	.MuiSelect-icon {
		color: ${({ theme }: any) =>
			theme === "dark" ? "white !important" : "initial"};
	}

	.MuiPaper-root {
		background: ${({ theme }) =>
			theme === "dark" ? "#2d2d2d !important" : "white"};
		color: ${({ theme }) => (theme === "dark" ? "white" : "initial")};
	}
`;

const ReachOutHeader = styled.div`
	font-weight: bold;
	font-size: 18px;
	margin-bottom: 20px;
`;

const ReachOutText = styled.p`
	margin-bottom: 30px;
`;

const ButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 20px;
`;

const Label = styled.label`
	width: 100%;
	text-transform: capitalize;
	margin-bottom: 0px;
	color: white;
	font-size: 12px;
	vertical-align: top;
`;

const SignInAnchor = styled.a`
	color: #468be8 !important;
	border-bottom: 1px solid #468be8;
	margin-left: 6px;
`;

const SignInWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const SubmitWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 12px;
`;

export {
	Label,
	ReachOut,
	ErrorForm,
	ReachOutText,
	SignInAnchor,
	SubmitWrapper,
	ButtonWrapper,
	SignInWrapper,
	ReachOutHeader,
	ContactSalesWrapper,
};
