/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import styled from "@emotion/styled/macro";

import ArrowRight from "assets/images/common/step-arrow-right.png";

import {
	TickCircleIcon,
	AddMembersIcon,
	CreateAccountIcon,
	// CreateAddLearningIcon,
} from "components/svg";

const OnboardingAccountSteps = styled.ul`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	list-style: none;
	margin: 0 0 30px;
	padding: 0;
`;

const OnboardingAccountStep = styled.li`
	display: flex;
	align-items: center;
	position: relative;
	margin: 5px 0;

	+ ::before {
		content: "";
		float: left;
		width: 5px;
		height: 8px;
		background-image: url(${ArrowRight});
		background-repeat: no-repeat;
		padding-left: 20px;
	}
`;

const StepText = styled.span<RegisterStepsStyleProps>`
	font-weight: ${({ isActive }) => (isActive ? "600" : "")};
	color: ${({ isActive, isCompleted }) =>
		isActive ? "#6264a7" : isCompleted ? "#13a10e" : "#141823"};
	vertical-align: middle;
	flex: 1;
	margin-left: 10px;
	margin-right: 15px;
	font-size: 14px;
	line-height: 1.29;
	text-align: left;
	cursor: pointer;
`;

interface RegisterStepsProps {
	active: "step-1" | "step-2" | "step-3" | "none";
	completed: {
		"step-1": boolean;
		"step-2": boolean;
		"step-3": boolean;
	};
}

interface RegisterStepsStyleProps {
	isActive: boolean;
	isCompleted?: boolean;
}

const RegisterSteps = ({ active, completed }: RegisterStepsProps) => {
	const stepOneComplete = completed["step-1"] === true;
	// const stepTwoComplete = completed["step-2"] === true;
	const stepThreeComplete = completed["step-3"] === true;

	return (
		<OnboardingAccountSteps>
			<OnboardingAccountStep>
				{stepOneComplete ? (
					<TickCircleIcon color="green" />
				) : (
					<CreateAccountIcon />
				)}
				<StepText isActive={active === "step-1"} isCompleted={stepOneComplete}>
					Create your Account
				</StepText>
			</OnboardingAccountStep>
			{/* <OnboardingAccountStep>
        {stepTwoComplete ? (
          <TickCircleIcon color="green" />
        ) : (
          <CreateAddLearningIcon />
        )}

        <StepText isActive={active === "step-2"} isCompleted={stepTwoComplete}>
          Create or Add your Learning
        </StepText>
      </OnboardingAccountStep> */}
			<OnboardingAccountStep>
				{stepThreeComplete ? (
					<TickCircleIcon color="green" />
				) : (
					<AddMembersIcon />
				)}

				<StepText
					isActive={active === "step-3"}
					isCompleted={stepThreeComplete}
				>
					Add your Members
				</StepText>
			</OnboardingAccountStep>
		</OnboardingAccountSteps>
	);
};

export default RegisterSteps;
