import { useState } from "react";

// Component imports
import MenuItems from "./menuItems";

import {
	Modal,
	HelpResources,
	ModalContents,
	ProfileInformation,
} from "components";

// Hook imports
import { useIsMobileView } from "hooks";
import { useMenuItemsData } from "./useMenuItemsData";

// Style imports
import { NavbarUl } from "components/navbar/navbar.styles";

const Navbar = ({
	hasPendingBill,
	showSsoButton,
	showSsoUpdateButton,
}: any) => {
	const { menuItemsData } = useMenuItemsData();

	const [type, setType] = useState("");

	const { isLowResLaptop } = useIsMobileView();

	return (
		<nav>
			<Modal
				maxwidth={
					isLowResLaptop
						? type === "Help Resources"
							? "450px"
							: "550px"
						: type === "Help Resources"
						? "600px"
						: "700px"
				}
				height={
					isLowResLaptop
						? type === "Profile"
							? "450px"
							: "300px"
						: type === "Profile"
						? "705px"
						: "350px"
				}
			>
				<ModalContents>
					{type === "Help Resources" && <HelpResources />}
					{type === "Profile" && (
						<ProfileInformation showSsoUpdateButton={showSsoUpdateButton} />
					)}
				</ModalContents>
				<NavbarUl>
					{menuItemsData.map((item) => {
						const depthLevel = 0;
						return (
							<MenuItems
								key={item.title}
								item={item}
								hasPendingBill={hasPendingBill}
								depthLevel={depthLevel}
								setType={setType}
								showSsoButton={showSsoButton}
							/>
						);
					})}
				</NavbarUl>
			</Modal>
		</nav>
	);
};

export default Navbar;
