import { useState, useEffect } from "react";

import { client } from "utils/api-client";

import { useQuery } from "react-query";

import { Notification } from "utils/notification";

import {
  useVleTokenContext,
  useMarketPlaceFilterContext,
  useCoursesFilterContext,
} from "context";

function useGetCpCourses() {
  const [courses, setCourses] = useState([]);
  const [getCoursesLoader, setGetCoursesLoader] = useState(false);

  const { vleToken } = useVleTokenContext();

  const { courseProviderValue } = useMarketPlaceFilterContext();

  const { isFree } = useCoursesFilterContext();

  const getCpCourses = () => {
    return client({
      method: "get",
      endpoint: "api/course-provider/courses",
      optional: {
        token: vleToken,
        params: {
          orderby_item: "created_date",
          orderby_direction: "DESC",
          show_b2b_enabled_only: "1",
          search_by: "is_free",
          app: "ms_teams",
          account_id: courseProviderValue?.split(" ")[1],
          is_free: isFree,
        },
      },
    });
  };

  const { data, isFetching, isSuccess, isError } = useQuery(
    "cpCourses",
    getCpCourses
  );

  useEffect(() => {
    if (isFetching) {
      setGetCoursesLoader(true);
    }
  }, [isFetching]);

  useEffect(() => {
    if (isSuccess) {
      setGetCoursesLoader(false);

      setCourses(data?.data?.data?.courses);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (isError) {
      setGetCoursesLoader(false);

      Notification({
        message: "Unable to get courses, please try again later",
        type: "warning",
        title: "Warning",
      });
    }
  }, [isError]);

  return { courses, setCourses, getCoursesLoader };
}

export { useGetCpCourses };
