import React, { useMemo, useState } from "react";

interface CourseLibraryContextParams {
  courseLibraryData: any;
  totalPages: number;
  setCourseLibraryData: (state: any) => null;
  setTotalPages: (state: number) => null;
}

const CourseLibraryContext = React.createContext<
  CourseLibraryContextParams | undefined
>(undefined);

CourseLibraryContext.displayName = "CourseLibraryContext";

function CourseLibraryDataProvider(props: any) {
  const [courseLibraryData, setCourseLibraryData] = useState([]);
  const [totalPages, setTotalPages] = useState<number>();

  const value = useMemo(() => {
    return {
      courseLibraryData,
      setCourseLibraryData,
      totalPages,
      setTotalPages,
    };
  }, [courseLibraryData, setCourseLibraryData, totalPages, setTotalPages]);

  return <CourseLibraryContext.Provider value={value} {...props} />;
}

function useCourseLibraryContext() {
  const context = React.useContext(CourseLibraryContext);

  if (context === undefined) {
    throw new Error(
      `useCourseLibraryContext must be used within a CourseLibraryDataProvider`
    );
  }

  return context;
}

export { CourseLibraryDataProvider, useCourseLibraryContext };
