/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React, { Fragment, useState, useEffect } from "react";

import { CustomHeightSpinner } from "styles/components.styles";

import styled from "@emotion/styled/macro";

interface IframeProps {
	isLoading?: boolean;
	src: string;
	title: string;
	height: string;
}

const IframeStyle = styled.iframe`
	width: 100%;
	border-width: 0px;
	margin-left: 0%;
`;

const Iframe = ({ isLoading, src, title, height }: IframeProps) => {
	const [iframeLoader, setIframeLoader] = useState(false);

	useEffect(() => {
		setIframeLoader(true);
	}, []);

	function hideSpinner() {
		try {
			setIframeLoader(false);
		} catch (e) {
			setIframeLoader(false);
		}
	}
	const contentId = (Math.random() + 1).toString(10).substring(7);

	return (
		<Fragment>
			<CustomHeightSpinner
				height={height}
				display={isLoading || iframeLoader ? "flex" : "none"}
			/>

			<IframeStyle
				title={title}
				key={contentId}
				src={src}
				onLoad={hideSpinner}
				allow="autoplay; fullscreen; encrypted-media"
				onError={() => setIframeLoader(false)}
				css={{
					display: isLoading || iframeLoader ? "none" : "block",
					height: height,
				}}
			/>
		</Fragment>
	);
};

export default Iframe;
