import { OnboardingIcon } from "screens/register/register.styles";

const CoursesIcon = () => {
  return (
    <OnboardingIcon
      width="12px"
      height="12px"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.5 6C8.5 6.18008 8.40317 6.34624 8.24651 6.43502L5.61976 7.92351C5.11978 8.20683 4.5 7.84566 4.5 7.27099V4.72907C4.5 4.1544 5.11977 3.79323 5.61975 4.07654L8.2465 5.56499C8.40317 5.65377 8.5 5.81993 8.5 6ZM6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0ZM1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6Z"></path>
    </OnboardingIcon>
  );
};

export default CoursesIcon;
