import React, { useMemo } from "react";

import { localStorageKeys, useLocalStorageState } from "hooks";

// Context imports
import { useMykademyDataContext, usePortfolioDataContext } from "context";
interface UserRoleContextParams {
	userRole: string;
	setUserRole: (state: boolean) => null;
	removeUserRoleFromLocalStorage: () => null;
}

const UserRoleContext = React.createContext<UserRoleContextParams | undefined>(
	undefined
);

UserRoleContext.displayName = "UserRoleContext";

function UserRoleProvider(props: any) {
	let [userRole, setUserRole, removeUserRoleFromLocalStorage] =
		useLocalStorageState({
			key: localStorageKeys.userRole,
		});

	userRole = userRole?.toLowerCase();

	const value = useMemo(() => {
		return {
			userRole,
			setUserRole,
			removeUserRoleFromLocalStorage,
		};
	}, [userRole, setUserRole, removeUserRoleFromLocalStorage]);

	return <UserRoleContext.Provider value={value} {...props} />;
}

function useUserRoleContext() {
	const { isMykademy, mykademyUserData } = useMykademyDataContext();
	const { isPortfolio, portfolioUserData } = usePortfolioDataContext();

	const context = React.useContext(UserRoleContext);

	if (context === undefined) {
		throw new Error(
			`useUserRoleContext must be used within a UserRoleProvider`
		);
	}

	const userRole = context.userRole.toLowerCase();

	const userRoleAdmin =
		userRole === "admin" ||
		userRole === "branch_manager" ||
		userRole === "tutor" ||
		userRole === "mentor" ||
		userRole === "content_editor";

	const userRoleTutor = userRole === "tutor" || userRole === "employer";

	const userRoleMentor = userRole === "mentor";

	const userRoleContentEditor = userRole === "content_editor";

	const userRoleLearner =
		userRole === "student" ||
		userRole === "students" ||
		userRole === "learners" ||
		userRole === "learner" ||
		userRole === "apprentices";

	let dataType = isPortfolio ? "portfolio" : isMykademy ? "mykademy" : null;

	return {
		...context,

		dataType: dataType,

		isAdmin: userRoleAdmin,
		isLearner: userRoleLearner,
		isTutor: userRoleTutor,

		isAsessor: userRole === "assessor",
		isVerifier: userRole === "verifier",

		isMykademyAdmin: userRoleAdmin && mykademyUserData ? true : false,
		isMykademyAdminOnly:
			userRole === "admin" && mykademyUserData ? true : false,
		isMykademyBranchAdmin: userRole === "branch_manager" && mykademyUserData,
		isMykademyLearner: userRoleLearner && mykademyUserData,
		isMykademyTutor: userRoleTutor && mykademyUserData,
		isMykademyMentor: userRoleMentor && mykademyUserData,
		isMykademyContentEditor: userRoleContentEditor && mykademyUserData,

		isPortfolioAdmin: userRoleAdmin && portfolioUserData,
		isPortfolioLearner: userRoleLearner && portfolioUserData,
		isPortfolioTutor: userRoleTutor && portfolioUserData,
	};
}

export { UserRoleProvider, useUserRoleContext };
