import React from "react";

import styled from "@emotion/styled/macro";

import { Spinner } from "styles/components.styles";

interface FullPageWrapperProps {
  hide: boolean;
}

const FullPageWrapper = styled.div<FullPageWrapperProps>`
  font-size: 2em;
  height: 100vh;
  display: ${({ hide }) => (hide ? "none" : "flex")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  color: #fff;
  z-index: 99999999;

  background: url(/static/media/landing-tick.4d1c6d34.png) calc(50% + 40px) 100% /
      1120px no-repeat,
    url(/static/media/bg-header-01.32b9dc4f.png) 50% center / cover no-repeat;
`;

const MessageWrapper = styled.div`
  margin-bottom: 16px;
  letter-spacing: 2px;
  text-transform: capitalize;
  font-weight: 600;
`;

const FullPageOverlay = ({
  message,
  hide,
}: {
  message: string;
  hide: boolean;
}) => {
  return (
    <FullPageWrapper hide={hide}>
      <MessageWrapper>{message}</MessageWrapper>
      <Spinner />
    </FullPageWrapper>
  );
};

export default FullPageOverlay;
