import { useState, useEffect } from "react";

// Third party imports
import { useQuery } from "react-query";

// Util imports
import { client } from "utils/api-client";
import { Notification } from "utils/notification";

// Context imports
import { useVleTokenContext } from "context";
import { useAuthorizeLearnerPathway } from "hooks/useAuthorizeLearnerPathway";

function useGetPathwayEnrollmentDetails({
  pathwayId,
  learnerId,
}: {
  pathwayId: string;
  learnerId: string;
}) {
  const [pathway, setPathway] = useState<any>();

  const { vleToken } = useVleTokenContext();

  const { pathwayToken, isLoading: isAuthorizing } =
    useAuthorizeLearnerPathway();

  const getPathwayEnrollmentDetails = () => {
    return client({
      method: "get",
      endpoint: "api/learning-pathway/enrolled-courses/admin",
      optional: {
        token: vleToken,
        params: {
          token: pathwayToken,
          learner: learnerId,
          pathway: pathwayId,
        },
      },
    });
  };

  const { data, error, isSuccess, isLoading, isError } = useQuery(
    ["pathwayEnrollmentDetails", { pathwayId, learnerId }],
    getPathwayEnrollmentDetails,
    {
      refetchOnWindowFocus: false,
      enabled: !!pathwayToken && !!pathwayId && !!learnerId,
      select: (data) => {
        return data?.data?.data;
      },
    }
  );

  useEffect(() => {
    if (isSuccess) {
      setPathway(data[0]);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (isError) {
      console.log({ error });

      Notification({
        message:
          "Unable to get pathway enrollment details, please try again later",
        type: "warning",
        title: "Warning",
      });
    }
  }, [error, isError]);

  return {
    isLoading: isLoading || isAuthorizing,
    pathway,
  };
}

export { useGetPathwayEnrollmentDetails };
