/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React, { Fragment } from "react";

import { Doughnut } from "react-chartjs-2";

import { Col } from "react-bootstrap";

import { Chart, ArcElement, Tooltip, Legend } from "chart.js";

import { BoxShadow, CustomHeightSpinner } from "styles/components.styles";
import { DoughnutWrapper } from "styles/charts.styles";

interface DoughnutChartProps {
	doughtNutData: (number | null)[];
	enrollmentSumHeader: any;
	isLoadingUserAnalytics: boolean;
}

Chart.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({
	doughtNutData,
	enrollmentSumHeader,
	isLoadingUserAnalytics,
}: DoughnutChartProps) => {
	const data = {
		datasets: [
			{
				data: doughtNutData,
				backgroundColor: ["#13A10E", "#6264A7", "#D9DBDB", "#C4314B"],
				label: "Expenditures",
			},
		],
		labels: ["Passed", "In Progress", "Not Started"],
	};

	const options = {
		responsive: true,
		plugins: {
			tooltip: {
				callbacks: {
					label: function (tooltipItem: any) {
						let total = tooltipItem.dataset.data.reduce(function (
							previousValue: any,
							currentValue: any
						) {
							return previousValue + currentValue;
						});

						let currentTooltipValue =
							tooltipItem.dataset.data[tooltipItem.dataIndex];

						let percentage = Math.floor(
							(currentTooltipValue / total) * 100 + 0.5
						);

						return `${percentage}%`;
					},
				},
			},
			legend: {
				position: "right",
				labels: {
					boxWidth: 12,
					boxHeight: 12,
					fontSize: 12,
					color: "#252424",
				},
			},
		},
		cutout: "70%",

		title: {
			display: false,
			text: "Chart.js Doughnut Chart",
		},

		animation: {
			animateScale: true,
			animateRotate: true,
		},
	};

	return (
		<Col xs={12} sm={6} md={6} lg={4} xl={3} css={{ marginBottom: "20px" }}>
			<BoxShadow css={{ height: "100%" }}>
				<Fragment>
					<h4 css={{ marginBottom: "20px" }}>
						{/* Enrollment Summary */}
						{enrollmentSumHeader?.label}
					</h4>
					{isLoadingUserAnalytics ? (
						<CustomHeightSpinner height="245px" />
					) : (
						<DoughnutWrapper>
							{/* @ts-ignore */}
							<Doughnut data={data} options={options} />
						</DoughnutWrapper>
					)}
				</Fragment>
			</BoxShadow>
		</Col>
	);
};

export default DoughnutChart;
