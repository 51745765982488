import { useState, useEffect } from "react";

// Util imports
import { client } from "utils/api-client";
import { Notification } from "utils/notification";

// Context imports
import { useVleTokenContext } from "context";
import { useQuery } from "react-query";

interface IGetOrgnameSettingsReturnType {
	isSuccess: boolean;
	isLoading: boolean;
	showOrgNameBesideLogo: boolean;
}

const useGetOrgnameSetting: () => IGetOrgnameSettingsReturnType = () => {
	const [showOrgNameBesideLogo, setShowOrgNameBesideLogo] = useState(true);
	const { vleToken } = useVleTokenContext();

	const getOrgnameSetting = () => {
		return client({
			method: "get",
			endpoint: "api/show-orgname-beside-logo",
			optional: {
				token: vleToken,
			},
		});
	};

	const { data, error, isError, isLoading, isSuccess } = useQuery(
		["orgnameSettings"],
		getOrgnameSetting
	);

	useEffect(() => {
		if (isSuccess) {
			setShowOrgNameBesideLogo(data?.data?.data?.showOrgNameBesideLogo);
		}
	}, [data, isSuccess]);

	useEffect(() => {
		if (isError) {
			console.log({ error });

			Notification({
				message:
					"Unable to get organisation name settings, please try again later",
				type: "warning",
				title: "Warning",
			});
		}
	}, [error, isError]);

	return {
		isSuccess,
		isLoading,
		showOrgNameBesideLogo,
	};
};

export { useGetOrgnameSetting };
