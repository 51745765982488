import { useState, useEffect } from "react";

import axios from "axios";
import * as microsoftTeams from "@microsoft/teams-js";

import { Notification } from "utils/notification";

import {
	useDomainContext,
	useVleTokenContext,
	useUserRoleContext,
	useMykademyDataContext,
} from "context";

import { useModalContext } from "components/modal/modal";

// Hook imports
import {
	useLogout,
	useGetTokenFromMS,
	localStorageKeys,
	useLocalStorageState,
} from "hooks";

interface useLinkSsoProps {
	url: string | null;
}

function useLinkSso({ url }: useLinkSsoProps) {
	const baseURL = process.env.REACT_APP_TEAMS_API_URL;
	const ssoSigninUrl = process.env.REACT_APP_TEAMS_ADMIN_SSO_SIGN_IN;

	const [linkSso, setLinkSso] = useState(false);

	const [updateRoleLoader, setUpdateRoleLoader] = useState(false);

	const { setLogout, logoutLoader, setLogoutLoader } = useLogout();

	const { hide, setSsoRegister, setCheckAutoLogin } = useGetTokenFromMS();

	const { setIsOpen } = useModalContext();
	const { setDomain } = useDomainContext();

	const { vleToken, setVleToken } = useVleTokenContext();
	const { userRole, setUserRole } = useUserRoleContext();
	const { mykademyUserData, setMykademyUserData } = useMykademyDataContext();

	const [showLoginPage, setShowLoginPage] = useLocalStorageState({
		key: localStorageKeys.showLoginPage,
	});

	useEffect(() => {
		if (linkSso) {
			setLinkSso(false);

			// Initialize the Microsoft Teams SDK
			microsoftTeams.initialize();

			// Get the user context from Teams
			microsoftTeams.getContext(async (context: microsoftTeams.Context) => {
				// console.log({ context });
				let tenant_id = context?.tid || "";

				localStorage.setItem("tenant_id", tenant_id);

				const ssoLoginSuccess = async (result: any) => {
					// console.log("result", result);
					localStorage.setItem("graph_access_token", result.access_token);

					console.log("update role", result.updateRole);

					if (result.updateRole) {
						try {
							let apiBody = {
								access_token: result.access_token,
							};

							setUpdateRoleLoader(true);

							let updateTokenResponse = await axios.post(
								`${baseURL}api/check-update-sso-admin-login`,
								apiBody,
								{
									headers: {
										Authorization: vleToken,
										"Content-Type": "application/json",
									},
								}
							);

							console.log({ updateTokenResponse });

							if (updateTokenResponse) {
								setUpdateRoleLoader(false);

								console.log("data", updateTokenResponse);
								const userData = updateTokenResponse?.data?.data;

								setMykademyUserData(userData);
								setUserRole(
									userData?.akademy?.user_data?.role_name.toLowerCase()
								);
								setVleToken(userData?.vleToken);
								setDomain(userData?.userData.domain);

								// @ts-ignore
								window.location.assign(window.location);

								// setLogout(true);
								// setSsoRegister(true);
							}
						} catch (e: any) {
							console.log({ e });

							setUpdateRoleLoader(false);

							Notification({
								message: e?.response.data.message,
								type: "warning",
								title: "Warning",
							});
						}
					}

					if (
						tenant_id &&
						result.access_token &&
						(result.updateRole === null || result.updateRole === undefined)
					) {
						try {
							let apiBody = {
								tenant_id: tenant_id,
							};

							let storeTidResponse = await axios.post(
								`${baseURL}api/store-tenant-id-for-organisation`,
								apiBody,
								{
									headers: {
										Authorization: vleToken,
										"Content-Type": "application/json",
									},
								}
							);

							// console.log({ storeTidResponse });

							if (storeTidResponse) {
								const mykademyUserDataCopy = JSON.parse(
									JSON.stringify(mykademyUserData)
								);

								mykademyUserDataCopy.userData.tenant_id = tenant_id;

								setMykademyUserData(mykademyUserDataCopy);
							}

							Notification({
								message: "Organisation successfully configured with microsoft",
								type: "success",
								title: "Success",
							});
						} catch (error: any) {
							console.log({ error });

							Notification({
								message: error?.response.data.message,
								type: "warning",
								title: "Warning",
							});
						}
					}
				};

				const ssoLoginFailure = async (error: any) => {
					console.error("SSO failed: ", error);
					// setLinkSso(false);
				};

				// Perform Azure AD single sign-on authentication
				let authTokenRequestOptions = {
					// url: `https://devauth.vle.olivegroup.io/api/auth/sso-admin-sign-in?tid=${context.tid}`,
					url: `/${url ? url : ssoSigninUrl}`,
					width: 500,
					height: 504,
					successCallback: (result: any) => {
						ssoLoginSuccess(result);
					}, // The result variable is the SSO token.
					failureCallback: (error: any) => {
						ssoLoginFailure(error);
					},
				};

				microsoftTeams.authentication.authenticate(authTokenRequestOptions);
			});
		}
	}, [
		url,
		baseURL,
		linkSso,
		vleToken,
		setDomain,
		setUserRole,
		setVleToken,
		ssoSigninUrl,
		mykademyUserData,
		setMykademyUserData,
	]);

	return { setLinkSso, updateRoleLoader };
}

export { useLinkSso };
