const localStorageKeys = {
	vleToken: "vleToken",
	isLegacy: "isLegacy",
	userRole: "userRole",
	legacyType: "legacyType",
	selectedOption: "selectedOption",
	onboardingSteps: "onboardingSteps",
	mykademyUserData: "mykademyUserData",
	portfolioUserData: "portfolioUserData",
	mykademyOrganizationData: "mykademyOrganizationData",
	eventLink: "eventLink",
	courseId: "courseId",
	domain: "domain",
	addedCourses: "addedCourses",
	vcTokenData: "vcTokenData",
	ssoSignup: "ssoSignup",
	showLoginPage: "showLoginPage",
	pathwayToken: "pathwayToken",
	msOid: "msOid",
};

export { localStorageKeys };
