import React, { useState, useRef, useContext, ReactNode } from "react";

import {
  localStorageKeys,
  useOnClickOutside,
  useLocalStorageState,
} from "hooks";

import {
  ListItem,
  ListItems,
  SelectedTextWrapper,
  TellMeMoreIcon,
  SelectContainer,
  SelectedText,
} from "./dropdown.styles";

const SelectContext = React.createContext<{
  selectedOption: string;
  changeSelectedOption: ({
    option,
    icon,
    item,
  }: {
    option: string;
    icon: string;
    item: any;
  }) => void;
}>({
  selectedOption: "",
  changeSelectedOption: ({
    option,
    icon,
    item,
  }: {
    option: string;
    icon: string;
    item: any;
  }) => {},
});

const Select: React.FC<{
  children: ReactNode | ReactNode[];
  defaultValue?: string;
  placeholder?: string;
  defaultIcon?: string;
  setSelectedItem?: any;
  setImageLoading?: any;
}> = ({
  children,
  defaultValue,
  placeholder,
  defaultIcon,
  setSelectedItem,
  setImageLoading,
}) => {
  const [selectedOption, setSelectedOption] = useState(defaultValue || "");
  const [selectedOptionIcon, setSelectedOptionIcon] = useState(
    defaultIcon || ""
  );
  const [showDropdown, setShowDropdown] = useState(false);

  const [, setSelectedOptionLocal] = useLocalStorageState({
    key: localStorageKeys.selectedOption,
  });

  const selectPlaceHolder = placeholder || "Choose an option";

  const selectContainerRef = useRef(null);

  function toggleDropdownHandler() {
    setShowDropdown(!showDropdown);
  }

  function clickOutsideHandler() {
    setShowDropdown(false);
  }

  useOnClickOutside({ ref: selectContainerRef, handler: clickOutsideHandler });

  function updateSelectedOption({
    option,
    icon,
    item,
  }: {
    option: string;
    icon: string;
    item: any;
  }) {
    setSelectedOption(option);
    setSelectedOptionIcon(icon);
    setSelectedItem(item);

    setSelectedOptionLocal(item);

    // localStorage.setItem("selected-option", JSON.stringify(item));

    setImageLoading(true);

    setShowDropdown(false);
  }

  return (
    <SelectContext.Provider
      value={{ selectedOption, changeSelectedOption: updateSelectedOption }}
    >
      <SelectContainer ref={selectContainerRef}>
        <SelectedTextWrapper
          showDropdown={showDropdown}
          onClick={toggleDropdownHandler}
        >
          {defaultIcon ? (
            <TellMeMoreIcon src={selectedOptionIcon} alt={selectedOption} />
          ) : null}

          <SelectedText>
            {selectedOption ? selectedOption : selectPlaceHolder}
          </SelectedText>
        </SelectedTextWrapper>
        <ListItems showDropdown={showDropdown}>{children}</ListItems>
      </SelectContainer>
    </SelectContext.Provider>
  );
};

const Option: React.FC<{
  children: ReactNode | ReactNode[];
  value: string;
  icon: string;
  item: any;
}> = ({ children, value, icon, item }) => {
  const { changeSelectedOption } = useContext(SelectContext);

  return (
    <ListItem
      onClick={() =>
        changeSelectedOption({
          option: value,
          icon: icon,
          item: item,
        })
      }
    >
      {children}
    </ListItem>
  );
};

export { Select, Option };
