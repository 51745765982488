import { useState, useEffect } from "react";

// Third party imports
import { useQuery } from "react-query";

// Util imports
import { client } from "utils/api-client";
import { Notification } from "utils/notification";

// Context imports
import { useVleTokenContext } from "context";

function useGetRecentCourses() {
  const [allCourses, setAllCourses] = useState([]);
  const [firstThreeCourses, setFirstThreeCourses] = useState([]);
  const [
    firstThreeCoursesAccToCompletion,
    setFirstThreeCoursesAccToCompletion,
  ] = useState([]);

  const { vleToken } = useVleTokenContext();

  const getRecentCourses = () => {
    return client({
      method: "get",
      endpoint: "api/akademy/recent-courses?course_status=1",
      optional: {
        token: vleToken,
      },
    });
  };

  const { data, error, isSuccess, isLoading, isError } = useQuery(
    "recentCourses",
    getRecentCourses,
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (isSuccess) {
      const recentCourses = data?.data?.data;

      const coursesAccordingToCompletion = recentCourses.filter(
        (courses: any) =>
          courses.cs_percentage !== "100" && courses.cs_percentage !== "0"
      );

      const firstThreeCoursesAccToCompletion =
        coursesAccordingToCompletion.slice(0, 3);

      const firstThreeCourses = recentCourses.slice(0, 3);

      setAllCourses(recentCourses);
      setFirstThreeCourses(firstThreeCourses);
      setFirstThreeCoursesAccToCompletion(firstThreeCoursesAccToCompletion);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (isError) {
      console.log({ error });

      Notification({
        message: "Unable to get recent courses, please try again later",
        type: "warning",
        title: "Warning",
      });
    }
  }, [error, isError]);

  return {
    isLoading,
    allCourses,
    firstThreeCourses,
    firstThreeCoursesAccToCompletion,
  };
}

export { useGetRecentCourses };
