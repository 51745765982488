import { useGetOrgData } from "hooks";
import React from "react";
import {
  ChildHeader,
  ChildHeaderWrapper,
  ChildWrapper,
  HelpResourcesWrapper,
  IconWrapper,
  Title,
} from "./helpResources.styles";
import ContentSupportIcon from "assets/images/icon/content-support.png";
import PlatformSupportIcon from "assets/images/icon/platform-support.png";

const HelpResources = () => {
  const { orgData } = useGetOrgData();

  return (
    <HelpResourcesWrapper>
      <Title>Support Details</Title>
      <ChildWrapper>
        <IconWrapper>
          <img
            src={ContentSupportIcon}
            alt="contact-support"
            style={{ height: "22px", width: "22px" }}
          />
        </IconWrapper>
        <ChildHeaderWrapper>
          <a href={`mailto:${orgData?.org?.content_support_email}`}>
            {orgData?.org?.content_support_email}
          </a>
          <ChildHeader>Content Support</ChildHeader>
        </ChildHeaderWrapper>
      </ChildWrapper>
      <ChildWrapper>
        <IconWrapper>
          <img
            src={PlatformSupportIcon}
            alt="contact-support"
            style={{ height: "22px", width: "22px" }}
          />
        </IconWrapper>
        <ChildHeaderWrapper>
          <a href={`mailto:${orgData?.org?.platform_support_email}`}>
            {orgData?.org?.platform_support_email}
          </a>
          <ChildHeader>Platform Support</ChildHeader>
        </ChildHeaderWrapper>
      </ChildWrapper>
      <span>
        Or you can explore our{" "}
        <a
          target="_blank"
          href="https://vlehelp.olivegroup.io/"
          rel="noreferrer"
        >
          knowledge base.
        </a>
      </span>
    </HelpResourcesWrapper>
  );
};

export default HelpResources;
