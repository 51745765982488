import { useState, useCallback } from "react";

// import { client } from "utils/api-client";

// import { useQuery } from "react-query";

// import { Notification } from "utils/notification";

// import { useLegacyContext, useVleTokenContext } from "context";

function useGetUserPayments() {
	const [paymentData, setPaymentData] = useState<any>({});

	const [learnersCreatedThisMonth, setLearnersCreatedThisMonth] =
		useState<any>("");

	const [paymentOrganization, setPaymentOrganization] = useState([]);
	const [isCardAdded, setIsCardAdded] = useState(false);
	const [hasPendingBill, setHasPendingBill] = useState(false);
	const [totalLearnersFromOrganization, setTotalLearnersFromOrganization] =
		useState<any>("");

	// const { vleToken } = useVleTokenContext();

	// const { isLegacy } = useLegacyContext();

	let isLoading = false;

	// const getUserPayments = () => {
	// 	if (!isLegacy && vleToken) {
	// 		return client({
	// 			method: "get",
	// 			endpoint: "api/payment/list",
	// 			optional: {
	// 				token: vleToken,
	// 			},
	// 		});
	// 	} else {
	// 		return null;
	// 	}
	// };

	// const { data, isSuccess, isLoading, isError } = useQuery(
	// 	"userPayments",
	// 	getUserPayments,
	// 	{
	// 		refetchOnWindowFocus: false,
	// 	}
	// );

	const calculateTotalLearners = useCallback((data: any) => {
		let totalUsers = data.reduce(
			(total: any, item: any) => total + item.quantity,
			0
		);
		return totalUsers;
	}, []);

	// useEffect(() => {
	// 	if (isSuccess) {
	// 		const paymentData = data?.data.data;

	// 		if (paymentData) {
	// 			setPaymentData(paymentData);

	// 			const { organization } = paymentData;

	// 			// Organization related
	// 			const totalLearners = organization.total_learners;
	// 			const isCardAdded = organization.is_card_added;

	// 			setTotalLearnersFromOrganization(totalLearners);

	// 			setPaymentOrganization(organization);
	// 			setLearnersCreatedThisMonth(totalLearners);
	// 			setIsCardAdded(isCardAdded);

	// 			let hasPendingBill;

	// 			if (organization.subscription_status === "canceled") {
	// 				hasPendingBill = true;
	// 			} else {
	// 				hasPendingBill = false;
	// 			}

	// 			if (organization.payment_failed_count >= 3) {
	// 				hasPendingBill = true;
	// 			} else {
	// 				hasPendingBill = false;
	// 			}

	// 			// hasPendingBill = true;

	// 			setHasPendingBill(hasPendingBill);
	// 		}
	// 	}
	// }, [isSuccess, data]);

	// useEffect(() => {
	// 	if (isError) {
	// 		Notification({
	// 			message: "Unable to get payments data, please try again later",
	// 			type: "warning",
	// 			title: "Warning",
	// 		});
	// 	}
	// }, [isError]);

	return {
		isLoading,
		paymentData,
		isCardAdded,
		hasPendingBill,
		paymentOrganization,
		calculateTotalLearners,
		learnersCreatedThisMonth,
		setLearnersCreatedThisMonth,
		totalLearnersFromOrganization,
	};
}

export { useGetUserPayments };
