import React from "react";

import { useGetUserPayments } from "screens/membershipPlans/useGetUserPayments";
import { useUserRoleContext } from "context";

const PendingBillsMessage = () => {
  const { hasPendingBill } = useGetUserPayments();
  const { isMykademyAdmin } = useUserRoleContext();

  if (hasPendingBill) {
    if (isMykademyAdmin) {
      return (
        <div className="alert alert-danger alert-dismissible fade show">
          <strong>You have pending bills. </strong> You need to pay your pending
          bills to continue accessing your admin section and other
          functionalities
        </div>
      );
    } else {
      return (
        <div className="alert alert-danger alert-dismissible fade show">
          <strong>Account Disabled Temporarily. </strong> Please Contact your
          Administrator
        </div>
      );
    }
  } else {
    return null;
  }
};

export default PendingBillsMessage;
