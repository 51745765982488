import * as yup from "yup";

import { useState, useEffect } from "react";

function useEditProfileSchema() {
  const [schema, setSchema] = useState<any>();

  useEffect(() => {
    const shapeObject: any = {
      "First Name": yup.string().required("First Name cannot be empty"),
      "Last Name": yup.string().required("Last Name cannot be empty"),
      "Phone Number": yup
        .string()
        .required("Phone Number cannot be empty")
        .matches(/^[0-9]{9,12}$/, "Enter the number In between 9 to 12 digits"),

      Country: yup
        .mixed()
        .test("required", "Country cannot be empty", (value) => {
          return value && value.length;
        }),

      "Email Address": yup.string().required("Email Address cannot be empty"),
    };

    let schema = yup.object().shape(shapeObject);

    setSchema(schema);
  }, []);

  return { schema };
}

export { useEditProfileSchema };
