import { useState, useEffect, useRef, useCallback } from "react";

interface LocalStorageStateProps {
	key: string;
	defaultValue?: any;
	options?: any;
}

/**
 * Custom hook to manage local storage
 * @function
 * @param {string} key - key to assign to local storage
 * @param {any} defaultValue - Default value passed by hook user
 * @param {object} options - Hook user can pass his/her own serializing and deserializing method
 * @return {any} state - State which is synced to local storage
 * @return {function} setState - Function to set the state
 * @return {function} removeStateFromLocalStorage - Function to remove state from local storage
 */

function useLocalStorageState({
	key,
	defaultValue = "",
	options: { serialize = JSON.stringify, deserialize = JSON.parse } = {},
}: LocalStorageStateProps) {
	/** `window.localStorage.getItem(key)` is a very expensive computation and setting
	 *  it as it is as the default value is not optimum. So we define a function/callback
	 *  instead which calls it. Even though the function is defined each re render, it is
	 *  only called when its needed which is the inital render. Also function being defined
	 *  is very cheap.
	 */
	const [state, setState] = useState(() => {
		const valueInLocalStorage = localStorage.getItem(key);

		if (valueInLocalStorage) {
			return deserialize(valueInLocalStorage);
		}

		/** Same case as for the useState case above. In case the hook user passes an
		 *  expensive computation function, we only call it when needed.
		 */
		return typeof defaultValue === "function" ? defaultValue() : defaultValue;
	});

	/** In case the key value changes, we delete the previous key value and data associated
	 *  and we assign the new key with the relevant data
	 */

	const previousKeyRef = useRef(key);

	useEffect(() => {
		const previousKey = previousKeyRef.current;

		if (previousKey !== key) {
			localStorage.removeItem(previousKey);
		}

		previousKeyRef.current = key;

		if (state) {
			localStorage.setItem(key, serialize(state));
		}
	}, [key, state, serialize]);

	/** --- --- */

	const removeStateFromLocalStorage = useCallback(() => {
		console.log(`${key} removed`);
		localStorage.removeItem(key);
	}, [key]);

	return [state, setState, removeStateFromLocalStorage];
}

export { useLocalStorageState };
