/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React from "react";

// Style imports
import styled from "@emotion/styled/macro";

// Interface imports
// import { IFormValues } from "screens/register/register";

import { BsExclamationCircle } from "react-icons/bs";

interface IpProps {
	isError: boolean;
	backgroundcolor: string;
	color: string;
	border: string;
	height: string;
	isSSo: boolean;
	fontsize: string;
	fontweight: string;
}

interface LabelProps {
	color?: string;
	hideLabel?: boolean;
}

// Styles
export const ErrorForm = styled.p`
	margin-left: 10px;
	font-size: 12px;
	color: #c4314b;
`;

export const Label = styled.label<LabelProps>`
	display: block;
	margin-bottom: 5px;
	font-size: 12px;
	color: ${({ color }) => (color ? color : "#242424")};
	text-transform: capitalize;
	visibility: ${({ hideLabel }) => (hideLabel ? "hidden" : "visible")};
	height: ${({ hideLabel }) => (hideLabel ? 0 : "auto")};
`;

const InputText = styled.input<IpProps>`
	margin-bottom: 10px;
	border-radius: 3px;
	background-color: ${({ backgroundcolor }) =>
		backgroundcolor ? backgroundcolor : "#fff"};
	border: ${({ border }) => (border ? border : "2px solid transparent")};
	font-size: ${({ fontsize }) => (fontsize ? fontsize : "14px")};
	color: ${({ color }) => (color ? color : "#242424")};
	background-clip: initial;
	padding: 0px 12px;
	height: ${({ height }) => (height ? height : "39px")};
	border: ${({ isSSo, isError }: IpProps) => {
		if (isSSo) {
			return isError ? "2px solid #c4314b" : "";
		} else {
			return "auto";
		}
	}};
	border-bottom: ${({ isError, isSSo }: IpProps) =>
		isError && !isSSo ? "2px solid #c4314b" : ""};

	font-weight: ${({ fontweight }) => (fontweight ? fontweight : "")};

	:focus {
		outline: none !important;
		box-shadow: none;
		border: ${({ isSSo, isError }: IpProps) => {
			if (isSSo) {
				return isError ? "2px solid #c4314b" : "2px solid #6264a7";
			} else {
				return;
			}
		}};
		border-bottom: ${({ isError, isSSo }: IpProps) => {
			if (isSSo) {
				return;
			} else {
				return isError ? "2px solid #c4314b" : "2px solid #6264a7";
			}
		}};
	}

	:focus-visible {
		outline: 0;
	}
`;

const InputWrapper = styled.div`
	position: relative;
`;

interface InputProps {
	// label: Path<IFormValues>;
	label: any;
	register: any;
	errors: any;
	type?: string;
	required?: boolean;
	onChange?: Function;
	onKeyDown?: Function;
	hideLabel?: boolean;
	backgroundcolor?: string;
	placeholder?: string;
	labelcolor?: string;
	clearErrors?: any;
	border?: string;
	color?: string;
	min?: number;
	readOnly?: boolean;
	disabled?: boolean;
	height?: string;
	isSSo?: boolean;
	margin?: string;
	fontsize?: string;
	fontweight?: string;
	name?: string;
}

const Input = ({
	name,
	label,
	color,
	errors,
	border,
	register,
	required,
	onChange,
	disabled,
	onKeyDown,
	hideLabel,
	labelcolor,
	placeholder,
	clearErrors,
	type = "text",
	backgroundcolor,
	min,
	readOnly,
	height,
	isSSo,
	margin,
	fontsize,
	fontweight,
}: InputProps) => {
	const isError =
		errors[`${label}`]?.type === "required" ||
		errors[`${label}`]?.type === "manual" ||
		errors[`${label}`]?.type === "matches" ||
		errors[`${label}`]?.type === "email" ||
		errors[`${label}`]?.type === "oneOf";

	function handleKeyDown(e: any) {
		if (typeof onKeyDown === "function") {
			onKeyDown(e);
		} else return;
	}

	console.log("errors", errors);

	return (
		<div css={{ margin: margin ? margin : "" }}>
			<Label color={labelcolor} htmlFor={label} hideLabel={hideLabel}>
				{name ?? label}
			</Label>

			<InputWrapper>
				<InputText
					id={label}
					type={type}
					name={label}
					className="form-control"
					placeholder={placeholder ? placeholder : label}
					isError={isError}
					onKeyDown={handleKeyDown}
					backgroundcolor={backgroundcolor}
					color={color}
					border={border}
					disabled={disabled}
					min={min}
					readOnly={readOnly}
					height={height}
					isSSo={isSSo}
					fontsize={fontsize}
					fontweight={fontweight}
					{...register(label, {
						required,

						onChange: (e: any) => {
							if (clearErrors && typeof clearErrors === "function") {
								clearErrors(label);
							}
							if (typeof onChange === "function") {
								onChange(e);
							} else return;
						},
					})}
				/>
				{isError && (
					<BsExclamationCircle
						style={{
							position: "absolute",
							// top: "10px",
							top: "50%",
							transform: "translateY(-50%)",
							right: "15px",
							fontSize: "15px",
							fill: "red",
						}}
					/>
				)}
			</InputWrapper>
			{isError && <ErrorForm>{errors[`${label}`]?.message}</ErrorForm>}
		</div>
	);
};

export default Input;
