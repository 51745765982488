import React, { useMemo } from "react";

import { localStorageKeys, useLocalStorageState } from "hooks";

interface DomainContextParams {
  domain: string;
  setDomain: (state: string) => null;
  removeDomainFromLocalStorage: () => null;
}

const DomainContext = React.createContext<DomainContextParams | undefined>(
  undefined
);

DomainContext.displayName = "DomainContext";

function DomainProvider(props: any) {
  let [domain, setDomain, removeDomainFromLocalStorage] = useLocalStorageState({
    key: localStorageKeys.domain,
  });

  const value = useMemo(() => {
    return {
      domain,
      setDomain,
      removeDomainFromLocalStorage,
    };
  }, [domain, setDomain, removeDomainFromLocalStorage]);

  return <DomainContext.Provider value={value} {...props} />;
}

function useDomainContext() {
  const context = React.useContext(DomainContext);

  if (context === undefined) {
    throw new Error(`useDomainContext must be used within a DomainProvider`);
  }

  return context;
}

export { DomainProvider, useDomainContext };
