/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React from "react";

import { useHistory } from "react-router-dom";

import { Button } from "styles/components.styles";

import {
  ReachOut,
  ReachOutText,
  ReachOutHeader,
} from "components/contactSalesForm/contactSalesForm.styles";

const ContactSalesPopup = ({ error, formType }: any) => {
  let history = useHistory();

  function routeToDestination() {
    if (formType === "demoRequest") {
      history.push("/homeTab");
    } else if (formType === "talkToSales") {
      history.push("/getStartedTab");
    }
  }

  return (
    <ReachOut>
      <ReachOutHeader>
        {error ? "Demo submission failed!" : "Thanks for reaching out"}
      </ReachOutHeader>
      <ReachOutText>
        {error
          ? "Please try again later."
          : " We appreciate your time in telling us a little more about yourself. Someone from our sales team will be in touch shortly"}
      </ReachOutText>
      <Button
        css={{ marginLeft: "auto" }}
        variant="success"
        fontweight="600"
        onClick={routeToDestination}
      >
        Got it!
      </Button>
    </ReachOut>
  );
};

export default ContactSalesPopup;
