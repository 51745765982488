/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React, { useState, useEffect } from "react";

import {
  Wrapper,
  LeftArrow,
  PageDescription,
  RightArrow,
} from "./pagination.styles";

import { Button } from "styles/components.styles";

import LeftArrowIcon from "assets/images/common/arrow-left.svg";
import RightArrowIcon from "assets/images/common/arrow-right.svg";

interface PaginationProps {
  dataPerPage?: number;
  data: any;
  justifycontent?: string;
  setPaginatedData: any;
}

const Pagination = ({
  dataPerPage = 5,
  data,
  justifycontent,
  setPaginatedData,
}: PaginationProps) => {
  const [currentPageState, setCurrentPageState] = useState(1);
  const [dataPerPageState, setDataPerPageState] = useState(dataPerPage);
  const [totalPagesState, setTotalPagesState] = useState<any>();

  useEffect(() => {
    const indexOfLastTodo = currentPageState * dataPerPageState;
    const indexOfFirstTodo = indexOfLastTodo - dataPerPageState;

    const currentEvents = data.slice(indexOfFirstTodo, indexOfLastTodo);

    setPaginatedData(currentEvents);
  }, [currentPageState, data, dataPerPageState, setPaginatedData]);

  useEffect(() => {
    if (
      data.length / dataPerPageState >
      Math.round(data.length / dataPerPageState)
    ) {
      setTotalPagesState(Math.round(data.length / dataPerPageState) + 1);
    } else {
      setTotalPagesState(Math.round(data.length / dataPerPageState));
    }
  }, [data.length, dataPerPageState]);

  function showPreviousPage() {
    setCurrentPageState((prevState: any) => prevState - 1);
  }

  function showNextPage() {
    setCurrentPageState((prevState: any) => prevState + 1);
  }

  return (
    <Wrapper
      css={{ justifyContent: justifycontent ? justifycontent : "flex-start" }}
    >
      <Button
        variant="transparent"
        disabled={currentPageState === 1}
        padding="0"
        onClick={showPreviousPage}
      >
        <LeftArrow src={LeftArrowIcon} alt="left arrow" />
      </Button>
      <PageDescription>
        Showing {currentPageState} of {totalPagesState}
      </PageDescription>
      <Button
        variant="transparent"
        disabled={currentPageState === totalPagesState}
        padding="0"
        onClick={showNextPage}
      >
        <RightArrow src={RightArrowIcon} alt="left arrow" />
      </Button>
    </Wrapper>
  );
};

export default Pagination;
