/** @jsxRuntime classic */
/** @jsx jsx */
import { keyframes } from "@emotion/react";

import styled from "@emotion/styled/macro";

// Style imports
import * as colors from "styles/colors";

const Icon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 20px;
  margin-left: 10px;
`;

const popIn = keyframes`
from {
  opacity: 0;
  transform: translate(-50%, -50%) scale(1.5) ;
}
to {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1) ;
}
`;

const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
`;

const Label = styled.label`
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin: 0.6em 1em;
`;

const Indicator = styled.div`
  border: 1px solid;
  border-radius: 1em;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 6px;
  left: -1.5em;

  ${Label}:hover & {
    background: ${colors.gray};
  }

  &::after {
    content: "";
    position: absolute;
    display: none;
  }

  ${Input}:checked + &::after {
    display: block;
    border: solid ${colors.black};
    border-radius: 1em;
    background-color: ${colors.black};
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation-name: ${popIn};
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }

  ${Input}:disabled + & {
    pointer-events: none;
    opacity: 0.6;
    background: #e6e6e6;
  }
`;

export { Icon, popIn, Input, Label, Indicator };
