/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React, { Fragment, useState, useEffect } from "react";

// Style imports
import { Button, CustomHeightSpinner } from "styles/components.styles";

// Component imports
import { NoDataLayout } from "components";

import TopCourse from "./topCourse";
import CoursesCommonTable from "./coursesCommonTable";

// Image imports
import AllCoursesCompletedPortfolioImage from "assets/images/common/portfolio-img.png";
import AllCoursesCompletedMykademyImage from "assets/images/getStarted/courses-img.png";
// import { Link, useHistory } from "react-router-dom";
import { useDeeplinkRouting } from "hooks";

interface CoursesCommonWidgetProps {
	title: string;
	allCourses: any;
	isLoading: boolean;
	deeplinkHandler: any;
	showLearnerMarketPlace: boolean;
}

const CoursesCommonWidget = ({
	title,
	isLoading,
	allCourses,
	deeplinkHandler,
	showLearnerMarketPlace,
}: CoursesCommonWidgetProps) => {
	const [recentCourses, setRecentCourses] = useState([]);
	const [topCourse, setTopCourse] = useState<any | {}>({});
	const [allCoursesCompleted, setAllCoursesCompleted] = useState<
		boolean | null
	>(null);
	const [noCoursesEnrolled, setNoCoursesEnrolled] = useState<boolean | null>(
		null
	);

	const [src, setSrc] = useState("");
	const [allCoursesCompletedText, setAllCoursesCompletedText] = useState("");
	const [buttonName, setButtonName] = useState("");
	const { setDeeplinkParams } = useDeeplinkRouting();
	// const history = useHistory();

	function routeToMarketPlace() {
		setDeeplinkParams({
			entityId: "mycourses",
			routeName: "course-list",
			historyPush: false,
		});
		// history.push("/course-list");
		localStorage.setItem("fromCourseWidget", "yes");
	}

	useEffect(() => {
		if (allCourses) {
			let allCoursesInProgress = allCourses.filter(
				(course: any) => course.cs_percentage !== "100"
			);

			const allCoursesCount = allCourses.length;

			if (allCoursesCount !== 0 && allCoursesInProgress.length === 0) {
				setAllCoursesCompleted(true);
			}

			if (allCoursesCount === 0) {
				setNoCoursesEnrolled(true);
			} else {
				setNoCoursesEnrolled(false);
			}

			if (allCourses && allCoursesCount !== 0) {
				const allCoursesExceptFirst = allCoursesInProgress.slice(1);
				const firstFiveCourses = allCoursesExceptFirst.slice(0, 5);

				setTopCourse(allCoursesInProgress[0]);
				setRecentCourses(firstFiveCourses);
			}
		}
	}, [allCourses]);

	useEffect(() => {
		if (title === "Portfolio") {
			setSrc(AllCoursesCompletedPortfolioImage);
			setAllCoursesCompletedText(
				"Currently, you’re not having any programme In Progress"
			);

			setButtonName("View Full Portfolio");
		} else if (title === "Courses") {
			setSrc(AllCoursesCompletedMykademyImage);
			setAllCoursesCompletedText(
				"At the moment, you don’t have any Courses In Progress"
			);

			setButtonName("View All Courses");
		}
	}, [title]);

	// Render phase
	if (isLoading) {
		return <CustomHeightSpinner height="400px" />;
	} else if (allCoursesCompleted) {
		return (
			<NoDataLayout
				imgHeight="160px"
				imgSrc={src}
				text={allCoursesCompletedText}
			/>
		);
	} else if (noCoursesEnrolled) {
		return (
			<NoDataLayout imgHeight="160px" imgSrc={src}>
				<p>
					You are not enrolled in any courses.
					{showLearnerMarketPlace ? (
						<Fragment>
							<span> Please check our </span>
							<span
								onClick={() => routeToMarketPlace()}
								style={{ color: "blue", cursor: "pointer" }}
							>
								library of courses
							</span>
						</Fragment>
					) : (
						""
					)}
				</p>
			</NoDataLayout>
		);
	} else {
		return (
			<Fragment>
				<TopCourse
					title={title}
					topCourse={topCourse}
					viewAllCoursesDeeplink={deeplinkHandler}
				/>
				<CoursesCommonTable
					title={title}
					recentCourses={recentCourses}
					viewAllCoursesDeeplink={deeplinkHandler}
				/>
				<Button
					variant="lightPurpleText"
					hoverColor="#0a58ca"
					onClick={() => deeplinkHandler()}
					css={{ fontSize: "12px", marginTop: "25px" }}
				>
					{buttonName}
				</Button>
			</Fragment>
		);
	}
};

export default CoursesCommonWidget;
