const vleConfig = {
  index: {
    APP_ID: process.env.REACT_APP_TEAMS_APP_ID,
    APP_BASEURL: process.env.REACT_APP_BASEURL,
    ENITYID: "index",
    ENTITY_LABEL: "index",
    REACT_APP_IMAGE_PATHPREFIX: process.env.REACT_APP_IMAGE_PATHPREFIX,
  },
  mykademy: {
    API_URL: "https://dev.mykademy.com/",
    APP_TOKEN_CREATE: process.env.REACT_APP_MYKADEMY_APP_TOKEN_CREATE,
    APP_ID: process.env.REACT_APP_TEAMS_APP_ID,
    APP_BASEURL: process.env.REACT_APP_BASEURL,
    REACT_APP_MYKADEMY_SUBDOMAIN_VALIDATION_APIURL:
      process.env.REACT_APP_MYKADEMY_SUBDOMAIN_VALIDATION_APIURL,
    REACT_APP_MYKADEMY_SUBDOMAIN_VALIDATION_SECRET:
      process.env.REACT_APP_MYKADEMY_SUBDOMAIN_VALIDATION_SECRET,
  },
  myportfolio: {
    APP_BASEURL_IFRAME: process.env.REACT_APP_BASEURL_IFRAME_MYPORTFOLIO,
    APP_ID: process.env.REACT_APP_TEAMS_APP_ID,
    APP_BASEURL: process.env.REACT_APP_BASEURL,
    API_URL: process.env.REACT_APP_MYPORTFOLIO_API_URL,
    STATIC_CLIENT_SECRET:
      process.env.REACT_APP_MYPORTFOLIO_STATIC_CLIENT_SECRET,
    STATIC_TOKEN: process.env.REACT_APP_MYPORTFOLIO_STATIC_TOKEN,
    NEWSFEED_CLIENT_ID: process.env.REACT_APP_MYPORTFOLIO_NEWSFEED_CLIENT_ID,
    NEWSFEED_CLIENT_SECRET:
      process.env.REACT_APP_MYPORTFOLIO_NEWSFEED_CLIENT_SECRET,
    REACT_APP_NEWSFEED_API_URL: process.env.REACT_APP_NEWSFEED_API_URL,
    REACT_APP_PORTFOLIO_AUTH_STATIC_CLIENT_ID:
      process.env.REACT_APP_PORTFOLIO_AUTH_STATIC_CLIENT_ID,
    REACT_APP_PORTFOLIO_GRANT_TYPE: process.env.REACT_APP_PORTFOLIO_GRANT_TYPE,
    TENANTID: process.env.REACT_APP_TENANTID,
  },
  vle: {
    REACT_APP_TEAMS_AUTH_URL: process.env.REACT_APP_TEAMS_AUTH_URL,
    REACT_APP_TEAMS_API_URL: process.env.REACT_APP_TEAMS_API_URL,
  },
};

export { vleConfig };
