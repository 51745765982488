/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React, { Fragment, useState } from "react";

import { Spinner } from "styles/components.styles";

import OliveImage from "assets/images/brand/olive-group-icon.svg";

import {
  Iframe,
  Wrapper,
  OliveImg,
  AboutOlive,
  OliveTitle,
  SpinnerWrapper,
  CompanyVideoWrap,
  CompanyVideoInfo,
  PopupVideoWrapper,
} from "./popupVideo.styles";

interface PopupVideoProps {
  title: string;
  videoSrc: string;
  showLogo?: boolean;
}

function PopupVideo({ title, videoSrc, showLogo }: PopupVideoProps) {
  const [isLoading, setIsLoading] = useState(true);

  function hideSpinner() {
    setIsLoading(false);
  }

  return (
    <Fragment>
      <Wrapper isLoading={isLoading}>
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      </Wrapper>

      <PopupVideoWrapper isLoading={isLoading}>
        <CompanyVideoInfo>
          <AboutOlive>
            {showLogo ? (
              <OliveImg
                className="modal-logo"
                src={OliveImage}
                alt="olive image"
              />
            ) : null}

            <OliveTitle>{title}</OliveTitle>
          </AboutOlive>
        </CompanyVideoInfo>

        <CompanyVideoWrap>
          <Iframe
            src={videoSrc}
            onLoad={hideSpinner}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          />
        </CompanyVideoWrap>
      </PopupVideoWrapper>
    </Fragment>
  );
}

export default PopupVideo;
