/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React, { Fragment } from "react";

// Third party imports
import Cropper from "react-cropper";
import { UseFormRegister, FieldValues } from "react-hook-form";

// Style imports
import { InputFile } from "styles/form.styles";

import {
  DeleteImg,
  ReplaceImg,
  ReplaceWrap,
  DeleteButton,
  CropImgHolder,
  ModifySelectedImageWrap,
} from "components/imgCropper/imgCropper.styles";

// Image imports
import ImgArrowSwipe from "assets/images/register/arrow-swap.svg";
import ImgDeleteBlue from "assets/images/register/delete-blue.svg";

interface CropSelectorProps {
  image: string;
  register: UseFormRegister<FieldValues>;
  setCropper: any;
  onChangeHandler: Function;
  deleteSelectedImage: Function;
}

const CropSelector = ({
  image,
  register,
  setCropper,
  onChangeHandler,
  deleteSelectedImage,
}: CropSelectorProps) => {
  const { ref } = register("avatar");

  return (
    <Fragment>
      <CropImgHolder image={image}>
        <Cropper
          style={{ height: 300, width: "100%" }}
          src={image}
          zoomTo={0}
          initialAspectRatio={1}
          preview=".img-preview"
          viewMode={1}
          guides={true}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false}
          onInitialized={(instance) => {
            setCropper(instance);
          }}
        />
      </CropImgHolder>
      <ModifySelectedImageWrap image={image}>
        <ReplaceWrap>
          <ReplaceImg src={ImgArrowSwipe} alt="replace image" />
          Replace
          <InputFile
            type="file"
            accept="image/png, image/jpg, image/jpeg"
            ref={ref}
            name="avatar"
            onChange={(e) => onChangeHandler(e)}
            onClick={(event: any) => {
              event.target.value = null;
            }}
          />
        </ReplaceWrap>
        <DeleteButton onClick={() => deleteSelectedImage()}>
          <DeleteImg src={ImgDeleteBlue} alt="delete image" />
          Delete
        </DeleteButton>
      </ModifySelectedImageWrap>
    </Fragment>
  );
};

export default CropSelector;
