/** @jsxRuntime classic */
/** @jsx jsx */

// Third party imports
import styled from "@emotion/styled/macro";

interface DoughnutWrapperProps {
  chartType?: string;
}

const DoughnutWrapper = styled.div<DoughnutWrapperProps>`
  width: 100%;
  position: relative;

  canvas {
    max-height: ${(chartType: any) => {
      if (chartType.chartType === "mykademyAdmin") {
        return "300px";
      } else {
        return "200px";
      }
    }};
  }

  canvas#race {
    position: relative;
    z-index: 99999;
  }
`;

export { DoughnutWrapper };
