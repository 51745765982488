/** @jsxRuntime classic */
/** @jsx jsx */

// Third party imports
import styled from "@emotion/styled/macro";

const Title = styled.h4`
  text-transform: capitalize;
`;

const Text = styled.div`
  font-size: 12px;
`;

export { Title, Text };
