/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import styled from "@emotion/styled/macro";

import { Fragment, useState } from "react";
import { Link } from "react-router-dom";

// Image imports
import ArrowRightIcon from "assets/images/common/arrow-right-blue.svg";
import ThumbnailsImg from "assets/images/getStarted/thumbnail_04.png";
import { TickCircleIcon } from "components/svg";

// Component imports
import { Button } from "styles/components.styles";

const AddLearningBox = styled.div`
	display: flex;
	align-items: start;
	gap: 10px;
	margin-bottom: 24px;
	padding: 16px;
	background: #fff;
	border: 1px solid #bcc3d1;
	border-radius: 6px;
`;

const AddLearningWrap = styled.div`
	display: flex;
	align-items: start;
	gap: 16px;
	flex: 1;
`;

const AddLearningImgWrap = styled.div`
	width: 72px;
	height: 41px;
	border-radius: 4px;
	overflow: hidden;
	aspect-ratio: 1;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`;

const AddLearningInfo = styled.div`
	flex: 1;
	a {
		display: inline-flex;
		align-items: center;
		gap: 8px;
		font-size: 10px;
		font-weight: bold;
		color: #6f75bc;
		img {
			width: 7px;
			height: 7px;
		}
	}
`;

const AddLearningHeading = styled.h4`
	font-size: 12px;
	font-weight: bold;
	color: #1c1d24;
`;

const CircleCheck = styled.div`
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 23px;
	height: 23px;
	font-size: 11px;
	color: #13171f;
	border-radius: 50%;
	svg {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`;

const AddLearningInfoContent = () => {
	const [addContent, setAdd] = useState<boolean>(false);
	return (
		<Fragment>
			<AddLearningBox>
				<AddLearningWrap>
					<AddLearningImgWrap>
						<img src={ThumbnailsImg} alt="thumbnails Img" />
					</AddLearningImgWrap>
					<AddLearningInfo>
						<AddLearningHeading>
							Data Protection UK (GDPR Staff Awareness)
						</AddLearningHeading>
						<Link to="/">
							See Details
							<img src={ArrowRightIcon} alt="arrow icon" />
						</Link>
					</AddLearningInfo>
				</AddLearningWrap>
				{addContent ? (
					<CircleCheck>
						<TickCircleIcon color="#454B93" />
					</CircleCheck>
				) : (
					<Button
						variant="chineseBlue"
						fontweight="600"
						css={{
							fontSize: "12px",
							paddingTop: "10px",
							paddingRight: "20px",
							paddingBottom: "10px",
							paddingLeft: "20px",
							borderRadius: "6px",
						}}
						type="button"
						onClick={() => setAdd((addContent) => !addContent)}
					>
						Add
					</Button>
				)}
			</AddLearningBox>
		</Fragment>
	);
};

export default AddLearningInfoContent;
