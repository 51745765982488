/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React, { Fragment } from "react";

// Third party imports
import parse from "html-react-parser";
import { Row, Col } from "react-bootstrap";

// Style imports
import { Button } from "styles/components.styles";
import {
  AddImg,
  BtnText,
  ImgWrap,
  ContentImgLg,
  ContentPrice,
  CategoryWrap,
  ContentDetail,
  ContentBtnWrap,
  TotalLecturesImg,
  ContentDetailWrap,
  ContentDetailTitle,
  ContentPricingWrap,
  ContentTotalLectures,
  ContentDetailInfoWrap,
} from "styles/courses.styles";

// Context imports
import { useModalContext } from "components/modal/modal";

// Image imports
import AddIcon from "assets/images/common/add-icon.svg";
import TickIcon from "assets/images/common/tick-icon.svg";
import CategoryImg from "assets/images/getStarted/category.svg";
import TotalLecturesIcon from "assets/images/common/total-lectures.svg";

import { useGetRecentCoursesMarketplace } from "hooks";

// Interface imports
import {
  SingleCourse,
  SingleCourseAdmin,
} from "ts/interfaces/courses.interface";
import ActionButton from "screens/coursesTab/marketPlaceCourseButton/actionButton";

interface AddContentDescriptionProps<T> {
  course: T;
  isMarketPlace?: boolean;
  showDetailText?: boolean;
  addCourse?: any;
  isSelected?: boolean;
  removeCourse?: any;
}

const AddContentDescription = <T extends SingleCourse | SingleCourseAdmin>({
  course,
  isMarketPlace,
  showDetailText,
}: AddContentDescriptionProps<T>) => {
  const { setIsOpen } = useModalContext();

  const { coursesCategories } = useGetRecentCoursesMarketplace();

  const showCategories = (course: any) => {
    if (course.category) {
      let categoryName = "";

      coursesCategories.map((category: any) => {
        let splitted = course.category.split(",");
        if (splitted.includes(category.id)) {
          if (categoryName) {
            categoryName = `${categoryName},${category.ct_name}`;
          } else categoryName = category.ct_name;
        }

        return null;
      });

      return categoryName;
    }
  };

  function checkImageSrc() {
    if ("image" in course) {
      return course.image;
    } else if ("cb_image" in course) {
      return course.cb_image;
    } else {
      return "";
    }
  }

  function checkTitle() {
    if ("title" in course) {
      return course.title;
    } else if ("cb_title" in course) {
      return course.cb_title;
    } else {
      return "";
    }
  }

  function checkDescription() {
    if ("description" in course) {
      return course.description;
    } else if ("cb_description" in course) {
      return course.cb_description;
    } else {
      return "";
    }
  }

  function checkPrice() {
    if ("price" in course) {
      return `€${course.price}`;
    } else if ("cb_price" in course) {
      return `€${course.cb_price}`;
    } else {
      return "";
    }
  }

  return (
    <Row>
      <Col lg={6}>
        <ImgWrap>
          <ContentImgLg src={checkImageSrc()} alt={checkTitle()} />
        </ImgWrap>
      </Col>
      <Col lg={6}>
        <ContentDetailWrap>
          <ContentDetailTitle>{checkTitle()}</ContentDetailTitle>

          <ContentDetailInfoWrap>
            <ContentDetail>{parse(checkDescription())}</ContentDetail>
          </ContentDetailInfoWrap>

          {isMarketPlace ? (
            <CategoryWrap>
              <img src={CategoryImg} alt="category" />
              <span>{showCategories(course)}</span>
            </CategoryWrap>
          ) : null}
          {isMarketPlace ? (
            <ContentPrice>
              {course.is_free === "1" ? "Free" : checkPrice()}
            </ContentPrice>
          ) : null}
          <ContentPricingWrap>
            <ContentTotalLectures>
              <TotalLecturesImg src={TotalLecturesIcon} alt="total lectures" />
              {course.total_lectures} Total Lectures
            </ContentTotalLectures>
          </ContentPricingWrap>

          <ContentBtnWrap>
            <ActionButton course={course} showDetailText={showDetailText} />

            <Button
              variant="dark"
              fontweight="600"
              css={{ marginLeft: "8px" }}
              onClick={() => setIsOpen(false)}
            >
              Close
            </Button>
          </ContentBtnWrap>
        </ContentDetailWrap>
      </Col>
    </Row>
  );
};

export default AddContentDescription;
