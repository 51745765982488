import React, { useState } from "react";

const FilterStateContext = React.createContext<string | undefined>(undefined);

const VcTokenValidStateContext = React.createContext<boolean | undefined>(
	undefined
);

const FilterUpdaterContext = React.createContext<any | undefined>(undefined);

const VcTokenValidUpdaterContext = React.createContext<any | undefined>(
	undefined
);

FilterStateContext.displayName = "FilterStateContext";
VcTokenValidStateContext.displayName = "VcTokenValidStateContext";

FilterUpdaterContext.displayName = "FilterUpdaterContext";
VcTokenValidUpdaterContext.displayName = "VcTokenValidUpdaterContext";

function SsoProvider(props: any) {
	const [filterValue, setFilterValue] = useState("");

	const [vcTokenIsValid, setVcTokenIsValid] = useState(false);

	return (
		<FilterStateContext.Provider value={filterValue}>
			<FilterUpdaterContext.Provider value={setFilterValue}>
				<VcTokenValidStateContext.Provider value={vcTokenIsValid}>
					<VcTokenValidUpdaterContext.Provider value={setVcTokenIsValid}>
						{props.children}
					</VcTokenValidUpdaterContext.Provider>
				</VcTokenValidStateContext.Provider>
			</FilterUpdaterContext.Provider>
		</FilterStateContext.Provider>
	);
}

function useSsoFilterStateContext() {
	const filterStateSso = React.useContext(FilterStateContext);

	if (filterStateSso === undefined) {
		throw new Error(
			`useSsoFilterStateContext must be used within a SsoProvider`
		);
	}

	return filterStateSso;
}

function useSsoFilterUpdaterContext() {
	const setFilterValueSso = React.useContext(FilterUpdaterContext);

	if (setFilterValueSso === undefined) {
		throw new Error(
			`useSsoFilterUpdaterContext must be used within a SsoProvider`
		);
	}

	return setFilterValueSso;
}

function useVcTokenValidStateContext() {
	const vcTokenIsValid = React.useContext(VcTokenValidStateContext);

	if (vcTokenIsValid === undefined) {
		throw new Error(
			`useVcTokenValidStateContext must be used within a SsoProvider`
		);
	}

	return vcTokenIsValid;
}

function useVcTokenValidUpdaterContext() {
	const setVcTokenIsValid = React.useContext(VcTokenValidUpdaterContext);

	if (setVcTokenIsValid === undefined) {
		throw new Error(
			`useVcTokenValidUpdaterContext must be used within a SsoProvider`
		);
	}

	return setVcTokenIsValid;
}

export {
	SsoProvider,
	useSsoFilterStateContext,
	useSsoFilterUpdaterContext,
	useVcTokenValidStateContext,
	useVcTokenValidUpdaterContext,
};
