/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React, { Fragment, useEffect } from "react";

//Hook imports
import { useAddOrRemoveCourse } from "hooks";

//Context imports
import { useCartStateContext, useMarketPlaceFilterContext } from "context";

//Style imports
import { Button } from "styles/components.styles";
import { AddImg, BtnText } from "styles/courses.styles";

// Image imports
import AddIcon from "assets/images/common/add-icon.svg";
import TickIcon from "assets/images/common/tick-icon.svg";

const ActionButton = ({ course, showDetailText, enablePayment }: any) => {
  const { addCourse, removeCourse, isSelected, setCurrentCourse } =
    useAddOrRemoveCourse();
  const { courseProviderValue } = useMarketPlaceFilterContext();

  const { addToCart, removeFromCart, carts } = useCartStateContext();
  const isAddedToCart = carts?.find((cart: any) => cart.id === course?.id);

  useEffect(() => {
    setCurrentCourse(course);
  }, [course, setCurrentCourse]);

  return (
    <Button
      variant={
        enablePayment
          ? course?.is_free === "1"
            ? isSelected
              ? "green"
              : "purple"
            : !!isAddedToCart
            ? "green"
            : "purple"
          : isSelected
          ? "green"
          : "purple"
      }
      fontweight="600"
      padding={showDetailText ? "" : "5px 10px"}
      onClick={() => {
        if (enablePayment) {
          if (course?.is_free === "1") {
            if (isSelected) {
              removeCourse({ course });
            } else {
              addCourse({ course });
            }
          } else {
            if (isAddedToCart) {
              removeFromCart(course?.id);
            } else {
              addToCart({ ...course, courseProvider: courseProviderValue });
            }
          }
        } else {
          if (isSelected) {
            removeCourse({ course });
          } else {
            addCourse({ course });
          }
        }
      }}
    >
      {enablePayment ? (
        course?.is_free === "1" ? (
          isSelected ? (
            <AddImg src={TickIcon} alt="remove icon" />
          ) : (
            <Fragment>
              <AddImg src={AddIcon} alt="add icon" />
              <BtnText>Add</BtnText>
            </Fragment>
          )
        ) : !!isAddedToCart ? (
          <Fragment>
            <AddImg src={TickIcon} alt="remove icon" />
            <BtnText>In Cart</BtnText>
          </Fragment>
        ) : (
          <Fragment>
            <AddImg src={AddIcon} alt="add icon" />
            <BtnText>Add To Cart</BtnText>
          </Fragment>
        )
      ) : isSelected ? (
        <AddImg src={TickIcon} alt="remove icon" />
      ) : (
        <Fragment>
          <AddImg src={AddIcon} alt="add icon" />
          <BtnText>Add</BtnText>
        </Fragment>
      )}
    </Button>
  );
};

export default ActionButton;
