import React, { useState, useMemo } from "react";

interface PurchaseHistoryFilterContextParams {
  paymentMethod: any;
  setPaymentMethod: (state: any) => void;
  dateRange: string | null;
  setDateRange: React.Dispatch<React.SetStateAction<null[]>>;
  paymentStatus: any;
  setPaymentStatus: React.Dispatch<React.SetStateAction<any>>;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  historyType: string;
  setHistoryType: React.Dispatch<React.SetStateAction<string>>;
}

const PurchaseHistoryFilterContext = React.createContext<
  PurchaseHistoryFilterContextParams | undefined
>(undefined);

PurchaseHistoryFilterContext.displayName = "PurchaseHistoryFilterContext";

function PurchaseHistoryFilterProvider(props: any) {
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [paymentMethod, setPaymentMethod] = useState<any>(null);
  const [paymentStatus, setPaymentStatus] = useState<any>(null);
  const [search, setSearch] = useState<string>("");
  const [historyType, setHistoryType] = useState<string>("");
  const value = useMemo(() => {
    return {
      search,
      dateRange,
      setSearch,
      historyType,
      setDateRange,
      paymentMethod,
      paymentStatus,
      setHistoryType,
      setPaymentMethod,
      setPaymentStatus,
    };
  }, [dateRange, historyType, paymentMethod, paymentStatus, search]);

  return <PurchaseHistoryFilterContext.Provider value={value} {...props} />;
}

function usePurchaseHistoryFilterContext() {
  const context = React.useContext(PurchaseHistoryFilterContext);

  if (context === undefined) {
    throw new Error(
      `usePurchaseHistoryFilterContext must be used within a PurchaseHistoryFilterProvider`
    );
  }

  return context;
}

export { PurchaseHistoryFilterProvider, usePurchaseHistoryFilterContext };
