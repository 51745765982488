let profileWithGetStarted = [
  {
    title: "Help Resources",
    icon: "ProfileIcon",
  },
  {
    title: "Get Started",
    icon: "ProfileIcon",
    route: "/getStartedTab",
  },
];

let profileWithoutGetStarted = [
  {
    title: "Help Resources",
    icon: "ProfileIcon",
  },
];

export { profileWithGetStarted, profileWithoutGetStarted };
