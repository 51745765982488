import { useState, useEffect } from "react";

// Third party imports
import { useQuery } from "react-query";

// Util imports
import { client } from "utils/api-client";
import { Notification } from "utils/notification";

// Context imports
import { useMykademyDataContext, useVleTokenContext } from "context";
import { useAuthorizeLearnerPathway } from "hooks/useAuthorizeLearnerPathway";

function useGetPathwayDetails({ pathwayId }: { pathwayId: string }) {
	const [pathway, setPathway] = useState([]);

	const { vleToken } = useVleTokenContext();
	const {
		mykademyUserData: {
			userData: { mk_member_id },
		},
	} = useMykademyDataContext();

	const { pathwayToken, isLoading: isAuthorizing } =
		useAuthorizeLearnerPathway();

	const getPathwayDetails = () => {
		return client({
			method: "get",
			endpoint: "api/learning-pathway/enrolled-courses",
			optional: {
				token: vleToken,
				params: {
					token: pathwayToken,
					learner: mk_member_id,
					pathway: pathwayId,
				},
			},
		});
	};

	const { data, error, isSuccess, isLoading, isError } = useQuery(
		["pathwayDetails", { pathwayId }],
		getPathwayDetails,
		{
			refetchOnWindowFocus: false,
			enabled: !!pathwayToken && !!pathwayId,
			select: (data) => {
				return data?.data?.data?.map((item: any) => ({
					id: item?._id,
					title: item.title,
					pathway_progress: item.pathway_progress,
					// eslint-disable-next-line array-callback-return
					courses: item.components?.map((course: any) => {
						if (
							course &&
							course.metadata &&
							course.metadata.course &&
							course.skip === false
						) {
							course.metadata.course["enable_component"] =
								course.enable_component;
							course.metadata.course["recent_course"] = course.recent_course;

							return {
								...course.metadata,
								metadataId: course?._id,
								recent_course: course?.recent_course,
							};
						}
					}),
					recent_course: item.recent_course,
					recent_components: item.recent_components,
				}));
			},
		}
	);

	useEffect(() => {
		if (isSuccess) {
			setPathway(data[0]);
		}
	}, [data, isSuccess]);

	useEffect(() => {
		if (isError) {
			console.log({ error });

			Notification({
				message: "Unable to get pathway details, please try again later",
				type: "warning",
				title: "Warning",
			});
		}
	}, [error, isError]);

	return {
		isLoading: isLoading || isAuthorizing,
		pathway,
	};
}

export { useGetPathwayDetails };
