import { useState, useEffect } from "react";

import { client } from "utils/api-client";

import { useQuery, useQueryClient } from "react-query";

import { Notification } from "utils/notification";

import {
  useVleTokenContext,
  useCourseLibraryContext,
  useMarketPlaceFilterContext,
  useCoursesFilterContext,
} from "context";
import { useGetOrgData } from "hooks/useGetOrgData";

function useGetCourseLibrary({ learner }: { learner: boolean }) {
  const { setCourseLibraryData, setTotalPages } = useCourseLibraryContext();

  const { filterValue, filterBy, courseProviderValue } =
    useMarketPlaceFilterContext();

  const [coursesCategories, setCoursesCategories] = useState([]);
  const [pageLimit, setPageLimit] = useState(8);
  const [pageNumber, setPageNumber] = useState(1);

  const { vleToken } = useVleTokenContext();
  const { isFree } = useCoursesFilterContext();
  const { enablePayment } = useGetOrgData();

  const queryClient = useQueryClient();

  const getCourseLibrary = ({ pageNumber, filterBy, filterValue }: any) => {
    // if (courseProviderValue) {
    return client({
      method: "get",
      endpoint: learner
        ? "api/akademy/getLearnerCategories"
        : "api/course-provider/courses",
      optional: {
        token: vleToken,
        params: {
          orderby_item: "total_enrolled_users",
          orderby_direction: "DESC",
          show_b2b_enabled_only: 1,
          search_by: filterBy,
          app: "ms_teams",
          page: pageNumber,
          page_limit: pageLimit,
          status: 1,
          search_keyword: filterValue,
          account_id: courseProviderValue?.split(" ")[1],
          is_free: enablePayment ? isFree : "",
        },
      },
    });
    // } else {
    // 	return null;
    // }
  };

  const { data, isLoading, isSuccess, isError } = useQuery(
    [
      "courseLibrary",
      { pageNumber, filterBy, filterValue, courseProviderValue, isFree },
    ],
    () =>
      getCourseLibrary({
        pageNumber,
        filterBy,
        filterValue,
        courseProviderValue,
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    }
  );

  useEffect(() => {
    if (isSuccess && data) {
      if (learner) {
        const allCategories: [] = data?.data?.data;
        setCoursesCategories(allCategories);
      } else {
        const allCourseLibrary: [] = data?.data.data.courses;

        let allCategories = data?.data.data.categories;
        let coursesCount = data?.data.data.courses_count;

        const totalPages = Math.ceil(coursesCount / pageLimit);

        setTotalPages(totalPages);

        setCourseLibraryData(allCourseLibrary);

        setCoursesCategories(allCategories);
      }
    }
  }, [
    data,
    isSuccess,
    pageLimit,
    queryClient,
    setTotalPages,
    setCourseLibraryData,
    learner,
  ]);

  useEffect(() => {
    if (isError) {
      Notification({
        message: "Unable to get course library, please try again later",
        type: "warning",
        title: "Warning",
      });
    }
  }, [isError]);

  return {
    isLoading,
    pageNumber,
    setPageNumber,
    coursesCategories,
    setCoursesCategories,
  };
}

export { useGetCourseLibrary };
