import { OnboardingIcon } from "screens/register/register.styles";

const CreateAddLearningIcon = () => {
  return (
    <OnboardingIcon
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.12521 5.18369C6.62521 4.8948 6 5.25563 6 5.83309V10.1668C6 10.7443 6.62522 11.1051 7.12521 10.8162L11.2501 8.43289C11.4048 8.34355 11.5 8.17854 11.5 7.99996C11.5 7.82139 11.4048 7.65637 11.2501 7.56703L7.12521 5.18369ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8ZM8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1Z"></path>
    </OnboardingIcon>
  );
};

export default CreateAddLearningIcon;
