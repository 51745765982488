/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React, { Fragment } from "react";

import { useLogout, useGetOrgData, useDeeplinkRouting } from "hooks";

import { Ul, Li, Image } from "./sidebarBottomNav.styles";
import { Button, FullPageSpinner } from "styles/components.styles";

import LogoutIcon from "assets/images/common/logout.svg";
import HomeIcon from "assets/images/marketplace/home.svg";

const SidebarBottomNav = ({ url, hideSidebar }: any) => {
	const { setLogout, logoutLoader } = useLogout();

	const { setDeeplinkParams } = useDeeplinkRouting();

	const { showLoginPage } = useGetOrgData();

	function routeToDashboard() {
		setDeeplinkParams({
			entityId: "index",
			routeName: "dashboard",
			historyPush: false,
		});
	}

	return (
		<Fragment>
			{logoutLoader ? (
				<FullPageSpinner />
			) : (
				<Ul hideSidebar={hideSidebar}>
					<Li css={{ flex: 1, padding: "15px" }}>
						<Button
							variant="transparent"
							padding="0"
							onClick={() => routeToDashboard()}
						>
							<Image className="logoutImgstyle" src={HomeIcon} alt="home" />
							Home
						</Button>
					</Li>
					{showLoginPage === false ? null : (
						<Li css={{ flex: 2, padding: "15px" }}>
							<Button
								variant="transparent"
								padding="0"
								onClick={() => setLogout(true)}
							>
								<Image src={LogoutIcon} alt="Sign Out" />
								Sign Out
							</Button>
						</Li>
					)}
				</Ul>
			)}
		</Fragment>
	);
};

export default SidebarBottomNav;
