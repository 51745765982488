import { useState, useEffect } from "react";
import * as microsoftTeams from "@microsoft/teams-js";

import { vleConfig } from "utils/vleConfig";

import { useHistory } from "react-router-dom";

interface DeeplinkProps {
  entityId: string;
  routeName: string;
  historyPush?: boolean;
  pageRefresh?: boolean;
}

function useDeeplinkRouting() {
  const [deeplinkParams, setDeeplinkParams] = useState<DeeplinkProps>({
    entityId: "",
    routeName: "",
    historyPush: false,
    pageRefresh: false,
  });

  const [routingComplete, setRoutingComplete] = useState(false);

  const history = useHistory();

  useEffect(() => {
    const { entityId, routeName, historyPush, pageRefresh } = deeplinkParams;

    if (entityId && routeName) {
      const entityLabel = entityId;
      const appid = vleConfig.index.APP_ID;
      const baseUrl = vleConfig.index.APP_BASEURL;

      console.log({ appid, baseUrl, historyPush });

      let encodedContext = encodeURI(`{"subEntityId": ${entityLabel}}`);

      let encodedWebUrl = encodeURI(`${baseUrl}${routeName}`);

      microsoftTeams.executeDeepLink(
        `https://teams.microsoft.com/l/entity/${appid}/${entityId}?webUrl=${encodedWebUrl}&context=${encodedContext}`
      );

      if (historyPush) {
        history.push(`/${routeName}`);
      }

      if (pageRefresh) {
        // refresh the page for them
        // @ts-ignore
        window.location.assign(window.location);
      }

      setRoutingComplete(true);
    }

    return () => setRoutingComplete(false);
  }, [history, deeplinkParams]);

  return { deeplinkParams, setDeeplinkParams, routingComplete };
}

export { useDeeplinkRouting };
