import React, { useState } from "react";

const PathwayContext = React.createContext<any | undefined>(undefined);

const PathwayUpdateContext = React.createContext<any | undefined>(undefined);

function PathwayContextProvider(props: any) {
  const [pathway, setPathway] = useState(null);

  return (
    <PathwayContext.Provider value={pathway}>
      <PathwayUpdateContext.Provider value={setPathway}>
        {props.children}
      </PathwayUpdateContext.Provider>
    </PathwayContext.Provider>
  );
}

function useGetPathwayContext() {
  const pathway = React.useContext(PathwayContext);

  if (pathway === undefined) {
    throw new Error(
      `useGetPathwayContext must be used within a PathwayContextProvider`
    );
  }

  return pathway;
}

function usePathwayUpdateContext() {
  const setPathway = React.useContext(PathwayUpdateContext);

  if (setPathway === undefined) {
    throw new Error(
      `usePathwayUpdateContext must be used within a PathwayContextProvider`
    );
  }

  return setPathway;
}

export {
  PathwayContextProvider,
  useGetPathwayContext,
  usePathwayUpdateContext,
};
