/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from "@emotion/react";

import React from "react";

import { Container } from "react-bootstrap";

import { Button } from "styles/components.styles";

import {
  FooterFixed,
  ContinueIcon,
  ContinueDescription,
} from "screens/onboarding/onboarding.styles";

import ArrowRightWhiteIcon from "assets/images/common/arrow-right-white.svg";

const OnboardingFooter = ({ addCoursesToAdmin }: any) => {
  return (
    <FooterFixed>
      <Container css={{ display: "flex", alignItems: "center" }}>
        <Button
          variant="purple"
          fontweight="600"
          borderradius="2px"
          padding="6px 16px 8px"
          onClick={() => addCoursesToAdmin()}
        >
          Continue
          <ContinueIcon src={ArrowRightWhiteIcon} />
        </Button>
        <ContinueDescription>
          You can add additional content if you wish.
        </ContinueDescription>
      </Container>
    </FooterFixed>
  );
};

export default OnboardingFooter;
