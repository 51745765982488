import React, { useState, useMemo } from "react";

interface SelectedOptionParams {
  selectedOption: {};
  setSelectedOption: (state: boolean) => null;
}

const SelectedOptionContext = React.createContext<
  SelectedOptionParams | undefined
>(undefined);

SelectedOptionContext.displayName = "SelectedOptionContext";

function SelectedOptionProvider(props: any) {
  const [selectedOption, setSelectedOption] = useState({});

  const value = useMemo(() => {
    return { selectedOption, setSelectedOption };
  }, [selectedOption]);

  return <SelectedOptionContext.Provider value={value} {...props} />;
}

function useSelectedOptionContext() {
  const context = React.useContext(SelectedOptionContext);

  if (context === undefined) {
    throw new Error(
      `useSelectedOptionContext must be used within a SelectedOptionProvider`
    );
  }

  return context;
}

export { SelectedOptionProvider, useSelectedOptionContext };
