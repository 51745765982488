import React, { useState } from "react";

interface LearnerFilterParams {
	isFree: string;
	setFilterValue: (state: string) => null;
}

const LearnerCourseFilterContext = React.createContext<
	LearnerFilterParams | undefined
>(undefined);

function LearnerCourseFilterContextProvider(props: any) {
	const [filterValue, setFilterValue] = useState("");

	const contextValue = {
		isFree: filterValue,
		setFilterValue,
	};
	return (
		<LearnerCourseFilterContext.Provider value={contextValue} {...props} />
	);
}

function useLearnerCoursesFilterContext() {
	const context = React.useContext(LearnerCourseFilterContext);

	if (context === undefined) {
		throw new Error(
			`useLearnerCoursesFilterContext must be used within a LearnerCourseFilterContextProvider`
		);
	}

	return context;
}

export { useLearnerCoursesFilterContext, LearnerCourseFilterContextProvider };
