import React, { useState } from "react";

// interface CertificateParams {
//   certificate: string;
//   setCertificate: (state: any) => null;
// }

const CertificateContext = React.createContext<any | undefined>(undefined);

const CertificateUpdateContext = React.createContext<any | undefined>(
	undefined
);

function CertificateContextProvider(props: any) {
	const [certificate, setCertificate] = useState(null);

	return (
		<CertificateContext.Provider value={certificate}>
			<CertificateUpdateContext.Provider value={setCertificate}>
				{props.children}
			</CertificateUpdateContext.Provider>
		</CertificateContext.Provider>
	);
}

function useCertificateContext() {
	const certificateState = React.useContext(CertificateContext);

	if (certificateState === undefined) {
		throw new Error(
			`useCertificateContext must be used within a CertificateContextProvider`
		);
	}

	return certificateState;
}

function useCertificateUpdateContext() {
	const setCertificate = React.useContext(CertificateUpdateContext);

	if (setCertificate === undefined) {
		throw new Error(
			`useCertificateUpdateContext must be used within a CertificateContextProvider`
		);
	}

	return setCertificate;
}

export {
	CertificateContextProvider,
	useCertificateContext,
	useCertificateUpdateContext,
};
