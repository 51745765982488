import { useState, useEffect } from "react";

// Third party imports
import { useQuery } from "react-query";

// Util imports
import { client } from "utils/api-client";
import { Notification } from "utils/notification";

// Context imports
import { useMykademyDataContext, useVleTokenContext } from "context";
import { useAuthorizeLearnerPathway } from "hooks/useAuthorizeLearnerPathway";

function useGetRecentPathways() {
  const [allPathways, setAllPathways] = useState([]);
  const [firstThreePathways, setFirstThreePathways] = useState([]);
  const [
    firstThreePathwaysAccToCompletion,
    setFirstThreePathwaysAccToCompletion,
  ] = useState([]);

  const { vleToken } = useVleTokenContext();
  const {
    mykademyUserData: {
      userData: { mk_member_id },
    },
  } = useMykademyDataContext();

  const { pathwayToken, isLoading: isAuthorizing } =
    useAuthorizeLearnerPathway();

  const getRecentPathways = () => {
    return client({
      method: "get",
      endpoint: "api/learning-pathway/enrolled-courses",
      optional: {
        token: vleToken,
        params: {
          token: pathwayToken,
          learner: mk_member_id,
        },
      },
    });
  };

  const { data, error, isSuccess, isLoading, isError } = useQuery(
    "recentPathways",
    getRecentPathways,
    {
      refetchOnWindowFocus: false,
      enabled: !!pathwayToken,
      select: (data) => {
        return data?.data?.data?.map((item: any) => ({
          id: item?._id,
          title: item.title,
          pathway_progress: item.pathway_progress,
          courses: item.components?.map(
            (course: any) => course?.metadata?.course
          ),
        }));
      },
    }
  );

  useEffect(() => {
    if (isSuccess) {
      const pathwaysAccordingToCompletion = data.filter(
        (pathway: any) =>
          pathway.pathway_progress !== "100" && pathway.pathway_progress !== "0"
      );

      const firstThreePathwaysAccToCompletion =
        pathwaysAccordingToCompletion.slice(0, 3);

      const firstThreePathways = data.slice(0, 3);

      setAllPathways(data);
      setFirstThreePathways(firstThreePathways);
      setFirstThreePathwaysAccToCompletion(firstThreePathwaysAccToCompletion);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (isError) {
      console.log({ error });

      Notification({
        message: "Unable to get recent pathways, please try again later",
        type: "warning",
        title: "Warning",
      });
    }
  }, [error, isError]);

  return {
    isLoading: isLoading || isAuthorizing,
    allPathways,
    firstThreePathways,
    firstThreePathwaysAccToCompletion,
  };
}

export { useGetRecentPathways };
