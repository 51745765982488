/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { FormFloating } from "styles/form.styles";

// Icon imports
import ShowPassIcon from "assets/images/common/show-pass-icon.svg";
import HidePassIcon from "assets/images/common/hide-pass-icon.svg";
import LockIocn from "assets/images/common/lock.svg";
import CheckIocn from "assets/images/common/check-icon.svg";

import {
	PasswordWrap,
	PassValidationBlock,
	ShowHidePassImage,
	PassHeader,
	PassImageWrap,
	PassText,
	PassBody,
	PassList,
	LineThrough,
} from "./passwordInput.styles";

const PasswordInput = () => {
	const [isPasswordFocus, setIsPasswordFocus] = useState<boolean>(false);
	const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false);
	return (
		<PasswordWrap>
			<FormFloating className={isPasswordFocus ? "active" : ""}>
				<FloatingLabel
					controlId="floatingInput"
					label="Password"
					className="mb-3"
					onFocus={() => setIsPasswordFocus(true)}
					onBlur={() => setIsPasswordFocus(false)}
					// value={state.password}
					//onChange={(event) => state.handlePasswordChange(event)}
				>
					<Form.Control
						type={isPasswordShown ? "text" : "password"}
						placeholder="Password"
					/>
					<ShowHidePassImage
						src={isPasswordShown ? ShowPassIcon : HidePassIcon}
						alt="icon"
						onClick={() => setIsPasswordShown((prevState) => !prevState)}
					/>
				</FloatingLabel>
				{isPasswordFocus && (
					<PassValidationBlock>
						<PassHeader>
							<PassImageWrap>
								<img src={LockIocn} alt="lock icon" />
							</PassImageWrap>
							<PassText>Your password need to</PassText>
						</PassHeader>
						<PassBody>
							<PassList css={LineThrough}>
								<img src={CheckIocn} alt="check icon" />
								include both lower and upper case characters
							</PassList>
							<PassList css={LineThrough}>
								<img src={CheckIocn} alt="check icon" />
								include at least one number or symbol
							</PassList>
							<PassList>be at least 8 characters long</PassList>
						</PassBody>
					</PassValidationBlock>
				)}
			</FormFloating>
		</PasswordWrap>
	);
};
export default PasswordInput;
