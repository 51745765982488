import { OnboardingIcon } from "screens/register/register.styles";

const CreateAccountIcon = () => {
  return (
    <OnboardingIcon
      width="14px"
      height="14px"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.5 0C0.671573 0 0 0.671573 0 1.5V12.5C0 13.3284 0.671573 14 1.5 14H12.5C13.3284 14 14 13.3284 14 12.5V1.5C14 0.671573 13.3284 0 12.5 0H1.5ZM6.35357 3.39646C6.54882 3.59172 6.54881 3.9083 6.35355 4.10356L4.60355 5.85347C4.41451 6.04251 4.11021 6.04939 3.91281 5.8691L3.16281 5.1841C2.95891 4.99788 2.94459 4.68162 3.13081 4.47772C3.31704 4.27382 3.6333 4.2595 3.8372 4.44572L4.23436 4.80847L5.64646 3.39644C5.84173 3.20118 6.15831 3.20119 6.35357 3.39646ZM6.35357 8.39645C6.54882 8.59172 6.54881 8.90831 6.35355 9.10356L4.60355 10.8535C4.41451 11.0425 4.11021 11.0494 3.91281 10.8691L3.16281 10.1841C2.95891 9.99788 2.94459 9.68162 3.13081 9.47772C3.31704 9.27382 3.6333 9.2595 3.8372 9.44572L4.23436 9.80847L5.64646 8.39644C5.84173 8.20118 6.15831 8.20119 6.35357 8.39645ZM8 9H10.5C10.7761 9 11 9.22386 11 9.5C11 9.77614 10.7761 10 10.5 10H8C7.72386 10 7.5 9.77614 7.5 9.5C7.5 9.22386 7.72386 9 8 9ZM7.5 4.5C7.5 4.22386 7.72386 4 8 4H10.5C10.7761 4 11 4.22386 11 4.5C11 4.77614 10.7761 5 10.5 5H8C7.72386 5 7.5 4.77614 7.5 4.5Z"></path>
    </OnboardingIcon>
  );
};

export default CreateAccountIcon;
