/** @jsxRuntime classic */
/** @jsx jsx */

// Third party imports
import styled from "@emotion/styled/macro";

const Percentage = styled.span`
  font-weight: 300;
  font-size: 36px;
  line-height: 36px;
  text-align: center;
  color: #252424;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 8px;
`;

const Count = styled.span`
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  color: #76767b;
`;

const Progress = styled.span`
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #252424;
`;

export { Percentage, InfoWrapper, Count, Progress };
