import React, { useState } from "react";

const LearnersFilterStateContext = React.createContext<
  string | null | undefined
>(undefined);

const LearnersFilterUpdaterContext = React.createContext<any | undefined>(
  undefined
);

LearnersFilterStateContext.displayName = "LearnersFilterContext";
LearnersFilterUpdaterContext.displayName = "LearnersFilterContext";

function LearnersFilterProvider(props: any) {
  const [filterValue, setFilterValue] = useState(null);

  return (
    <LearnersFilterStateContext.Provider value={filterValue}>
      <LearnersFilterUpdaterContext.Provider value={setFilterValue}>
        {props.children}
      </LearnersFilterUpdaterContext.Provider>
    </LearnersFilterStateContext.Provider>
  );
}

function useLearnersFilterStateContext() {
  const filterState = React.useContext(LearnersFilterStateContext);

  if (filterState === undefined) {
    throw new Error(
      `useLearnersFilterStateContext must be used within a LearnersFilterProvider`
    );
  }

  return filterState;
}

function useLearnersFilterUpdaterContext() {
  const setFilterValue = React.useContext(LearnersFilterUpdaterContext);

  if (setFilterValue === undefined) {
    throw new Error(
      `useLearnersFilterUpdaterContext must be used within a LearnersFilterProvider`
    );
  }

  return setFilterValue;
}

export {
  LearnersFilterProvider,
  useLearnersFilterStateContext,
  useLearnersFilterUpdaterContext,
};
