/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React, { useEffect } from "react";

// Third party imports
import { Col } from "react-bootstrap";

// Component imports
import { Modal, ModalContents, ModalOpenButton } from "components";

import { AddContentDescription } from "components";

//  Style imports
import { Button } from "styles/components.styles";

import {
	InfoWrap,
	ContentImg,
	ContentTitle,
	AddBtnWrapper,
	AddContentWrapper,
} from "screens/onboarding/onboarding.styles";

import { AddImg, BtnText, CourseDetailButtonImg } from "styles/courses.styles";

// Image imports
import AddIcon from "assets/images/common/add-icon.svg";
import TickIcon from "assets/images/common/tick-icon.svg";
import ExclamationIcon from "assets/images/common/info-icon.svg";

// Interface imports
import { useAddOrRemoveCourse } from "hooks";

import { SingleCourseAdmin } from "ts/interfaces/courses.interface";
export interface CourseAddProps {
	course: SingleCourseAdmin;
}

const CourseAdd = ({ course }: CourseAddProps) => {
	const { addCourse, removeCourse, isSelected, setCurrentCourse } =
		useAddOrRemoveCourse();

	useEffect(() => {
		setCurrentCourse(course);
	}, [course, setCurrentCourse]);

	return (
		<Col lg={6}>
			<AddContentWrapper>
				<InfoWrap>
					<ContentImg src={course.image} alt={course.title} />
					<ContentTitle>{course.title}</ContentTitle>
				</InfoWrap>

				<AddBtnWrapper>
					{isSelected ? (
						<Button
							variant="green"
							fontweight="600"
							onClick={() => removeCourse({ course })}
						>
							<AddImg src={TickIcon} alt="remove icon" />
						</Button>
					) : (
						<Button
							variant="purple"
							fontweight="600"
							onClick={() => addCourse({ course })}
						>
							<AddImg src={AddIcon} alt="add icon" />
							<BtnText>Add</BtnText>
						</Button>
					)}

					<Modal maxwidth="1140px">
						<ModalOpenButton>
							<Button
								variant="dark"
								fontweight="600"
								padding="5px 10px"
								css={{ marginLeft: "8px" }}
							>
								<CourseDetailButtonImg src={ExclamationIcon} alt="info icon" />
							</Button>
						</ModalOpenButton>
						<ModalContents aria-label="Course Description">
							<AddContentDescription
								course={course}
								addCourse={addCourse}
								isSelected={isSelected}
								removeCourse={removeCourse}
							/>
						</ModalContents>
					</Modal>
				</AddBtnWrapper>
			</AddContentWrapper>
		</Col>
	);
};

export default CourseAdd;
