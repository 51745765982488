import { FC, useEffect } from "react";

//Third Party Imports
import { AxiosResponse } from "axios";

import { useForm } from "react-hook-form";

import { UseMutateFunction } from "react-query";

//Style imports
import {
	Button,
	BoxShadow,
	CustomHeightSpinner,
} from "styles/components.styles";

import { RadioLabel } from "screens/settings/emailSettings/changeEmailStatus/changeEmailStatus.styles";

import { Form, FormGroup } from "styles/form.styles";

// import { SettingsWrapper } from "screens/settings/ssoSettings/ssoSettings.styles";

interface ToggleStatusFormProps {
	mutate: UseMutateFunction<AxiosResponse<any>, unknown, any, unknown>;
	isLoading: boolean;
	initialValue: boolean;
	fieldName: string;
	primaryLabelForEnableButton: string;
	secondaryLabelForEnableButton: string;
	primaryLabelForDisableButton: string;
	secondaryLabelForDisableButton: string;
}

const ToggleStatusForm: FC<ToggleStatusFormProps> = ({
	mutate,
	isLoading,
	initialValue,
	fieldName,
	primaryLabelForEnableButton,
	secondaryLabelForEnableButton,
	primaryLabelForDisableButton,
	secondaryLabelForDisableButton,
}) => {
	const {
		register,
		setValue,
		handleSubmit,
		formState: { isDirty, isSubmitting, isValid },
	} = useForm<any>({
		reValidateMode: "onSubmit",
		defaultValues: {
			fieldName: "",
		},
	});

	useEffect(() => {
		setValue(fieldName, initialValue ? "enable" : "disable");
	}, [initialValue, setValue, fieldName]);

	const onSubmit = (data: any) => {
		mutate(data);
	};

	return (
		<BoxShadow>
			{isLoading ? (
				<CustomHeightSpinner height="200px" />
			) : (
				<Form onSubmit={handleSubmit(onSubmit)}>
					<FormGroup>
						<RadioLabel htmlFor="enable">
							<input
								{...register(fieldName)}
								type="radio"
								name={fieldName}
								value="enable"
							/>
							<span>
								<h5>{primaryLabelForEnableButton}</h5>
								<p>{secondaryLabelForEnableButton}</p>
							</span>
						</RadioLabel>
					</FormGroup>
					<FormGroup>
						<RadioLabel htmlFor="disable">
							<input
								{...register(fieldName)}
								type="radio"
								name={fieldName}
								value="disable"
							/>
							<span>
								<h5>{primaryLabelForDisableButton}</h5>
								<p>{secondaryLabelForDisableButton}</p>
							</span>
						</RadioLabel>
					</FormGroup>

					<Button
						type="submit"
						variant="charcoalGrey"
						fontweight="600"
						css={{ marginTop: "20px" }}
						disabled={!(isValid && isDirty) || isSubmitting}
					>
						{isSubmitting ? "Saving" : "Save"}
					</Button>
				</Form>
			)}
		</BoxShadow>
	);
};

export default ToggleStatusForm;
