import React, { useState, useMemo } from "react";

interface ActiveMembersFilterContextParams {
  filterBy: "week" | "month" | "year" | "";
  setFilterBy: (state: string) => void;
  dateRange: string | null;
  setDateRange: React.Dispatch<React.SetStateAction<null[]>>;
  findUniqueActiveMembers: boolean;
  setFindUniqueActiveMembers: React.Dispatch<React.SetStateAction<boolean>>;
}

const ActiveMembersFilterContext = React.createContext<
  ActiveMembersFilterContextParams | undefined
>(undefined);

ActiveMembersFilterContext.displayName = "ActiveMembersFilterContext";

function ActiveMembersFilterProvider(props: any) {
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [filterBy, setFilterBy] = useState<string>("");
  const [findUniqueActiveMembers, setFindUniqueActiveMembers] =
    useState<boolean>(false);

  const value = useMemo(() => {
    return {
      dateRange,
      setDateRange,
      filterBy,
      setFilterBy,
      findUniqueActiveMembers,
      setFindUniqueActiveMembers,
    };
  }, [dateRange, filterBy, findUniqueActiveMembers]);

  return <ActiveMembersFilterContext.Provider value={value} {...props} />;
}

function useActiveMembersFilterContext() {
  const context = React.useContext(ActiveMembersFilterContext);

  if (context === undefined) {
    throw new Error(
      `useActiveMembersFilterContext must be used within a ActiveMembersFilterProvider`
    );
  }

  return context;
}

export { ActiveMembersFilterProvider, useActiveMembersFilterContext };
