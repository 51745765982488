import React, { FC } from "react";
import { Controller } from "react-hook-form";
import ReactSelect from "react-select";
import styled from "@emotion/styled";
import { Control, getCustomStyles } from "../autoComplete";

interface SelectProps {
  name: string;
  placeholder: string;
  control: any;
  label: string;
  errors: any;
  options: Array<{
    label: string;
    value: string;
  }>;
}

const SelectWrapper = styled.div`
  margin-bottom: 15px;
`;

const Select: FC<SelectProps> = ({
  control,
  errors,
  name,
  placeholder,
  options,
  label,
}) => {
  return (
    <SelectWrapper>
      <Controller
        name={name}
        render={({ field }) => (
          <ReactSelect
            value={field?.value}
            options={options}
            isDisabled={false}
            onChange={field?.onChange}
            onBlur={field?.onBlur}
            placeholder={placeholder}
            isClearable={true}
            components={{
              Control: (props) => (
                <Control {...props} placeholder={placeholder} label={label} />
              ),
            }}
            styles={getCustomStyles({
              error: errors[name],
              touched: errors[name],
            })}
            key={name}
          />
        )}
        control={control}
      />
      <span className="text-danger">
        {errors[name] ? errors[name].message : null}
      </span>
    </SelectWrapper>
  );
};

export default Select;
