import React from "react";

// Style imports
import styled from "@emotion/styled/macro";

import { BsExclamationCircle } from "react-icons/bs";

// Styles
const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 1.33;
  text-align: left;
  color: #242424;
`;

const DefSelect = styled.select<SelProps>`
  margin-bottom: 10px;
  border-radius: 3px;
  background-color: ${({ backgroundcolor }) =>
    backgroundcolor ? backgroundcolor : "#fff"};
  border: ${({ border }) => (border ? border : "2px solid transparent")};
  font-size: 14px;
  line-height: 1.43;
  text-align: left;
  color: ${({ color }) => (color ? color : "#242424")};
  height: ${({ height }) => (height ? height : "auto")};
  border-bottom: ${({ isError }: SelProps) =>
    isError ? "2px solid #c4314b" : ""};

  :focus {
    outline: none !important;
    box-shadow: none;
    border-color: transparent;
    border-bottom: ${({ isError }: SelProps) =>
      isError ? "2px solid #c4314b" : "2px solid #6264a7"};
  }

  :focus-visible {
    outline: 0;
  }
`;

const ErrorForm = styled.p`
  margin-left: 10px;
  font-size: 12px;
  color: #c4314b;
`;

const SelectWrap = styled.div`
  position: relative;
`;

// Typescript interface
interface SelProps {
  isError: boolean;
  backgroundcolor: string;
  color: string;
  height: string;
  border: string;
}

interface NativeSelectProps {
  label: string;
  errors: any;
  options: any;
  register: any;
  clearErrors: any;
  placeholder: string;
  backgroundcolor?: string;
  height?: string;
  color?: string;
  border?: string;
  disabled?: boolean;
}

const NativeSelect = ({
  label,
  color,
  errors,
  height,
  border,
  options,
  register,
  disabled,
  clearErrors,
  placeholder,
  backgroundcolor,
}: NativeSelectProps) => {
  const isError = errors[`${label}`]?.type === "required";

  return (
    <>
      <Label>{label}</Label>
      <SelectWrap>
        <DefSelect
          className="form-control"
          height={height}
          isError={isError}
          color={color}
          border={border}
          backgroundcolor={backgroundcolor}
          disabled={disabled}
          {...register(label, {
            required: true,
            onChange: (e: any) => {
              clearErrors(label);
            },
          })}
        >
          <option value="" style={{ color: "grey" }} hidden>
            {placeholder}
          </option>
          {options.map((option: any) => {
            let optionValue;
            let optionName;

            if (label === "Country") {
              const { dial_code, code, name } = option;

              optionValue = `${dial_code} ${code} ${name}`;
              optionName = `${name} (${dial_code})`;
            } else if (label === "Course") {
              const { cb_title, id } = option;

              optionValue = `${id}`;
              optionName = `${cb_title}`;
            } else if (label === "Section") {
              const { s_name, id } = option;

              optionValue = `${id}`;
              optionName = `${s_name}`;
            } else if (label === "Branches") {
              const { ib_name, id } = option;

              optionValue = `${id}`;
              optionName = `${ib_name}`;
            } else {
              optionValue = "";
              optionValue = "";
            }

            return (
              <option key={option.id} value={optionValue}>
                {optionName}
              </option>
            );
          })}
        </DefSelect>
        {isError && (
          <BsExclamationCircle
            style={{
              position: "absolute",
              top: "10px",
              right: "15px",
              fontSize: "15px",
              fill: "red",
            }}
          />
        )}
      </SelectWrap>

      {isError && <ErrorForm>{errors[`${label}`]?.message}</ErrorForm>}
    </>
  );
};

export default NativeSelect;
