import { OnboardingIcon } from "screens/register/register.styles";

const GridViewIcon = () => {
  return (
    <OnboardingIcon
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.5,9h-4C0.7,9,0,9.7,0,10.5v4C0,15.3,0.7,16,1.5,16h4C6.3,16,7,15.3,7,14.5v-4C7,9.7,6.3,9,5.5,9z M6,14.5C6,14.8,5.8,15,5.5,15h-4C1.2,15,1,14.8,1,14.5v-4C1,10.2,1.2,10,1.5,10h4C5.8,10,6,10.2,6,10.5V14.5z"></path>
      <path d="M5.5,0h-4C0.7,0,0,0.7,0,1.5v4C0,6.3,0.7,7,1.5,7h4C6.3,7,7,6.3,7,5.5v-4C7,0.7,6.3,0,5.5,0z M6,5.5C6,5.8,5.8,6,5.5,6h-4C1.2,6,1,5.8,1,5.5v-4C1,1.2,1.2,1,1.5,1h4C5.8,1,6,1.2,6,1.5V5.5z"></path>
      <path d="M14.5,0h-4C9.7,0,9,0.7,9,1.5v4C9,6.3,9.7,7,10.5,7h4C15.3,7,16,6.3,16,5.5v-4C16,0.7,15.3,0,14.5,0z M15,5.5C15,5.8,14.8,6,14.5,6h-4C10.2,6,10,5.8,10,5.5v-4C10,1.2,10.2,1,10.5,1h4C14.8,1,15,1.2,15,1.5V5.5z"></path>
      <path d="M14.5,9h-4C9.7,9,9,9.7,9,10.5v4c0,0.8,0.7,1.5,1.5,1.5h4c0.8,0,1.5-0.7,1.5-1.5v-4C16,9.7,15.3,9,14.5,9z M15,14.5c0,0.3-0.2,0.5-0.5,0.5h-4c-0.3,0-0.5-0.2-0.5-0.5v-4c0-0.3,0.2-0.5,0.5-0.5h4c0.3,0,0.5,0.2,0.5,0.5V14.5z"></path>
    </OnboardingIcon>
  );
};

export default GridViewIcon;
