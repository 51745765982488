import { useState, useEffect } from "react";

import { client } from "utils/api-client";

import { useQuery } from "react-query";

import { Notification } from "utils/notification";

import { useMarketPlaceFilterContext, useVleTokenContext } from "context";

function useGetDefaultPaymentMethod() {
  const [defaultPayment, setDefaultPayment] = useState<any>(null);

  const { vleToken } = useVleTokenContext();

  const { courseProviderValue } = useMarketPlaceFilterContext();

  const getDefaultPaymentMethod = () => {
    return client({
      method: "get",
      endpoint: `api/payment/payment-method?provider=${
        courseProviderValue?.split(" ")[1]
      }`,
      optional: {
        token: vleToken,
      },
    });
  };

  const { data, isLoading, isSuccess, isError } = useQuery(
    ["defaultPayment", { courseProviderValue }],
    getDefaultPaymentMethod,
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (isSuccess) {
      const stringifiedDecodedData = Buffer.from(
        data?.data?.data,
        "base64"
      ).toString("ascii");
      const parsedData = JSON.parse(stringifiedDecodedData);
      setDefaultPayment(
        parsedData.find((item: any) => item.isDefault === true)
      );
    }
  }, [data?.data?.data, isSuccess]);

  useEffect(() => {
    if (isError) {
      Notification({
        message: "Unable to ge, please try again later",
        type: "warning",
        title: "Warning",
      });
    }
  }, [isError]);

  return { defaultPayment, setDefaultPayment, isLoading };
}

export { useGetDefaultPaymentMethod };
