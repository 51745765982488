import { OnboardingIcon } from "screens/register/register.styles";

const AddMembersIcon = () => {
  return (
    <OnboardingIcon
      width="16px"
      height="15px"
      viewBox="0 0 16 15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 8C10.1046 8 11 8.89543 11 10V11.5C11 13.5544 8.91195 15 5.5 15C2.08805 15 0 13.5544 0 11.5V10C0 8.89543 0.89543 8 2 8H9ZM9 9H2C1.44772 9 1 9.44772 1 10V11.5C1 12.9071 2.57862 14 5.5 14C8.42138 14 10 12.9071 10 11.5V10C10 9.44772 9.55228 9 9 9ZM14 8C15.1046 8 16 8.89543 16 10V10.5C16 12.5886 14.4317 14 11.5 14C11.3587 14 11.2206 13.9967 11.0856 13.9902C11.2938 13.7482 11.4684 13.4875 11.6076 13.209L11.705 12.996L11.7438 12.9965C13.976 12.9312 15 11.9593 15 10.5V10C15 9.44772 14.5523 9 14 9L11.829 8.9991C11.6971 8.62645 11.4937 8.28763 11.2353 7.99912L14 8ZM5.5 0C7.433 0 9 1.567 9 3.5C9 5.433 7.433 7 5.5 7C3.567 7 2 5.433 2 3.5C2 1.567 3.567 0 5.5 0ZM12.5 2C13.8807 2 15 3.11929 15 4.5C15 5.88071 13.8807 7 12.5 7C11.1193 7 10 5.88071 10 4.5C10 3.11929 11.1193 2 12.5 2ZM5.5 1C4.11929 1 3 2.11929 3 3.5C3 4.88071 4.11929 6 5.5 6C6.88071 6 8 4.88071 8 3.5C8 2.11929 6.88071 1 5.5 1ZM12.5 3C11.6716 3 11 3.67157 11 4.5C11 5.32843 11.6716 6 12.5 6C13.3284 6 14 5.32843 14 4.5C14 3.67157 13.3284 3 12.5 3Z"></path>
    </OnboardingIcon>
  );
};

export default AddMembersIcon;
