export const base = 'white';
export const black = 'black';

export const text = '#434449';
export const gray = '#f1f2f7';
export const gray10 = '#f1f1f4';
export const gray20 = '#e4e5e9';
export const gray80 = '#6f7077';
export const indigo = '#3f51b5';
export const indigoDarken10 = '#364495';
export const indigoLighten80 = '#b7c1f8';
export const yellow = '#ffc107';
export const green = '#4caf50';
export const danger = '#ef5350';
export const orange = 'orange';

export const black1 = '#1e1e1e';
export const black2 = '#252423';
export const gray1 = '#c7c7c7';
export const gray2 = '#adb5bd';
export const purple = '#585a96';
export const chineseBlue = '#454B93';
export const ghostWhite = '#f7f8fa';
