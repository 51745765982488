import { useState, useEffect } from "react";
import * as microsoftTeams from "@microsoft/teams-js";

import { useDeeplinkRouting, useGetTokenFromMS } from "hooks";

function useCommonLogin() {
	const [openCommonLogin, setCommonLogin] = useState(false);

	const { setDeeplinkParams } = useDeeplinkRouting();

	const { setSsoRegister } = useGetTokenFromMS();

	useEffect(() => {
		if (openCommonLogin) {
			microsoftTeams.authentication.authenticate({
				url: window.location.origin + "/login",
				width: 700,
				height: 700,
				successCallback: async (result) => {
					setDeeplinkParams({
						entityId: "index",
						routeName: "dashboard",
						historyPush: false,
					});
					setCommonLogin(false);
				},
				failureCallback: (reason: any) => {
					console.log({ reason });
					setCommonLogin(false);

					if (reason?.openSso) {
						console.log("sso opened");

						setTimeout(() => {
							setSsoRegister(true);
						}, 10000);
					}
				},
			});
		}
	}, [openCommonLogin, setDeeplinkParams, setSsoRegister]);

	return { openCommonLogin, setCommonLogin };
}

export { useCommonLogin };
