import React, { useMemo } from "react";

import { localStorageKeys, useLocalStorageState } from "hooks";

interface PathwayTokenContextParams {
	pathwayToken: string;
	setPathwayToken: (state: boolean) => null;
	removePathwayTokenFromLocalStorage: () => null;
}

const PathwayTokenContext = React.createContext<
	PathwayTokenContextParams | undefined
>(undefined);

PathwayTokenContext.displayName = "PathwayTokenContext";

function PathwayTokenProvider(props: any) {
	let [pathwayToken, setPathwayToken, removePathwayTokenFromLocalStorage] =
		useLocalStorageState({
			key: localStorageKeys.pathwayToken,
		});

	const value = useMemo(() => {
		return {
			pathwayToken,
			setPathwayToken,
			removePathwayTokenFromLocalStorage,
		};
	}, [pathwayToken, setPathwayToken, removePathwayTokenFromLocalStorage]);

	return <PathwayTokenContext.Provider value={value} {...props} />;
}

function usePathwayTokenContext() {
	const context = React.useContext(PathwayTokenContext);

	if (context === undefined) {
		throw new Error(
			`usePathwayTokenContext must be used within a PathwayTokenProvider`
		);
	}

	return context;
}

export { PathwayTokenProvider, usePathwayTokenContext };
