/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React from "react";

import TableRow from "../pathwayTableRow";

interface TableBodyProps {
  title: string;
  recentPathways: any;
  deeplinkhandler: any;
}

function PathwayTableBody({
  title,
  recentPathways,
  deeplinkhandler,
}: TableBodyProps) {
  if (title === "Pathway") {
    return recentPathways.map(({ title, pathway_progress, id }: any) => {
      return (
        <TableRow
          key={id}
          name={title}
          progress={pathway_progress}
          pathwayId={id}
          deeplinkhandler={deeplinkhandler}
        />
      );
    });
  } else if (title === "Portfolio") {
    return recentPathways.map(({ occupation_name, progress, id }: any) => {
      return (
        <TableRow
          key={id}
          name={occupation_name}
          progress={progress}
          pathwayId={id}
          deeplinkhandler={deeplinkhandler}
        />
      );
    });
  } else {
    return null;
  }
}

export default PathwayTableBody;
