import styled from "@emotion/styled/macro";

const CaretArrow = styled.span`
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid #6f7070;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
`;

const CheckboxContent = styled.div`
  display: flex;
  align-items: center;
`;

const CheckboxInput = styled.input`
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
  &:checked {
    + {
      label {
        &:before {
          background-color: #6f75bc;
        }
        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 1px;
          left: 5px;
          width: 5px;
          height: 9px;
          border: 1px solid #fff;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
        }
      }
    }
  }
`;

const CheckboxLabel = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #6264a7;

  &:before {
    content: "";
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #707070;
    border-radius: 2px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 6px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
  }
`;

export { CheckboxContent, CheckboxInput, CheckboxLabel, CaretArrow };
