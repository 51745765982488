import React, { ReactNode } from "react";

import styled from "@emotion/styled/macro";

interface WrapperProps {
  height: any;
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => (height ? height : "100vh")};
  width: 100%;
`;

const Image = styled.img`
  width: 196px;
  height: auto;
  margin-bottom: 30px;
`;

const Text = styled.p``;

interface NotAuthorisedScreenProps {
  imgSrc: string;
  text?: string;
  height?: any;
  children?: ReactNode;
}

const NotAuthorisedScreen = ({
  text,
  height,
  imgSrc,
  children,
}: NotAuthorisedScreenProps) => {
  return (
    <Wrapper height={height}>
      <Image src={imgSrc} alt={text} />
      <Text>{text}</Text>
      {children}
    </Wrapper>
  );
};

export default NotAuthorisedScreen;
