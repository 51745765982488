// Third party imports
import styled from "@emotion/styled/macro";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
`;

const LeftArrow = styled.img`
  height: 10px;
`;

const PageDescription = styled.div`
  font-size: 12px;
  margin: 0 8px;
`;

const RightArrow = styled.img`
  height: 10px;
`;

export { Wrapper, LeftArrow, PageDescription, RightArrow };
