import React, { useState, useMemo } from "react";

interface NewMembersFilterContextParams {
  dateRange: string | null;
  setDateRange: React.Dispatch<React.SetStateAction<null[]>>;
}

const NewMembersFilterContext = React.createContext<
  NewMembersFilterContextParams | undefined
>(undefined);

NewMembersFilterContext.displayName = "NewMembersFilterContext";

function NewMembersFilterProvider(props: any) {
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);

  const value = useMemo(() => {
    return {
      dateRange,
      setDateRange,
    };
  }, [dateRange]);

  return <NewMembersFilterContext.Provider value={value} {...props} />;
}

function useNewMembersFilterContext() {
  const context = React.useContext(NewMembersFilterContext);

  if (context === undefined) {
    throw new Error(
      `useNewMembersFilterContext must be used within a NewMembersFilterProvider`
    );
  }

  return context;
}

export { NewMembersFilterProvider, useNewMembersFilterContext };
