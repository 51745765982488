import { useState, useEffect } from "react";

//ThirdParty imports
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useMutation } from "react-query";

// Context imports
import { useMykademyDataContext, usePathwayTokenContext } from "context";

function useAuthorizeLearnerPathway() {
  const [existingPathwayToken] = useState(localStorage.getItem("pathwayToken"));
  const { pathwayToken, setPathwayToken } = usePathwayTokenContext();

  const {
    mykademyUserData: {
      userData: { mk_account_id },
    },
  } = useMykademyDataContext();

  const clientId = process.env.REACT_APP_LEARNER_PATHWAY_CLIENTID;
  const tenantId = process.env.REACT_APP_LEARNER_PATHWAY_TENANTID;
  const clientSecretKey = process.env.REACT_APP_LEARNER_PATHWAY_CLIENT_SECRET;
  const learnerPathwayBaseUrl = process.env.REACT_APP_LEARNER_PATHWAY_BASEURL;

  const getLearnerPathwayAccessToken = () => {
    return axios
      .post(`${learnerPathwayBaseUrl}/auth/v1`, {
        clientId,
        clientSecretKey,
        organisationId: mk_account_id,
        tenantId,
      })
      .then((res) => setPathwayToken(res?.data?.data?.token));
  };

  const { isLoading, mutate } = useMutation(getLearnerPathwayAccessToken);

  useEffect(() => {
    if (existingPathwayToken) {
      const { exp }: any = jwt_decode(existingPathwayToken);
      const hasExpired = exp < (new Date().getTime() + 1) / 1000;
      if (hasExpired) {
        mutate();
      }
    } else {
      mutate();
    }
  }, [existingPathwayToken, mutate]);

  return { pathwayToken, isLoading };
}

export { useAuthorizeLearnerPathway };
