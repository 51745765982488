import React from "react";
import ReactDOM from "react-dom";
import App from "./routes";

import { ReactQueryDevtools } from "react-query/devtools";

import { QueryClient, QueryClientProvider } from "react-query";

import { ReactNotifications } from "react-notifications-component";

import "styles/bootstrap";

import { AppProviders } from "context";

// Create a client
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			useErrorBoundary: true,
			refetchOnWindowFocus: false,
			retry(failureCount, error: any) {
				if (error.status === 404) return false;
				else if (failureCount < 2) return true;
				else return false;
			},
		},
	},
});

// const MyComponent: React.FunctionComponent<any> = ({ReactNotifications}) => {
//   return <div>{ReactNotifications}</div>;
// };

ReactDOM.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<AppProviders>
				<ReactNotifications />
				<App />
			</AppProviders>
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	</React.StrictMode>,
	document.getElementById("root")
);
