import { useState, useEffect } from "react";

// Third party imports
import { useInfiniteQuery } from "react-query";

// Util imports
import { client } from "utils/api-client";
import { Notification } from "utils/notification";

// Context imports
import { useMykademyDataContext, useVleTokenContext } from "context";
import { useAuthorizeLearnerPathway } from "hooks/useAuthorizeLearnerPathway";

function useGetPathwayList() {
	const [pathwayList, setPathwayList] = useState<any>(null);
	const [pageLimit, setPageLimit] = useState(20);

	const { vleToken } = useVleTokenContext();
	const {
		mykademyUserData: {
			userData: { mk_member_id },
		},
	} = useMykademyDataContext();

	const { pathwayToken, isLoading: isAuthorizing } =
		useAuthorizeLearnerPathway();

	const getPathwayList = () => {
		return client({
			method: "get",
			endpoint: "api/learning-pathway/enrolled-courses",
			optional: {
				token: vleToken,
				params: {
					token: pathwayToken,
					learner: mk_member_id,
				},
			},
		});
	};

	const {
		data,
		error,
		isSuccess,
		isLoading,
		isError,
		hasNextPage,
		fetchNextPage,
	} = useInfiniteQuery("pathwayList", getPathwayList, {
		getNextPageParam: (_lastPage, pages) => {
			let coursesCount = pages[0].data.data.total;

			const totalPages = Math.ceil(coursesCount / pageLimit);

			if (pages.length < totalPages) {
				return pages.length + 1;
			} else {
				return undefined;
			}
		},
		refetchOnWindowFocus: false,
		enabled: !!pathwayToken,
		select: (data) => {
			let allPathways: any = [];
			data?.pages?.map((page: any) => {
				// eslint-disable-next-line array-callback-return
				return page?.data?.data?.map((item: any) => {
					allPathways.push({
						id: item?._id,
						title: item.title,
						pathway_progress: item.pathway_progress
							? item.pathway_progress
							: "0",
						courses: item.components?.map(
							(course: any) => course?.metadata?.course
						),
					});
				});
			});
			return allPathways;
		},
	});

	useEffect(() => {
		if (isSuccess) {
			setPathwayList(data);
		}
	}, [data, isSuccess]);

	useEffect(() => {
		if (isError) {
			console.log({ error });

			Notification({
				message: "Unable to get pathwayList, please try again later",
				type: "warning",
				title: "Warning",
			});
		}
	}, [error, isError]);

	return {
		isLoading: isLoading || isAuthorizing,
		pathwayList,
		fetchNextPage,
		hasNextPage,
		isSuccess,
	};
}

export { useGetPathwayList };
