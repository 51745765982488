/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React, { useState, useContext, useMemo } from "react";

// Third party imports
import VisuallyHidden from "@reach/visually-hidden";
// import { config, animated, useTransition } from "react-spring";

// Style imports
import { Dialog, CircleButton, DialogOverlay } from "styles/components.styles";

interface modalProps {
	children: React.ReactElement<any>;
}

interface ModalContextParams {
	isOpen: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	maxwidth: string | null;
	height: string | null;
	onDismiss: any;
}

export const ModalContext = React.createContext<ModalContextParams | undefined>(
	undefined
);

/**
 *
 * @param fns : All the functions passed to callAll
 * @param args : All the arguments passed to a specific function from list of fns
 * @returns void
 */

const callAll =
	(...fns: Function[]) =>
	(...args: any) =>
		fns.forEach((fn: Function) => fn && fn(...args));

// const AnimatedDialogOverlay = animated(DialogOverlay);
// const AnimatedDialogContent = animated(Dialog);
const AnimatedDialogOverlay = DialogOverlay;
const AnimatedDialogContent = Dialog;

function Modal(props: any) {
	const [isOpen, setIsOpen] = useState(false);

	const { maxwidth, onDismiss, height } = props;

	const value = useMemo(() => {
		return {
			isOpen,
			setIsOpen,
			maxwidth,
			onDismiss,
			height,
		};
	}, [isOpen, height, maxwidth, onDismiss]);

	return <ModalContext.Provider value={value} {...props} />;
}

function useModalContext() {
	const context = useContext(ModalContext);

	if (context === undefined) {
		throw new Error(`useModalContext must be used withing a Modal component`);
	}

	return context;
}

function ModalDismissButton({ children: child }: modalProps) {
	const { setIsOpen } = useModalContext();

	return React.cloneElement(child, {
		onClick: callAll(() => setIsOpen(false), child.props.onClick),
	});
}

function ModalOpenButton({ children: child }: modalProps) {
	const { setIsOpen } = useModalContext();

	return React.cloneElement(child, {
		onClick: callAll(() => setIsOpen(true), child.props.onClick),
	});
}

function ModalContentsBase(props: any) {
	const { isOpen, setIsOpen, maxwidth, height, onDismiss } = useModalContext();

	// const transitions = useTransition(isOpen, {
	//   from: {
	//     opacity: 0,
	//     transform: -40,
	//   },
	//   enter: {
	//     opacity: 1,
	//     transform: 0,
	//   },
	//   leave: {
	//     opacity: 0,
	//     transform: -40,
	//   },
	//   config: config.gentle,
	// });

	return (
		<React.Fragment>
			<AnimatedDialogOverlay
				isOpen={isOpen}
				onDismiss={callAll(() => setIsOpen(false), onDismiss)}
			>
				<AnimatedDialogContent maxwidth={maxwidth} height={height} {...props} />
			</AnimatedDialogOverlay>
		</React.Fragment>
	);
}

function ModalContents({
	title,
	children,
	...props
}: {
	title?: string;
	children: React.ReactNode;
}) {
	return (
		<ModalContentsBase {...props}>
			<div
				css={{
					display: "flex",
					justifyContent: "flex-end",
					marginBottom: "10px",
				}}
			>
				<ModalDismissButton>
					<CircleButton>
						<VisuallyHidden>Close</VisuallyHidden>
						<span aria-hidden>×</span>
					</CircleButton>
				</ModalDismissButton>
			</div>
			{children}
		</ModalContentsBase>
	);
}

export {
	Modal,
	ModalContents,
	ModalOpenButton,
	useModalContext,
	ModalContentsBase,
	ModalDismissButton,
};
