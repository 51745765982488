/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React from "react";

import { Alert } from "react-bootstrap";

const NoResultsFound = ({ message }: { message?: string }) => {
  return (
    <Alert
      variant="danger"
      css={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: 0,
      }}
    >
      <p
        css={{
          margin: 0,
        }}
      >
        {message ? message : "No results found!"}
      </p>
    </Alert>
  );
};

export default NoResultsFound;
