export const smallPhone = "@media (min-width: 320px)";
export const phone = "@media (min-width: 400px)";
export const maxPhone = "@media (max-width: 399px)";
export const phoneWide = "@media (min-width: 480px)";
export const phablet = "@media (min-width: 560px)";
export const maxPhablet = "@media (max-width: 559px)";
export const tabletSmall = "@media (min-width: 640px)";
export const tablet = "@media (min-width: 768px)";
export const maxTableWide = "@media (max-width: 991px)";
export const tabletWide = "@media (min-width: 992px)";
export const desktop = "@media (min-width: 1248px)";
export const desktopWide = "@media (min-width: 1440px)";
export const desktopExtraWide = "@media (min-width: 1600px)";

export const hdMinus = "@media (min-height: 400px) and (max-height: 700px)";
