import { useState, useEffect } from "react";

// Hooks imports
import { useAsync } from "hooks";

// Utils imports
import { client } from "utils/api-client";

interface SubdomainProps {
  subdomain: string;
}

function useSubdomainValidation({ subdomain }: SubdomainProps) {
  const [subdomainExists, setSubdomainExists] = useState<true | false | null>(
    null
  );
  const [domainNameValidationLoader, setDomainNameValidationLoader] =
    useState(false);

  const { run, isSuccess, isError } = useAsync();

  useEffect(() => {
    if (isSuccess) {
      setSubdomainExists(true);

      setDomainNameValidationLoader(false);
    } else {
      setSubdomainExists(false);

      setDomainNameValidationLoader(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      setSubdomainExists(false);

      setDomainNameValidationLoader(false);
    }
  }, [isError]);

  useEffect(() => {
    let delayDebounceFn: any;
    if (subdomain) {
      delayDebounceFn = setTimeout(() => {
        setDomainNameValidationLoader(true);

        const apiBodySubdomainValidation = {
          subdomain: subdomain,
        };

        run(
          client({
            method: "patch",
            endpoint: "api/validation/app",
            optional: {
              data: apiBodySubdomainValidation,
            },
          })
        );
      }, 500);
    }

    return () => clearTimeout(delayDebounceFn);
  }, [run, subdomain]);

  return { subdomainExists, domainNameValidationLoader };
}

export { useSubdomainValidation };
