/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React, { useEffect } from "react";

// Third party imports
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import * as microsoftTeams from "@microsoft/teams-js";

// Component imports
import { HomeIcon } from "components/svg";
import { Modal, ModalContents, FullPageOverlay } from "components";
import EnablePopupModal from "screens/getStartedTab/getStartedBeforeLogin/enablePopupModal";

// Hook imports
import { useGetTokenFromMS } from "hooks";

// Image imports
import OliveVleLogo from "assets/images/common/olivevle-logo.svg";

// Style imports
import { Button } from "styles/components.styles";
import {
  TabImg,
  Imgwrap,
  OliveImg,
  TeachLearn,
  IntroBanner,
  NotFoundHeader,
  WelcomeImgHolder,
} from "./tabBeforeLogin.styles";

import { vleConfig } from "utils/vleConfig";

interface TabBeforeLoginProps {
  image?: string;
  header?: string;
  message?: string;
}

const withModal =
  (Component: any) =>
  ({ ...props }: any) => {
    return (
      <Modal maxwidth="750px">
        <Component {...props} />
      </Modal>
    );
  };

const TabBeforeLogin = ({ image, header, message }: TabBeforeLoginProps) => {
  const history = useHistory();

  const { hide, setSsoRegister, setCheckAutoLogin } = useGetTokenFromMS();

  useEffect(() => {
    setSsoRegister(true);
    setCheckAutoLogin(true);
  }, [setCheckAutoLogin, setSsoRegister]);

  const returnToGetStarted = () => {
    const appid = vleConfig.index.APP_ID;
    const entityId = "index";
    const entityLabel = "index";
    const baseUrl = vleConfig.index.APP_BASEURL
      ? encodeURI(vleConfig.index.APP_BASEURL)
      : null;

    microsoftTeams.executeDeepLink(
      `https://teams.microsoft.com/l/entity/${appid}/${entityId}?webUrl=${baseUrl}getStartedTab&label=${entityLabel}`
    );

    history.push("/homeTab");
  };

  return (
    <IntroBanner>
      <FullPageOverlay
        hide={hide}
        message="Please wait while the Olive VLE application loads..."
      />
      {!hide ? null : (
        <Container>
          <ModalContents>
            <EnablePopupModal />
          </ModalContents>
          <Row>
            <Col xs={12} md={12} lg={6}>
              <OliveImg src={OliveVleLogo} alt="Olive Logo" />
              <NotFoundHeader>{header}</NotFoundHeader>
              <TeachLearn>{message}</TeachLearn>
              <Button variant="greenText" onClick={() => returnToGetStarted()}>
                <HomeIcon />
                Return to sign in
              </Button>
            </Col>
            <Col xs={12} md={12} lg={6}>
              <WelcomeImgHolder>
                <Imgwrap>
                  <TabImg src={image} alt="tab image" />
                </Imgwrap>
              </WelcomeImgHolder>
            </Col>
          </Row>
        </Container>
      )}
    </IntroBanner>
  );
};

export default withModal(TabBeforeLogin);
