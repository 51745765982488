/** @jsxRuntime classic */
/** @jsx jsx */

import styled from "@emotion/styled/macro";

interface IEmailTemplateEditorProps {
	error: string;
}

export const Wrapper = styled.div<IEmailTemplateEditorProps>`
	.jodit-status-bar {
		display: none;
	}
	.jodit-wysiwyg {
		border-bottom: ${({ error }) => (error ? "2px solid #c4314b" : "")};
	}
	.jodit-wysiwyg:focus {
		background: white;
		border-bottom: ${({ error }) =>
			error ? "2px solid #c4314b" : "2px solid #6264a7"};
	}
	.css-eob06j {
		border-radius: 3px;
		background: #fff;
	}
`;

export const ErrorMessage = styled.span`
	margin-left: 10px;
	font-size: 12px;
	color: #c4314b;
`;
