import { OnboardingIcon } from "screens/register/register.styles";

const ListViewIcon = () => {
  return (
    <OnboardingIcon
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.8,1.5h10.4l0.1,0c0.4,0,0.6-0.4,0.6-0.7c0-0.4-0.3-0.8-0.8-0.8H0.8L0.6,0C0.3,0.1,0,0.4,0,0.8C0,1.2,0.3,1.5,0.8,1.5z"></path>
      <path d="M15.2,6H0.8l-0.1,0C0.3,6.1,0,6.4,0,6.8c0,0.4,0.3,0.8,0.8,0.8h14.5l0.1,0c0.4,0,0.6-0.4,0.6-0.7C16,6.4,15.6,6,15.2,6z"></path>
      <path d="M10.2,11.6H0.8l-0.1,0c-0.4,0-0.6,0.4-0.6,0.7c0,0.4,0.3,0.8,0.8,0.8h9.4l0.1,0c0.4,0,0.6-0.4,0.6-0.7C10.9,11.9,10.6,11.6,10.2,11.6z"></path>
    </OnboardingIcon>
  );
};

export default ListViewIcon;
