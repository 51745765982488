import { useState, useEffect } from "react";

// Third party imports
import { useQuery } from "react-query";

// Util imports
import { client } from "utils/api-client";
import { Notification } from "utils/notification";

// Context imports
import { useDomainContext } from "context";

function useGetOrgData() {
  const [orgData, setOrgData] = useState<any>({});
  const [showLoginPage, setShowLoginPage] = useState(true);

  const [showPendingUserInvitation, setShowPendingUserInvitation] =
    useState(null);

  const [showWidget, setShowWidget] = useState(true);

  const [showLearnerMarketPlace, setShowLearnerMarketPlace] = useState(false);

  const [showGetStartedTab, setShowGetStartedTab] = useState(false);

  const [showContentPro, setShowContentPro] = useState(true);

  const [showVideoClassRoom, setShowVideoClassRoom] = useState(false);

  const [showLearningPathway, setShowLearningPathway] = useState(false);

  const [canUnenrollPaidCourse, setCanUnenrollPaidCourse] = useState(false);

  const [courseTabLabelData, setCourseTabLabelData] = useState<any>(null);

  const [homeTabLabelData, setHomeTabLabelData] = useState<any>(null);

  const [videoClassroomLabelData, setVideoClassroomLabelData] =
    useState<any>(null);

  const [enablePayment, setEnablePayment] = useState(false);
  const [enableBundle, setEnableBundle] = useState(false);

  const [showClientMarketplace, setShowClientMarketPlace] = useState<
    boolean | null
  >(null);

  const [showSsoButton, setShowSsoButton] = useState<any>(null);
  const [showSsoUpdateButton, setShowSsoUpdateButton] = useState<any>(null);

  const { domain } = useDomainContext();

  const getOrgData = () => {
    return client({
      method: "post",
      endpoint: "api/get_org",
      optional: {
        data: {
          domain: domain.toLowerCase(),
        },
      },
    });
  };

  const { data, error, isLoading, isSuccess, isError } = useQuery(
    "orgData",
    getOrgData
  );

  useEffect(() => {
    if (isSuccess) {
      const orgData = data?.data?.data;

      setOrgData(orgData);

      console.log({ orgData });

      setShowClientMarketPlace(orgData?.org?.show_client_marketplace);

      setShowSsoUpdateButton(
        orgData?.org?.show_sso_update_button && orgData?.hasSsoRoles
      );

      setShowSsoButton(orgData?.org?.show_sso_button);

      setCourseTabLabelData(
        data?.data?.data?.labels?.find(
          (label: any) => label.labelPage === "courses"
        )
      );

      setHomeTabLabelData(
        data?.data?.data?.labels?.find(
          (label: any) => label.labelPage === "home"
        )
      );

      setVideoClassroomLabelData(
        data?.data?.data?.labels?.find(
          (label: any) => label.labelPage === "videoClassroom"
        )
      );

      if (orgData?.org?.show_login_page === false) {
        setShowLoginPage(false);
      } else {
        setShowLoginPage(true);
      }

      setShowPendingUserInvitation(
        data?.data?.data?.org?.show_pending_user_invitation
      );
      setCanUnenrollPaidCourse(data?.data?.data?.org?.can_unenroll_paid_course);
      setShowWidget(data?.data?.data?.org?.show_upcoming_event_widget);
      setShowLearnerMarketPlace(
        data?.data?.data?.org?.show_learner_marketplace
      );
      setShowGetStartedTab(data?.data?.data?.org?.show_get_started);
      setShowContentPro(data?.data?.data?.org?.show_content_pro);
      setShowVideoClassRoom(data?.data?.data?.org?.show_video_classroom);
      setShowLearningPathway(data?.data?.data?.org?.enable_pathway);
      setEnablePayment(data?.data?.data?.enable_payment);
      setEnableBundle(data?.data?.data?.show_bundle);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (isError) {
      console.log({ error });

      Notification({
        message: "Unable to get org data, please try again later",
        type: "warning",
        title: "Warning",
      });
    }
  }, [error, isError]);

  return {
    orgData,
    isLoading,
    showWidget,
    enableBundle,
    showLoginPage,
    showSsoButton,
    enablePayment,
    showContentPro,
    homeTabLabelData,
    showGetStartedTab,
    courseTabLabelData,
    showVideoClassRoom,
    showSsoUpdateButton,
    showLearningPathway,
    showClientMarketplace,
    canUnenrollPaidCourse,
    showLearnerMarketPlace,
    videoClassroomLabelData,
    showPendingUserInvitation,
  };
}

export { useGetOrgData };
