/** @jsxRuntime classic */
/** @jsx jsx */

import { css } from "@emotion/react";

import styled from "@emotion/styled/macro";

const PasswordWrap = styled.div`
	position: relative;
	.form-floating {
		.form-control {
			paading: 1rem 3rem 1rem 0.75rem;
		}
	}
`;

const PassValidationBlock = styled.div`
	position: absolute;
	top: 0;
	left: 100%;
	background: #f7f8fa;
	border-radius: 6px;
	margin-left: 20px;
	padding: 16px 20px;
	min-width: 300px;
	width: 100%;
	@media (max-width: 1199px) {
		min-width: 100%;
		top: auto;
		left: 0;
		margin-left: 0;
	}
	&:before {
		content: "";
		width: 0;
		height: 0;
		border-width: 7px 10px 7px 0;
		border-color: transparent #f7f8fa transparent transparent;
		border-style: solid;
		position: absolute;
		left: -10px;
		top: 20px;
		@media (max-width: 1199px) {
			border-width: 0 7px 10px 7px;
			border-color: transparent transparent #f7f8fa transparent;
			left: 50%;
			top: -4px;
			transform: translateY(-50%);
		}
	}
`;

const ShowHidePassImage = styled.img`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 15px;
	cursor: pointer;
`;

const PassHeader = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	margin-bottom: 10px;
`;

const PassImageWrap = styled.div`
	width: 20px;
	height: 20px;
	background: #fff;
	border-radius: 50%;
	padding: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`;

const PassText = styled.div`
	font-size: 10px;
	font-weight: bold;
	color: #13171f;
	opacity: 0.4;
`;

const PassBody = styled.ul`
	margin: 0;
	padding: 0;
`;

const PassList = styled.li`
	position: relative;
	margin-bottom: 15px;
	padding-left: 30px;
	font-size: 10px;
	font-weight: 600;
	color: #6268b4;
	&:last-child {
		margin-bottom: 0;
	}
	img {
		position: absolute;
		left: 4px;
		top: 4px;
		width: 13px;
		height: 9px;
	}
`;

const LineThrough = css`
	color: #13171f;
	text-decoration: line-through;
`;

export {
	PasswordWrap,
	PassValidationBlock,
	ShowHidePassImage,
	PassHeader,
	PassImageWrap,
	PassText,
	PassBody,
	PassList,
	LineThrough,
};
