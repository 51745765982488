// Third party imports
import { useMutation, useQueryClient } from "react-query";

// Util imports
import { client } from "utils/api-client";
import { Notification } from "utils/notification";

// Context imports
import { useVleTokenContext } from "context";

function useUnsubscribeBundle() {
	const queryClient = useQueryClient();

	const { vleToken } = useVleTokenContext();

	const unSubscribeBundle = (data: any) => {
		let apiBody = {
			bundle_id: data["bundleId"],
		} as const;

		return client({
			method: "post",
			endpoint: "api/akademy/unsubscribe-bundle",
			optional: {
				data: apiBody,
				token: vleToken,
			},
		});
	};

	const { mutate, isLoading } = useMutation(unSubscribeBundle, {
		onSuccess: () => {
			queryClient.invalidateQueries("bundleList");
			queryClient.removeQueries("bundleList");
		},

		onError: (error: any) => {
			const errorMsg = error?.response.data.message;

			Notification({
				message: errorMsg
					? errorMsg
					: "Unable to unSubscribe bundle, please try again later",
				type: "warning",
				title: "Warning",
			});
		},
	});

	return {
		mutate,
		isLoading,
	};
}

export { useUnsubscribeBundle };
