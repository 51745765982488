import React, { useMemo } from "react";

import { localStorageKeys, useLocalStorageState } from "hooks";

interface onboardingContextParams {
  onboardingSteps: {
    step_one: boolean;
    step_two: boolean;
    step_three: boolean;
    step_four: boolean;
    step_five: boolean;
    step_six: boolean;
  };
  setOnboardingSteps: (state: any) => null;
  removeOnboardingStepsFromLocalStorage: () => null;
}

const OnboardingContext = React.createContext<
  onboardingContextParams | undefined
>(undefined);

OnboardingContext.displayName = "OnboardingContext";

function OnboardingProvider(props: any) {
  const [
    onboardingSteps,
    setOnboardingSteps,
    removeOnboardingStepsFromLocalStorage,
  ] = useLocalStorageState({
    key: localStorageKeys.onboardingSteps,
  });

  const value = useMemo(() => {
    return {
      onboardingSteps,
      setOnboardingSteps,
      removeOnboardingStepsFromLocalStorage,
    };
  }, [
    onboardingSteps,
    setOnboardingSteps,
    removeOnboardingStepsFromLocalStorage,
  ]);

  return <OnboardingContext.Provider value={value} {...props} />;
}

function useOnboardingContext() {
  const context = React.useContext(OnboardingContext);

  if (context === undefined) {
    throw new Error(
      `useOnboardingContext must be used within a OnboardingProvider`
    );
  }

  return context;
}

export { OnboardingProvider, useOnboardingContext };
