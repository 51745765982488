/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import React from "react";

import { Title, Text } from "styles/widget.styles";
import { BoxShadow } from "styles/components.styles";
import { Skeleton } from "@mui/material";

interface WidgetProps {
  title: string;
  text: string;
  height?: string;
  children: React.ReactNode;
  isLoading?: boolean;
}

const Widget = ({ title, text, height, children, isLoading }: WidgetProps) => {
  return (
    <BoxShadow css={{ height: height ? height : "auto" }}>
      <Title>
        {isLoading ? (
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width="100px" />
        ) : (
          title
        )}
      </Title>
      <Text>
        {isLoading ? (
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width="200px" />
        ) : (
          text
        )}
      </Text>
      {children}
    </BoxShadow>
  );
};

export default Widget;
