import * as yup from "yup";

import { useState, useEffect } from "react";

function useEditPasswordSchema() {
	const [schema, setSchema] = useState<any>();

	useEffect(() => {
		const shapeObject: any = {
			"Current Password": yup
				.string()
				.required("Current Password cannot be empty"),
			"New Password": yup
				.string()
				.required("New password cannot be empty")
				.matches(
					/^((?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\W).{6,15})$/,
					"Password must contain at least 8 characters with a lowercase, uppercase ,number and a special character"
				),
			"Confirm New Password": yup
				.string()
				.required("Confirm password cannot be empty")
				.matches(
					/^((?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\W).{6,15})$/,
					"Password must contain at least 8 characters with a lowercase, uppercase ,number and a special character"
				),
		};

		let schema = yup.object().shape(shapeObject);

		setSchema(schema);
	}, []);

	return { schema };
}

export { useEditPasswordSchema };
