import { useState, useEffect } from "react";

import { client } from "utils/api-client";

import { useQuery } from "react-query";

import { Notification } from "utils/notification";

import { useVleTokenContext } from "context";

function useGetCourses() {
	const [courses, setCourses] = useState([]);
	const [getCoursesLoader, setGetCoursesLoader] = useState(false);

	const { vleToken } = useVleTokenContext();

	const getCourses = () => {
		return client({
			method: "get",
			endpoint: "api/akademy/getFreeCourses",
			optional: {
				token: vleToken,
				params: {
					orderby_item: "created_date",
					orderby_direction: "DESC",
					show_b2b_enabled_only: "1",
					search_by: "is_free",
					search_keyword: "1",
					app: "ms_teams",
				},
			},
		});
	};

	const { data, isFetching, isSuccess, isError } = useQuery(
		"courses",
		getCourses,
		{
			refetchOnWindowFocus: false,
		}
	);

	useEffect(() => {
		if (isFetching) {
			setGetCoursesLoader(true);
		}
	}, [isFetching]);

	useEffect(() => {
		if (isSuccess) {
			setGetCoursesLoader(false);

			setCourses(data?.data?.data?.courses);
		}
	}, [isSuccess, data]);

	useEffect(() => {
		if (isError) {
			setGetCoursesLoader(false);

			Notification({
				message: "Unable to get courses, please try again later",
				type: "warning",
				title: "Warning",
			});
		}
	}, [isError]);

	return { courses, setCourses, getCoursesLoader };
}

export { useGetCourses };
