/** @jsxRuntime classic */
/** @jsx jsx */

import styled from "@emotion/styled/macro";

const OnboardingWrapper = styled.div`
  background: #f5f5f5;
  padding: 40px 0;
  min-height: 100vh;
`;

const Form = styled.form`
  padding: 20px;
`;

const OnboardingFirstHeaderWrap = styled.div`
  margin-bottom: 20px;
`;

const OnboardingFirstHeader = styled.h4`
  font-size: 18px;
  font-weight: bold;
  color: #242424;
`;

const OnboardingFirstText = styled.p`
  font-size: 14px;
  line-height: 1.43;
  text-align: left;
  color: #616161;
`;

const OnboardingSecondHeaderWrap = styled.div`
  margin-top: 30px;
  margin-bottom: 15px;
`;

const OnboardingSecondHeader = styled.h4`
  font-size: 14px;
  font-weight: 600;
  color: #242424;
`;

const OnboardingSecondText = styled.p`
  font-size: 14px;
  line-height: 1.43;
  text-align: left;
  color: #616161;
`;

const FormGroup = styled.div`
  margin-bottom: 12px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  disabled {
    opacity: 0.5;
  }
`;

const CropImg = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin-bottom: 10px;
  object-fit: cover;
`;

const OnboardingCompanyLogo = styled.div`
  margin-bottom: 10px;
`;

const OnboardingCompanyLogoHeader = styled.span`
  margin-bottom: 10px;
  display: block;
  font-size: 12px;
  color: #242424;
`;

const InputFile = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  font-size: 0;
`;

const FormFloating = styled.div`
  margin-bottom: 34px;
  .form-control {
    border: 1px solid #bcc3d1;
    border-radius: 6px;
    background-color: #fff;
    font-size: 12px;
    font-weight: 600;
    color: #13171f;
    height: calc(3rem + 1px);
    &:not(:placeholder-shown) ~ label {
      color: #4e545f;
      opacity: 1;
      font-size: 10px;
    }
    &:focus {
      border-color: #6f75bc;
      border-width: 2px;
      box-shadow: 0 0 0 6px rgb(69 75 147 / 7%);
      ~ label {
        font-size: 10px;
        color: #4e545f;
        opacity: 1;
      }
      &:not(:placeholder-shown) ~ label {
        color: #6f75bc;
        opacity: 1;
      }
    }
  }
  label {
    font-size: 12px;
    font-weight: 600;
    color: #9b9ba2;
  }
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
`;

export {
  Form,
  Label,
  CropImg,
  InputFile,
  FormGroup,
  ButtonWrapper,
  OnboardingWrapper,
  OnboardingFirstText,
  OnboardingSecondText,
  OnboardingCompanyLogo,
  OnboardingFirstHeader,
  OnboardingSecondHeader,
  OnboardingFirstHeaderWrap,
  OnboardingSecondHeaderWrap,
  OnboardingCompanyLogoHeader,
  FormFloating,
};
