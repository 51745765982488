import React from "react";

import styled from "@emotion/styled/macro";

import { Modal, ModalOpenButton, ModalContents, PopupVideo } from "components";

import PlayBtnImg from "assets/images/common/play_icon.svg";

import {
	ImgWrapper,
	AssetImage,
} from "components/onboardingSteps/onboardingSteps.styles";

const VideoWrap = styled.div`
	width: auto;
	height: 360px;
	margin-top: 20px;
	cursor: pointer;
`;

const Image = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

interface ImageWrapProps {
	showLogo: boolean;
}

const ImageWrap = styled.div<ImageWrapProps>`
	width: 100%;
	height: 360px;
	margin-top: 20px;
	position: relative;

	::before {
		content: "";
		position: ${({ showLogo }) => (showLogo ? "absolute" : "initial")};
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.3);
		transition: all 1s;
		-webkit-transition: all 1s;
	}
`;

const PlayButtonImg = styled.img`
	width: 72px;
	height: 72px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

interface ImageButtonProps {
	title?: string | undefined;
	imgSrc?: string | undefined;
	type: string | undefined;
	videoSrc: string;
	showPlayButton?: boolean;
	showLogo?: boolean;
}

const ImageButton = ({
	type,
	title,
	imgSrc,
	videoSrc,
	showPlayButton = true,
	showLogo = true,
}: ImageButtonProps) => {
	return (
		<VideoWrap>
			<Modal maxwidth="800px">
				<ModalOpenButton>
					<ImageWrap showLogo={showLogo}>
						<Image src={imgSrc} alt={title} />
						{showPlayButton ? (
							<PlayButtonImg src={PlayBtnImg} alt="play button" />
						) : null}
					</ImageWrap>
				</ModalOpenButton>
				<ModalContents aria-label="Video Player">
					{type === "video" ? (
						<PopupVideo
							showLogo={showLogo}
							videoSrc={videoSrc}
							title={title ? title : ""}
						/>
					) : type === "image" ? (
						<ImgWrapper>
							<AssetImage src={videoSrc} alt={title} />
						</ImgWrapper>
					) : null}
				</ModalContents>
			</Modal>
		</VideoWrap>
	);
};

export default ImageButton;
