import React, { useState, useMemo } from "react";

interface ActiveMembersFilterContextParams {
  paymentResponse: any;
  setPaymentResponse: (state: any) => void;
}

const PaymentResponseContext = React.createContext<
  ActiveMembersFilterContextParams | undefined
>(undefined);

PaymentResponseContext.displayName = "PaymentResponseContext";

function PaymentResponseProvider(props: any) {
  const [paymentResponse, setPaymentResponse] = useState<string>("");

  const value = useMemo(() => {
    return {
      paymentResponse,
      setPaymentResponse,
    };
  }, [paymentResponse]);

  return <PaymentResponseContext.Provider value={value} {...props} />;
}

function usePaymentResponseContext() {
  const context = React.useContext(PaymentResponseContext);

  if (context === undefined) {
    throw new Error(
      `usePaymentResponseContext must be used within a PaymentResponseProvider`
    );
  }

  return context;
}

export { PaymentResponseProvider, usePaymentResponseContext };
