/** @jsxRuntime classic */
/** @jsx jsx */

// Third party imports
import styled from "@emotion/styled/macro";

interface PopupVideoWrapperProps {
  isLoading: boolean;
}

const CompanyVideoInfo = styled.div`
  margin-bottom: 20px;
`;

const AboutOlive = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  margin-right: 15px;
`;

const OliveImg = styled.img`
  width: 42px;
  height: 42px;
  margin-right: 15px;
`;

const OliveTitle = styled.h4`
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  color: #252423;
`;

const CompanyVideoWrap = styled.div`
  min-height: 400px;
  background: #000;
  width: 100%;
  position: relative;
`;

const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`;

const PopupVideoWrapper = styled.div<PopupVideoWrapperProps>`
  display: ${({ isLoading }: PopupVideoWrapperProps) =>
    isLoading ? "none" : "block"};
`;

const Wrapper = styled.div<PopupVideoWrapperProps>`
  width: 100%;
  height: 462px;
  position: relative;
  display: ${({ isLoading }) => (isLoading ? "block" : "none")};
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export {
  Iframe,
  Wrapper,
  OliveImg,
  AboutOlive,
  OliveTitle,
  SpinnerWrapper,
  CompanyVideoWrap,
  CompanyVideoInfo,
  PopupVideoWrapper,
};
