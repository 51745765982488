// Third party imports
import styled from "@emotion/styled/macro";

interface imageProp {
  image: any;
}

const UploadLogoContent = styled.div<imageProp>`
  display: ${(props: any) => (props.image ? "none" : "")};
`;

const UploadLogoTitle = styled.h4`
  margin-bottom: 10px;
  font-size: 14px;
  color: #616161;
`;

const UploadImgWrap = styled.div`
  position: relative;
  font-size: 14px;
  font-weight: 600;
  color: #6264a7;
`;

const UploadImg = styled.img`
  margin-right: 5px;
  width: 40px;
  height: 40px;
`;

const AssociatedQuestion = styled.div`
  margin-left: 50px;
  display: flex;
  font-size: 12px;
  color: #484644;
  margin-bottom: 15px;
`;

const AssociatedQuestionText = styled.div``;

const AssociatedQuestionImage = styled.img`
  margin-right: 10px;
`;

const PreviewTitle = styled.h4`
  margin-bottom: 10px;
  font-size: 14px;
  color: #616161;
`;

const PreviewImgWrap = styled.div`
  height: 346px;
  margin: 12px 0 0;
  padding: 12px;
  border-radius: 8px;
  background-color: #ebebeb;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PreviewImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const CropImgHolder = styled.div<imageProp>`
  max-width: 300px;
  display: ${(props: any) => (props.image ? "" : "none")};
`;

const CompanyButtonWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
`;

const ModifySelectedImageWrap = styled.div<imageProp>`
  padding: 0 20px;
  display: flex;
  margin-top: 10px;
  display: ${(props: any) => (props.image ? "" : "none")};
`;

const ReplaceImg = styled.img`
  display: inline-block;
  margin-right: 5px;
`;

const ReplaceWrap = styled.div`
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  color: #6264a7;
  position: relative;
`;

const DeleteButton = styled.button`
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  color: #6264a7;
  border: 0;
  background: transparent;
`;

const DeleteImg = styled.img`
  display: inline-block;
  margin-right: 5px;
`;

export {
  DeleteImg,
  UploadImg,
  PreviewImg,
  ReplaceImg,
  ReplaceWrap,
  DeleteButton,
  PreviewTitle,
  UploadImgWrap,
  CropImgHolder,
  PreviewImgWrap,
  UploadLogoTitle,
  UploadLogoContent,
  AssociatedQuestion,
  CompanyButtonWrapper,
  AssociatedQuestionText,
  ModifySelectedImageWrap,
  AssociatedQuestionImage,
};
